import React from "react";
import IS from "../resources/IS.svg";
import UK from "../resources/UK.svg";

export default class LanguageSwitch extends React.Component {
  state = {
    selectedLanguage: "is"
  };

  componentDidMount() {
    //if localStorage has no language preference, choose en
    if (localStorage.getItem("language") === undefined) {
      localStorage.setItem("language", "en");
    } else if (localStorage.getItem("language") === "en") {
      this.setState({
        selectedLanguage: "en"
      });
    }
  }

  //function that fires on clicking the language flag
  handleLangChange = () => {
    //if selectedLanguage was english, change to icelandic and vice versa
    if (this.state.selectedLanguage === "en") {
      localStorage.setItem("language", "is");
      this.props.onSelectLanguage("is");
      this.setState({
        selectedLanguage: "is"
      });
    } else {
      this.props.onSelectLanguage("en");
      localStorage.setItem("language", "en");
      this.setState({
        selectedLanguage: "en"
      });
    }
  };

  render() {
    return (
      <div
        onClick={this.handleLangChange}
        style={{
          position: "fixed",
          right: 0,
          top: 0,
          paddingRight: "2%",
          paddingTop: "1rem",
          zIndex: 1301
        }}
      >
        {this.state.selectedLanguage === "is" ? (
          <img
            src={UK}
            alt=""
            style={{
              height: "32px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "black"
            }}
          />
        ) : (
          <img
            src={IS}
            alt=""
            style={{
              height: "32px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "black"
            }}
          />
        )}
      </div>
    );
  }
}
