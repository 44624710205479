import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import FadeInSection from "./FadeIn";
import Images from "../../resources/index";
import MailIcon from "@material-ui/icons/Mail";
import FacebookIcon from "@material-ui/icons/Facebook";
import Hidden from "@material-ui/core/Hidden";

class LaFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <footer
        style={{
          marginTop: "2.5rem",
          borderTop: "1px solid rgba(255, 255, 255, 0.23)"
        }}
      >
        <Container
          maxWidth={false}
          className="landing-container"
          style={{ marginTop: "8px" }}
        >
          <Grid
            container
            spacing={3}
            style={{
              alignItems: "center"
            }}
          >
            <Grid item xs={12} sm={4}>
              <img
                alt="draumadeildin"
                src={Images["footerlogo"]}
                style={{
                  maxWidth: "225px"
                }}
              />
            </Grid>
            <Hidden xsDown>
              <Grid item xs={12} sm={4} />
            </Hidden>
            <Grid item xs={12} sm={3}>
              <Grid container>
                <Grid item xs={4}>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://medium.com/@draumadeildin"
                  >
                    <img
                      alt="medium"
                      src={Images["medium"]}
                      style={{
                        maxWidth: "24px"
                      }}
                      className="footer-smoke-img"
                    />
                  </a>
                </Grid>

                <Grid item xs={4}>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/groups/348314645250059"
                  >
                    <FacebookIcon
                      className="footer-smoke"
                      style={{ color: "#a2a2a2" }}
                    />
                  </a>
                </Grid>

                <Grid item xs={4}>
                  <a href="mailto:support@draumadeildin.is">
                    <MailIcon
                      className="footer-smoke"
                      style={{ color: "#a2a2a2" }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </footer>
    );
  }
}

export default LaFooter;
