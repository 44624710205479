import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class AdminAddBrandedLeague extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      theme: "",
      passcode: ""
    };

    this.addBrandedLeague = this.addBrandedLeague.bind(this);
  }

  //handle input changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  addBrandedLeague() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .post(
        API_URL + "/leagues/branded",
        {
          name: this.state.name,
          passcode: this.state.passcode,
          theme: this.state.theme
        },
        config
      )
      .then(response => {
        alert("Success!");
      })
      .catch(error => {
        alert("Error!");
      });
  }
  render() {
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            Add Branded Leagues
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="name"
              label="Name"
              type="text"
              id="name"
              value={this.state.name}
              onChange={this.handleInputChange("name")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              name="theme"
              label="Theme"
              type="text"
              id="theme"
              value={this.state.theme}
              onChange={this.handleInputChange("theme")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              name="passcode"
              label="Passcode"
              type="password"
              id="passcode"
              value={this.state.passcode}
              onChange={this.handleInputChange("passcode")}
            />{" "}
          </Grid>
          <Grid item xs={12}>
            <Button onClick={this.addBrandedLeague}>Confirm</Button>
          </Grid>
        </Grid>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(AdminAddBrandedLeague);
