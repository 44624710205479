import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import Images from "../../resources/index";
import { NavLink } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { FormattedMessage } from "react-intl";
import Gameweek from "../Home/Gameweek";
import { useTheme } from "@material-ui/core/styles";
import { FormattedDate } from "react-intl";
import { Hidden } from "@material-ui/core";
import { API_URL } from "../../constants/apiConstants";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import EventIcon from "@material-ui/icons/Event";
import CircularProgress from "@material-ui/core/CircularProgress";

class LandingSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameweekMax: 21,
      series: [],
      loaded: false
    };
  }

  componentDidMount() {
    document.body.style.background = "black";

    axios
      .get(API_URL + "/getsettings")
      .then(response => {
        this.setState({
          settings: response.data
        });
        axios
          .get(API_URL + "/schedule/season/" + response.data.season)
          .then(response => {
            let gameweekMax = this.state.gameweekMax;

            if (response.data.length !== 0) {
              gameweekMax = response.data.reduce(
                (p, c) => (p.round > c.round ? p : c)
              ).round;
              this.setState({
                gameweekMax
              });
            }

            var seriesArray = [];
            for (let i = 1; i <= gameweekMax; i++) {
              seriesArray.push(response.data.filter(x => x.round === i));
            }

            this.setState({
              series: seriesArray,
              loaded: true
            });
          })
          .catch(error => {
            this.setState({
              loaded: true
            });

            console.log("Getting series failed");
          });
      })
      .catch(error => {
        this.setState({
          loaded: true
        });

        console.log("Getting settings failed");
      });
  }

  gameweek(props) {
    return (
      <span>
        <Paper style={{ backgroundColor: "#121212" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              style={{ cursor: "default" }}
            >
              <Typography variant="overline" gutterBottom>
                <FormattedMessage id="gameweek" />
              </Typography>
              <Typography variant="h6" gutterBottom>
                {props.gameweek}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Avatar
                style={{
                  color: "#FFFFFF",
                  left: "50%",
                  top: "50%",
                  backgroundColor: "#121212",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <EventIcon />
              </Avatar>
            </Grid>
          </Grid>
        </Paper>
      </span>
    );
  }

  oneResult(props) {
    const theme = useTheme();

    return (
      <span key={props.series._id}>
        <Grid container spacing={0} className="black-smoke">
          <Grid item xs={2} sm={1}>
            {props.series.team0w ? (
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "green",
                  float: "right",
                  paddingRight: "1em"
                }}
                variant="overline"
              >
                W
              </Typography>
            ) : null}
          </Grid>

          <Grid item xs={2} sm={1} style={{ alignSelf: "center" }}>
            <img
              style={{
                width: "50px",
                height: "50px",
                marginTop: "1em",
                marginBottom: "1em",
                float: "left"
              }}
              alt="team"
              src={Images.teams[props.series.team0.toLowerCase()]}
            />
          </Grid>
          <Grid item xs={4} sm={3} xl={1}>
            {props.series.team0r === 0 && props.series.team1r === 0 ? (
              <Typography
                style={{
                  marginTop: "1.5em"
                }}
              >
                vs
              </Typography>
            ) : (
              <Typography
                style={{
                  marginTop: "1.5em"
                }}
              >
                {props.series.team0r} - {props.series.team1r}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} sm={1} style={{ alignSelf: "center" }}>
            <img
              style={{
                width: "50px",
                height: "50px",
                marginTop: "1em",
                marginBottom: "1em",
                float: "right"
              }}
              alt="team"
              src={Images.teams[props.series.team1.toLowerCase()]}
            />
          </Grid>

          <Grid item xs={2} sm={1}>
            {props.series.team1w ? (
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "green",
                  float: "left",
                  paddingLeft: "1em"
                }}
                variant="overline"
              >
                W
              </Typography>
            ) : null}
          </Grid>
          <Hidden xsDown>
            <Grid item xs={2} xl={1} style={{ marginTop: "1rem" }}>
              <Typography variant="overline">
                <FormattedMessage
                  id="myId"
                  defaultMessage="{day}. "
                  values={{
                    day: (
                      <FormattedDate value={props.series.date} day="numeric" />
                    )
                  }}
                />

                <FormattedMessage
                  id={new Date(props.series.date).getMonth() + "m"}
                />
              </Typography>
            </Grid>
            <Grid item xs={2} xl={1} style={{ marginTop: "1rem" }}>
              <Typography variant="overline">
                {new Date(props.series.date).getHours() +
                  ":" +
                  String(new Date(props.series.date).getMinutes()).padStart(
                    2,
                    "0"
                  )}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </span>
    );
  }

  render() {
    if (this.state.loaded === false) {
      return (
        <Grid item xs={12}>
          <div
            style={{
              paddingTop: "1rem"
            }}
          >
            <CircularProgress
              style={{
                color: "#11F211"
              }}
              color="secondary"
            />
          </div>
        </Grid>
      );
    } else {
      return (
        <span>
          {this.state.series.map((series, i) => (
            <Grid
              container
              style={{
                margin: 0,
                width: "100%"
              }}
              spacing={3}
              key={i}
            >
              <Grid item xs={12} sm={12} md={3} xl={2}>
                <this.gameweek gameweek={i + 1} />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                {series.map((series, i) => (
                  <NavLink
                    key={i}
                    to={"/schedule/" + series._id}
                    style={{
                      textDecoration: "none",
                      color: "inherit"
                    }}
                  >
                    <this.oneResult series={series} />
                  </NavLink>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </span>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(LandingSchedule);
