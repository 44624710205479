import React, { Component } from "react";
import Grow from "@material-ui/core/Grow";

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisible(entry.isIntersecting);
      });
    });

    const { current } = domRef;
    observer.observe(current);

    return () => observer.unobserve(current);
  }, []);

  return (
    <Grow {...(isVisible ? { timeout: 1000 } : {})} in={isVisible}>
      <div ref={domRef}>{props.children}</div>
    </Grow>
  );
}

export default FadeInSection;
