import { ADD_USER } from "../constants/userConstants";
import { LOGOUT_USER } from "../constants/userConstants";

const userReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_USER:
      return action.payload;
    case LOGOUT_USER:
      return {};
    default:
      return state;
  }
};

export default userReducer;
