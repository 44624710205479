import React, { Component } from "react";
import Images from "../../resources/index";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import StandingsLanding from "../Home/StandingsLanding";
import Schedule from "../Home/Schedule";
import FadeInSection from "./FadeIn";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

class LaInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameweek: 1,
      gameweekMax: 21
    };
  }

  componentDidMount() {}

  render() {
    return (
      <section>
        <Container maxWidth={false} className="landing-container">
          <FadeInSection trans={"grow"}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                lg={4}
                style={{ margin: "auto" }}
              >
                <StandingsLanding
                  theme={"black"}
                  standings={this.props.standings}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                lg={4}
                style={{ margin: "auto" }}
              >
                <Schedule
                  landingPage={true}
                  blackBackground={true}
                  parentSeries={this.props.series}
                  gameweek={this.props.gameweek}
                />
                <Button
                  variant="outlined"
                  onClick={() => this.props.decGameweekFunction()}
                  disabled={this.props.disableDec}
                  style={{ width: "49%", marginTop: "0.5em", float: "left" }}
                >
                  <ArrowLeftIcon style={{ cursor: "pointer" }} />
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => this.props.incGameweekFunction()}
                  disabled={this.props.disableInc}
                  style={{ width: "49%", marginTop: "0.5em", float: "right" }}
                >
                  <ArrowRightIcon style={{ cursor: "pointer" }} />
                </Button>
              </Grid>
            </Grid>
          </FadeInSection>
        </Container>
      </section>
    );
  }
}

export default LaInfo;
