import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import RemoveIcon from "@material-ui/icons/Remove";
import { FormattedMessage } from "react-intl";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Avatar from "@material-ui/core/Avatar";

class Rank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      season: this.props.settings.season
    };
  }

  render() {
    return (
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="overall.rank" />
            </Typography>
            <Typography variant="h6" gutterBottom>
              {!this.props.parentUser.history["season" + this.state.season]
                .rankhistory[
                this.props.parentUser.history["season" + this.state.season]
                  .rankhistory.length - 1
              ]
                ? "N/A"
                : this.props.parentUser.history["season" + this.state.season]
                    .rankhistory[
                    this.props.parentUser.history["season" + this.state.season]
                      .rankhistory.length - 1
                  ]}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            {this.props.parentUser.history["season" + this.state.season]
              .rankhistory.length <= 1 ? (
              <Avatar
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#212121",
                  left: "50%",
                  top: "50%",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <TrendingUpIcon />
              </Avatar>
            ) : this.props.parentUser.history["season" + this.state.season]
              .rankhistory[
              this.props.parentUser.history["season" + this.state.season]
                .rankhistory.length - 1
            ] >
            this.props.parentUser.history["season" + this.state.season]
              .rankhistory[
              this.props.parentUser.history["season" + this.state.season]
                .rankhistory.length - 2
            ] ? (
              <Avatar
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#d10b26",
                  left: "50%",
                  top: "50%",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <TrendingDownIcon />
              </Avatar>
            ) : this.props.parentUser.history["season" + this.state.season]
              .rankhistory[
              this.props.parentUser.history["season" + this.state.season]
                .rankhistory.length - 1
            ] ===
            this.props.parentUser.history["season" + this.state.season]
              .rankhistory[
              this.props.parentUser.history["season" + this.state.season]
                .rankhistory.length - 2
            ] ? (
              <Avatar
                style={{
                  color: "#FFFFFF",

                  backgroundColor: "#212121",
                  left: "50%",
                  top: "50%",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <ShowChartIcon />
              </Avatar>
            ) : (
              <Avatar
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#43A047",
                  left: "50%",
                  top: "50%",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <TrendingUpIcon />
              </Avatar>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Rank);
