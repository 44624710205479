import React, { Component } from "react";
import Images from "../../resources/index";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import StandingsLanding from "../Home/StandingsLanding";
import Schedule from "../Home/Schedule";
import FadeInSection from "./FadeIn";
import GroupIcon from "@material-ui/icons/Group";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import Paper from "@material-ui/core/Paper";

class LaTri extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <section style={{ marginTop: "4rem" }}>
        <Container maxWidth={false} className="landing-container">
          <FadeInSection trans={"grow"}>
            <Grid container spacing={5} style={{ margin: "0", width: "100%" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                lg={4}
                style={{ margin: "auto" }}
              >
                <Paper
                  style={{
                    width: "90%",
                    height: "120px",
                    border: "1px solid rgba(255, 255, 255, 0.23)",
                    backgroundColor: "black"
                  }}
                >
                  <GroupIcon
                    className="main-theme-color"
                    style={{
                      color: "white",
                      margin: "-20px 20px 0 15px",
                      float: "left",
                      width: "50px",
                      height: "auto",
                      borderRadius: "10%",
                      borderStyle: "solid",
                      borderColor: "black"
                    }}
                  />

                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{
                      paddingTop: "45px",
                      align: "center",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      fontFamily: "Poppins"
                    }}
                  >
                    <FormattedMessage id="create.your.team" />
                  </Typography>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                lg={4}
                style={{ margin: "auto" }}
              >
                <Paper
                  style={{
                    width: "90%",
                    height: "120px",
                    border: "1px solid rgba(255, 255, 255, 0.23)",
                    backgroundColor: "black"
                  }}
                >
                  <ShowChartIcon
                    className="main-theme-color"
                    style={{
                      color: "white",
                      margin: "-20px 20px 0 15px",
                      float: "left",
                      width: "50px",
                      height: "auto",
                      borderRadius: "10%",
                      borderStyle: "solid",
                      borderColor: "black"
                    }}
                  />{" "}
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{
                      paddingTop: "45px",
                      align: "center",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      fontFamily: "Poppins"
                    }}
                  >
                    <FormattedMessage id="collect.points" />
                  </Typography>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                lg={4}
                style={{ margin: "auto" }}
              >
                <Paper
                  style={{
                    width: "90%",
                    height: "120px",
                    border: "1px solid rgba(255, 255, 255, 0.23)",
                    backgroundColor: "black"
                  }}
                >
                  <EmojiEventsIcon
                    className="main-theme-color"
                    style={{
                      color: "white",
                      margin: "-20px 20px 0 15px",
                      float: "left",
                      width: "50px",
                      height: "auto",
                      borderRadius: "10%",
                      borderStyle: "solid",
                      borderColor: "black"
                    }}
                  />{" "}
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{
                      paddingTop: "45px",
                      align: "center",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      fontFamily: "Poppins"
                    }}
                  >
                    <FormattedMessage id="win.the.league" />
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </FadeInSection>
        </Container>
      </section>
    );
  }
}

export default LaTri;
