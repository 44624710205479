import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from "@material-ui/icons/Group";
import BarChartIcon from "@material-ui/icons/BarChart";
import EventIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import { NavLink } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userLogout } from "../actions/miscActions";
import { bindActionCreators } from "redux";
import SettingsIcon from "@material-ui/icons/Settings";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Hidden from "@material-ui/core/Hidden";
import { FormattedMessage } from "react-intl";
import HelpIcon from "@material-ui/icons/Help";
import Images from "../resources/index";
import Footer from "./Footer";
import PieChartIcon from "@material-ui/icons/PieChart";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";

const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: "#212121"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    //marginRight: 36,
    position: "fixed"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  activeDark: {
    backgroundColor: "#535353"
  },
  activeLight: {
    backgroundColor: "whitesmoke"
  }
}));

const MiniDrawer = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let user = "";
  let lights = localStorage.getItem("theme");

  if (props.user.username !== undefined) {
    user = props.user.username;
  }

  const handleDrawerToggle = () => {
    if (window.innerWidth < 960) {
      setMobileOpen(!mobileOpen);
    } else {
      setOpen(!open);
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeToken = () => {
    localStorage.removeItem("JWT");
    props.userLogout();
  };

  const isActive = value => {
    if (props.location.pathname.toLowerCase() === value) {
      if (lights === "light") {
        return classes.activeLight;
      } else {
        return classes.activeDark;
      }
    } else {
      return "";
    }
  };

  const handleThemeChange = () => {
    props.onSelectTheme();
    handleClose();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          <NavLink
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              cursor: "default"
            }}
            to="/home"
          >
            {process.env.NODE_ENV === "development" ? (
              <img
                alt="logo"
                src={Images["dev_logo"]}
                style={{
                  width: "60px",
                  height: "auto",
                  display: "block"
                }}
              />
            ) : (
              <img
                alt="logo"
                src={Images["logo"]}
                style={{
                  width: "60px",
                  height: "auto",
                  display: "block"
                }}
              />
            )}
          </NavLink>
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: mobileOpen,
            [classes.drawerClose]: !mobileOpen
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: mobileOpen,
              [classes.drawerClose]: !mobileOpen
            })
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerToggle}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <NavLink
              to="/home"
              style={{
                textDecoration: "none",
                color: "inherit"
              }}
            >
              <ListItem button key={"Dashboard"} onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <DashboardIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="dashboard" />} />
              </ListItem>
            </NavLink>
            <NavLink
              to="/team"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Lineup"} onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <GroupIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="lineup" />} />{" "}
              </ListItem>
            </NavLink>

            <NavLink
              to="/points"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Points"} onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <BarChartIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="points" />} />{" "}
              </ListItem>
            </NavLink>
            <NavLink
              to="/leaderboard"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Leaderboard"} onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <ShowChartIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="leaderboard" />} />{" "}
              </ListItem>
            </NavLink>
            <NavLink
              to="/results"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Results"} onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <EventIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="results" />} />{" "}
              </ListItem>
            </NavLink>
            {props.settings.round !== 1 ? (
              <NavLink
                to="/stats"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  onClick={handleDrawerToggle}
                  button
                  key={"Stats"}
                  className={isActive("/stats")}
                >
                  <ListItemIcon>
                    <PieChartIcon
                      className={
                        lights === "dark"
                          ? "menu-item icon-colorb"
                          : "menu-item icon-colorw"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={<FormattedMessage id="stats" />} />{" "}
                </ListItem>
              </NavLink>
            ) : null}
          </List>
          <Divider />
          <List>
            <ListItem
              button
              key={user}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <ListItemIcon>
                <PersonIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                />
              </ListItemIcon>
              <ListItemText primary={user} />
            </ListItem>
            <NavLink
              to="/about"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem
                button
                key={"About"}
                className={isActive("/about")}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>
                  <HelpIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="about" />} />{" "}
              </ListItem>
            </NavLink>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                component={Link}
                to={"/users/" + props.user._id}
                onClick={function(event) {
                  handleClose();
                  handleDrawerToggle();
                }}
              >
                <PersonIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />{" "}
                <ListItemText primary={<FormattedMessage id="profile" />} />{" "}
              </MenuItem>{" "}
              <MenuItem
                onClick={function(event) {
                  handleClose();
                  handleDrawerToggle();
                }}
                component={Link}
                to={"/settings"}
              >
                <SettingsIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />
                <ListItemText primary={<FormattedMessage id="settings" />} />{" "}
              </MenuItem>
              {/**
               change theme

              <MenuItem
                style={{
                  textDecoration: "none",
                  color: "inherit"
                }}
                onClick={handleThemeChange}
              >
                <WbIncandescentIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />
                <ListItemText primary={<FormattedMessage id="theme" />} />{" "}
              </MenuItem>


               */}
              <MenuItem
                component={Link}
                to={"/"}
                onClick={function(event) {
                  handleClose();
                  handleDrawerToggle();
                  removeToken();
                }}
              >
                <PowerSettingsNewIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />
                <ListItemText primary={<FormattedMessage id="logout" />} />{" "}
              </MenuItem>
            </Menu>
          </List>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerToggle}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List disablePadding={true}>
            <NavLink
              to="/home"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem className={isActive("/home")} button key={"Dashboard"}>
                <ListItemIcon>
                  <DashboardIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <ListItemText
                      primary={<FormattedMessage id="dashboard" />}
                    />
                  }
                />
              </ListItem>
            </NavLink>
            <NavLink
              to="/team"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Lineup"} className={isActive("/team")}>
                <ListItemIcon>
                  <GroupIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="lineup" />} />{" "}
              </ListItem>
            </NavLink>

            <NavLink
              to="/points"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Points"} className={isActive("/points")}>
                <ListItemIcon>
                  <BarChartIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="points" />} />{" "}
              </ListItem>
            </NavLink>
            <NavLink
              to="/leaderboard"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem
                button
                key={"Leaderboard"}
                className={isActive("/leaderboard")}
              >
                <ListItemIcon>
                  <ShowChartIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="leaderboard" />} />{" "}
              </ListItem>
            </NavLink>
            <NavLink
              to="/results"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Results"} className={isActive("/results")}>
                <ListItemIcon>
                  <EventIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="results" />} />{" "}
              </ListItem>
            </NavLink>
            {props.settings.round !== 1 ? (
              <NavLink
                to="/stats"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem button key={"Stats"} className={isActive("/stats")}>
                  <ListItemIcon>
                    <PieChartIcon
                      className={
                        lights === "dark"
                          ? "menu-item icon-colorb"
                          : "menu-item icon-colorw"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={<FormattedMessage id="stats" />} />{" "}
                </ListItem>
              </NavLink>
            ) : null}
          </List>
          <Divider />
          <List disablePadding={true}>
            <ListItem
              button
              key={user}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={isActive("/users/" + user.toLowerCase())}
            >
              <ListItemIcon>
                <PersonIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                />
              </ListItemIcon>
              <ListItemText primary={user} />
            </ListItem>{" "}
            <NavLink
              to="/about"
              style={{
                textDecoration: "none",
                color: "inherit"
              }}
            >
              <ListItem button key={"About"} className={isActive("/about")}>
                <ListItemIcon>
                  <HelpIcon
                    className={
                      lights === "dark"
                        ? "menu-item icon-colorb"
                        : "menu-item icon-colorw"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="about" />} />{" "}
              </ListItem>
            </NavLink>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                component={Link}
                to={"/users/" + props.user._id}
                onClick={handleClose}
                style={{
                  textDecoration: "none",
                  color: "inherit"
                }}
              >
                <PersonIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />{" "}
                <ListItemText primary={<FormattedMessage id="profile" />} />{" "}
              </MenuItem>{" "}
              <MenuItem
                style={{
                  textDecoration: "none",
                  color: "inherit"
                }}
                component={Link}
                to={"/settings"}
                onClick={handleClose}
              >
                <SettingsIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />
                <ListItemText primary={<FormattedMessage id="settings" />} />{" "}
              </MenuItem>
              {/**
               theme change
                             <MenuItem
                style={{
                  textDecoration: "none",
                  color: "inherit"
                }}
                onClick={handleThemeChange}
              >
                <WbIncandescentIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />
                <ListItemText primary={<FormattedMessage id="theme" />} />{" "}
              </MenuItem>

               */}
              <MenuItem
                style={{
                  textDecoration: "none",
                  color: "inherit"
                }}
                component={Link}
                to={"/"}
                onClick={removeToken}
              >
                <PowerSettingsNewIcon
                  className={
                    lights === "dark"
                      ? "menu-item icon-colorb"
                      : "menu-item icon-colorw"
                  }
                  style={{
                    marginRight: "0.4em"
                  }}
                />
                <ListItemText primary={<FormattedMessage id="logout" />} />{" "}
              </MenuItem>
            </Menu>
          </List>
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
        <Footer />
      </main>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userLogout: userLogout
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiniDrawer);
