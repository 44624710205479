import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import BarChartIcon from "@material-ui/icons/BarChart";
import TodayIcon from "@material-ui/icons/Today";

class GameweekPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="gameweek.points" />
            </Typography>
            <Typography variant="h6" gutterBottom>
              {this.props.parentUser.history[
                "season" + this.props.settings.season
              ].points[
                this.props.parentUser.history[
                  "season" + this.props.settings.season
                ].points.length +
                  (this.props.gameweek - (this.props.settings.round - 1)) -
                  1
              ] !== undefined
                ? this.props.parentUser.history[
                    "season" + this.props.settings.season
                  ].points[
                    this.props.parentUser.history[
                      "season" + this.props.settings.season
                    ].points.length +
                      (this.props.gameweek - (this.props.settings.round - 1)) -
                      1
                  ]
                : "N / A"}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            {this.props.parentComponent === "Home" ? (
              <Avatar
                style={{
                  color: "white",
                  backgroundColor: "#212121",

                  left: "50%",
                  top: "50%",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <TodayIcon />
              </Avatar>
            ) : (
              <Avatar
                style={{
                  color: "white",

                  backgroundColor: "#212121",
                  left: "50%",
                  top: "50%",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <BarChartIcon />
              </Avatar>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(GameweekPoints);
