import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Hidden from "@material-ui/core/Hidden";
import Images from "../../resources/index";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = "100%";

const useStyles = makeStyles(theme => ({
  root: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    position: "fixed"
  },
  appBarShift: {
    marginLeft: drawerWidth
  },
  menuButton: {
    marginRight: 18,
    right: 0,
    position: "fixed"
  },
  closeButton: {
    marginRight: 18,
    right: 0,
    position: "fixed",
    top: 0,
    marginTop: "0.5rem"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    alignItems: "center",
    width: drawerWidth,
    padding: "38px",
    backgroundColor: "black"
  },
  drawerClose: {
    alignItems: "center",
    backgroundColor: "black",
    padding: "38px",
    overflowX: "hidden"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  activeDark: {
    backgroundColor: "#535353"
  },
  activeLight: {
    backgroundColor: "whitesmoke"
  }
}));

const LaNav = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActive = value => {
    if (props.pathname.toLowerCase() === value) {
      return classes.activeDark;
    } else {
      return "";
    }
  };

  const handleThemeChange = () => {
    props.onSelectTheme();
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <CssBaseline />
        <AppBar
          elevation={0}
          position="fixed"
          style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.23)" }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar style={{ minHeight: "80px" }}>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px"
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap"
                }}
              >
                <NavLink
                  style={{
                    cursor: "default"
                  }}
                  to="/"
                >
                  <img
                    alt="logo text"
                    src={Images["landinglogo"]}
                    style={{
                      maxWidth: "300px"
                    }}
                  />
                </NavLink>
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {props.textHidden ? null : (
                  <span>
                    <NavLink
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        width: "10%",
                        marginRight: "2rem"
                      }}
                      to="/login"
                    >
                      <Typography
                        className="landing-nav-p"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          fontFamily: "Montserrat"
                        }}
                      >
                        <FormattedMessage id="login" />
                      </Typography>
                    </NavLink>
                    <NavLink
                      style={{
                        width: "10%",
                        marginRight: "2.5em",
                        textDecoration: "none",
                        color: "inherit"
                      }}
                      to="/create"
                    >
                      <Typography
                        className="landing-nav-p"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          fontFamily: "Montserrat"
                        }}
                      >
                        <FormattedMessage id="register" />
                      </Typography>
                    </NavLink>
                  </span>
                )}
              </Grid>
            </Grid>{" "}
          </Toolbar>
        </AppBar>
      </Hidden>
      {props.mobileHidden ? null : (
        <Hidden smUp>
          <SwipeableDrawer
            onOpen={() => null}
            anchor="right"
            variant="temporary"
            classes={{
              paper: clsx({
                [classes.drawerOpen]: mobileOpen,
                [classes.drawerClose]: !mobileOpen
              })
            }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <IconButton
              color="inherit"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.closeButton)}
            >
              <CloseIcon />
            </IconButton>
            <List
              component="nav"
              style={{ placeItems: "center", width: "50%" }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "white"
                }}
                onClick={handleDrawerToggle}
                to="/login"
              >
                <ListItem style={{ justifyContent: "center" }} button>
                  <Typography
                    className="landing-nav-p"
                    style={{ fontWeight: "700", fontSize: "24px" }}
                    variant="body1"
                  >
                    <FormattedMessage id="login" />
                  </Typography>
                </ListItem>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white"
                }}
                onClick={handleDrawerToggle}
                to="/create"
              >
                <ListItem style={{ justifyContent: "center" }} button>
                  <Typography
                    className="landing-nav-p"
                    style={{ fontWeight: "700", fontSize: "24px" }}
                    variant="body1"
                  >
                    <FormattedMessage id="register" />
                  </Typography>
                </ListItem>
              </Link>
            </List>
            <Divider style={{ width: "100%" }} />
            <List component="nav">
              <Link
                style={{
                  textDecoration: "none",
                  color: "white"
                }}
                onClick={handleDrawerToggle}
                to="/schedule"
              >
                <ListItem style={{ justifyContent: "center" }} button>
                  <Typography
                    className="landing-nav-p"
                    style={{ fontWeight: "700", fontSize: "24px" }}
                    variant="body1"
                  >
                    <FormattedMessage id="landing.schedule" />
                  </Typography>
                </ListItem>
              </Link>
            </List>
            <img
              alt="draumadeildin"
              src={Images["footerlogo"]}
              style={{
                maxWidth: "260px",
                position: "fixed",
                bottom: 0
              }}
            />
          </SwipeableDrawer>
          <CssBaseline />
          <AppBar
            position="fixed"
            style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.23)" }}
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open
            })}
          >
            <Toolbar style={{ minHeight: "60px" }}>
              <Hidden smUp>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  className={clsx(classes.menuButton)}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>

              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <NavLink
                    style={{
                      cursor: "default",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                    to="/"
                  >
                    <img
                      alt="logo"
                      src={Images["logo"]}
                      style={{
                        width: "60px",
                        height: "auto",
                        display: "block"
                      }}
                    />
                  </NavLink>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Hidden>
      )}
      <div style={{ marginTop: "5.5rem" }}>{props.children}</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default LaNav;
