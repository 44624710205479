import { ADD_LEAGUES } from "../constants/leagueConstants";

const leagueReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_LEAGUES:
      if (Array.isArray(action.payload)) {
        return [...state, ...action.payload];
      } else {
        return [...state, action.payload];
      }
    default:
      return state;
  }
};

export default leagueReducer;
