import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import EventIcon from "@material-ui/icons/Event";
import Images from "../../resources/index";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  banner(props) {
    return (
      <Typography
        className="frontpage-paper-header"
        variant="overline"
        component="h3"
      >
        <span
          style={{
            marginRight: "1.7rem"
          }}
        >
          <EventIcon style={{ marginLeft: "0.2em" }} />{" "}
          <FormattedMessage id="results.gw" />
          {props.gameweek}
        </span>
      </Typography>
    );
  }

  oneResult(props) {
    const theme = useTheme();
    var cssClass = "black-smoke";
    if (props.blackBackground === false || props.blackBackground == null) {
      if (theme.palette.type === "light") {
        cssClass = "smoke-hoverw";
      } else {
        cssClass = "smoke-hoverb";
      }
    }

    return (
      <Grid container spacing={0} className={cssClass}>
        <Grid item xs={2}>
          {props.series.team0w ? (
            <Typography
              style={{
                fontWeight: "bold",
                color: "green",
                float: "right",
                paddingRight: "1em"
              }}
              variant="overline"
            >
              W
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={2} style={{ alignSelf: "center" }}>
          <img
            style={{
              width: "30px",
              height: "auto",
              marginTop: "1em",
              marginBottom: "1em",
              float: "left"
            }}
            alt="team"
            src={Images.teams[props.series.team0.toLowerCase()]}
          />
        </Grid>
        <Grid item xs={4}>
          {props.series.team0r === 0 && props.series.team1r === 0 ? (
            <Typography
              style={{
                marginTop: "1em"
              }}
            >
              vs
            </Typography>
          ) : (
            <Typography
              style={{
                marginTop: "1.5em",
                fontSize: "revert"
              }}
            >
              {props.series.team0r} - {props.series.team1r}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} style={{ alignSelf: "center" }}>
          <img
            style={{
              width: "30px",
              height: "auto",
              marginTop: "1em",
              marginBottom: "1em",
              float: "right"
            }}
            alt="team"
            src={Images.teams[props.series.team1.toLowerCase()]}
          />
        </Grid>

        <Grid item xs={2}>
          {props.series.team1w ? (
            <Typography
              style={{
                fontWeight: "bold",
                color: "green",
                float: "left",
                paddingLeft: "1em"
              }}
              variant="overline"
            >
              W
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Paper
        style={{
          width: "100%",
          display: "block",
          float: "left",
          cursor: "default"
        }}
        className={this.props.blackBackground ? "black-landing" : null}
      >
        {!this.props.landingPage ? (
          <NavLink
            to="/results"
            style={{
              textDecoration: "none",
              color: "inherit"
            }}
          >
            <this.banner gameweek={this.props.gameweek} />
          </NavLink>
        ) : (
          <NavLink
            to="/schedule"
            style={{
              textDecoration: "none",
              color: "inherit"
            }}
          >
            <this.banner gameweek={this.props.gameweek} />
          </NavLink>
        )}
        {this.props.parentSeries.length === 0 && (
          <span>
            <Divider />
            <Typography
              variant="subtitle2"
              style={{
                color: "grey",
                marginTop: "1rem",
                marginBottom: "1rem"
              }}
            >
              <FormattedMessage id="no.results" />
            </Typography>
          </span>
        )}
        {this.props.parentSeries
          .filter(x => x.round === this.props.gameweek)
          .map((series, i) => (
            <React.Fragment key={i}>
              {" "}
              <Divider />
              {!this.props.landingPage ? (
                <NavLink
                  to={"/results/" + series._id}
                  style={{
                    textDecoration: "none",
                    color: "inherit"
                  }}
                >
                  <this.oneResult
                    series={series}
                    blackBackground={this.props.blackBackground}
                  />
                </NavLink>
              ) : (
                <NavLink
                  to={"/schedule/" + series._id}
                  style={{
                    textDecoration: "none",
                    color: "inherit"
                  }}
                >
                  <this.oneResult
                    series={series}
                    blackBackground={this.props.blackBackground}
                  />
                </NavLink>
              )}
            </React.Fragment>
          ))}
      </Paper>
    );
  }
}

export default Schedule;
