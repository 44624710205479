import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import BarChartIcon from "@material-ui/icons/BarChart";
import Images from "../../resources/index";
import Grid from "@material-ui/core/Grid";
import GroupIcon from "@material-ui/icons/Group";
import CopyrightIcon from "@material-ui/icons/Copyright";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Paper
        square={false}
        style={{
          display: "block",
          float: "left",
          width: "100%"
        }}
      >
        <Typography
          className="frontpage-paper-header"
          variant="overline"
          component="h3"
        >
          <span
            style={{
              marginRight: "1.7rem"
            }}
          >
            <GroupIcon /> <FormattedMessage id="team" />
          </span>
        </Typography>
        <Divider />
        {
          <div
            style={{
              marginTop: "1rem"
            }}
          >
            {this.props.parentUser.lineup.length === 0 && (
              <Typography
                variant="subtitle2"
                style={{ color: "grey", marginBottom: "2rem" }}
              >
                <FormattedMessage id="create.lineup" />
              </Typography>
            )}
            <Grid container spacing={2} alignItems="center" justify="center">
              {this.props.parentUser.lineup.map(
                (item, index) =>
                  this.props.parentUser.captain === item ? (
                    <Grid key={index} item xs={12} sm={6} md={2} lg={2}>
                      <Typography
                        variant="caption"
                        style={{ position: "relative" }}
                      >
                        <CopyrightIcon
                          style={{
                            color: "#eda321",
                            position: "absolute",
                            right: -20,
                            marginLeft: "3.5rem",
                            fontSize: "1.5rem"
                          }}
                        />
                        <img
                          style={{
                            height: "140px",
                            marginBottom: "0.5rem"
                          }}
                          alt={item}
                          src={Images.players[item.toLowerCase()]}
                        />
                        <p>{item} </p>
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid key={index} item xs={12} sm={6} md={2} lg={2}>
                      <Typography variant="caption">
                        <img
                          style={{
                            height: "140px",
                            marginBottom: "0.5rem"
                          }}
                          alt={item}
                          src={Images.players[item.toLowerCase()]}
                        />
                        <p>{item} </p>
                      </Typography>
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        }
      </Paper>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Team);
