import React from "react";

function Footer() {
  return (
    <div style={{ marginTop: "2rem" }}>
      <span
        className="front-title"
        style={{
          bottom: 0,
          left: 0,
          right: 10,
          height: "30px",
          textAlign: "right",
          cursor: "default",
          fontSize: "12px",
          color: "#535353"
        }}
      >
        © Róbert Bernhard Gíslason
      </span>
    </div>
  );
}

export default Footer;
