import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import Images from "../resources/index";
import { NavLink } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { FormattedMessage } from "react-intl";
import Gameweek from "./Home/Gameweek";
import { useTheme } from "@material-ui/core/styles";
import { FormattedDate } from "react-intl";
import { Hidden } from "@material-ui/core";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameweekMax: 21,
      series: []
    };
  }

  componentDidMount() {
    let gameweekMax = this.state.gameweekMax;

    if (this.props.series.length !== 0) {
      gameweekMax = this.props.series.reduce(
        (p, c) => (p.round > c.round ? p : c)
      ).round;
      this.setState({
        gameweekMax
      });
    }

    var seriesArray = [];
    for (let i = 1; i <= gameweekMax; i++) {
      seriesArray.push(this.props.series.filter(x => x.round === i));
    }

    this.setState({
      series: seriesArray
    });
  }

  oneResult(props) {
    const theme = useTheme();

    return (
      <span key={props.series._id}>
        <NavLink
          to={"/results/" + props.series._id}
          style={{
            textDecoration: "none",
            color: "inherit"
          }}
        >
          <Grid
            container
            spacing={0}
            className={
              theme.palette.type === "light" ? "smoke-hoverw" : "smoke-hoverb"
            }
          >
            <Grid item xs={2} sm={1}>
              {props.series.team0w ? (
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    float: "right",
                    paddingRight: "1em"
                  }}
                  variant="overline"
                >
                  W
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={2} sm={1} style={{ alignSelf: "center" }}>
              <img
                style={{
                  width: "50px",
                  height: "50px",
                  marginTop: "1em",
                  marginBottom: "1em",
                  float: "left"
                }}
                alt="team"
                src={Images.teams[props.series.team0.toLowerCase()]}
              />
            </Grid>
            <Grid item xs={4} sm={3} xl={1}>
              {props.series.team0r === 0 && props.series.team1r === 0 ? (
                <Typography
                  style={{
                    marginTop: "1.5em"
                  }}
                >
                  vs
                </Typography>
              ) : (
                <Typography
                  style={{
                    marginTop: "1.5em"
                  }}
                >
                  {props.series.team0r} - {props.series.team1r}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} sm={1} style={{ alignSelf: "center" }}>
              <img
                style={{
                  width: "50px",
                  height: "50px",
                  marginTop: "1em",
                  marginBottom: "1em",
                  float: "right"
                }}
                alt="team"
                src={Images.teams[props.series.team1.toLowerCase()]}
              />
            </Grid>

            <Grid item xs={2} sm={1}>
              {props.series.team1w ? (
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    float: "left",
                    paddingLeft: "1em"
                  }}
                  variant="overline"
                >
                  W
                </Typography>
              ) : null}
            </Grid>
            <Hidden xsDown>
              <Grid item xs={2} xl={1} style={{ marginTop: "1rem" }}>
                <Typography variant="overline">
                  <FormattedMessage
                    id="myId"
                    defaultMessage="{day}. "
                    values={{
                      day: (
                        <FormattedDate
                          value={props.series.date}
                          day="numeric"
                        />
                      )
                    }}
                  />

                  <FormattedMessage
                    id={new Date(props.series.date).getMonth() + "m"}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2} xl={1} style={{ marginTop: "1rem" }}>
                <Typography variant="overline">
                  {new Date(props.series.date).getHours() +
                    ":" +
                    String(new Date(props.series.date).getMinutes()).padStart(
                      2,
                      "0"
                    )}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </NavLink>
      </span>
    );
  }

  render() {
    return (
      <span>
        {/*
                  <Grid container spacing={5}>

                    <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={{
                paddingBottom: "1rem",
                fontWeight: "lighter"
              }}
              className="front-title"
              variant="h4"
              component="h4"
            >
              <FormattedMessage id="results" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

        </Grid>

          */}

        {this.state.series.map((series, i) => (
          <Grid container spacing={5} key={i} id={i + 1}>
            <Grid item xs={12} sm={12} md={3} xl={2}>
              <Gameweek gameweek={i + 1} />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              {series.map((series, i) => (
                <this.oneResult key={i} series={series} />
              ))}
            </Grid>

            <Grid>
              <Divider />
            </Grid>
          </Grid>
        ))}
      </span>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Results);
