import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateLeaderboard = this.updateLeaderboard.bind(this);
  }

  startRound() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(API_URL + "/settings/start", {}, config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        alert("Error starting round");
        console.log(error);
      });
  }

  updateLeaderboard() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(API_URL + "/settings/updateleaderboard", {}, config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        alert("Error updating leaderboard");
        console.log(error);
      });
  }

  endRound() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(API_URL + "/settings/end", {}, config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        alert("Error ending round");
        console.log(error);
      });
  }

  endSeason() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(API_URL + "/settings/endseason", {}, config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        alert("Error ending season");
        console.log(error);
      });
  }

  giveTransfers() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(API_URL + "/settings/givetransfers", {}, config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        alert("Error giving transfers");
        console.log(error);
      });
  }

  render() {
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={{
                paddingBottom: "1rem"
              }}
              variant="h4"
              component="h4"
            >
              Admin page
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Button
              variant="outlined"
              onClick={() => {
                if (window.confirm("Are you sure want to start round?")) {
                  this.startRound();
                }
              }}
            >
              Start round
            </Button>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/results/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined">Confirm results</Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Button
              variant="outlined"
              onClick={() => {
                if (window.confirm("Are you sure want to end round?")) {
                  this.endRound();
                }
              }}
            >
              End round
            </Button>
          </Grid>

          <Grid item xs={6} sm={3} md={3}>
            <Button
              variant="outlined"
              onClick={() => {
                if (
                  window.confirm("Are you sure want to update the leaderboard?")
                ) {
                  this.updateLeaderboard();
                }
              }}
            >
              Update leaderboard
            </Button>
          </Grid>
          <Divider />

          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/addteam/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined" onClick={this.addTeam}>
                Add Team
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/editteam/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined">Edit Team</Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/addplayer/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined" onClick={this.addPlayer}>
                Add Player
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/editplayer/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined">Edit Player</Button>
            </Link>
          </Grid>

          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/addplayertoteam/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined" onClick={this.addPlayer}>
                Add Player to Team
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/addseries/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined">Add Series</Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Link
              to={"/admin/addbrandedleague/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button variant="outlined">Add Branded League</Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Button
              variant="outlined"
              onClick={() => {
                if (window.confirm("Are you sure want to give one transfer?")) {
                  this.giveTransfers();
                }
              }}
            >
              Give transfers
            </Button>
          </Grid>

          <Grid item xs={6} sm={3} md={3}>
            <Button
              variant="outlined"
              onClick={() => {
                if (window.confirm("Are you sure want to end THE SEASON?")) {
                  this.endSeason();
                }
              }}
            >
              End season
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Admin);
