import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Images from "../../resources/index";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import { FormattedDate } from "react-intl";
import "moment/locale/is";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

class LaSeriesProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: {
        date: "October 13, 2014 11:13:00",
        team0: "",
        team1: "",
        team0r: 0,
        team1r: 0,
        team0w: 0,
        team1w: 0
      },
      results: [
        {
          team0: {
            players: [
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              }
            ],
            name: "",
            rounds: 0
          },
          team1: {
            players: [
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              },
              {
                name: "",
                kills: 0,
                deaths: 0,
                mvp: 0,
                points: 0
              }
            ],
            name: "",
            rounds: 0
          }
        }
      ],
      team0players: ["", "", "", "", ""],
      team1players: ["", "", "", "", ""],
      selectedResult: 0,
      loadedResult: false,
      loaded: false,
      errorOpen: false,
      errorText: "Error"
    };
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.body.style.background = "black";

    window.scrollTo(0, 0);

    axios
      .get(API_URL + "/landing/series/" + this.props.match.params.seriesId)
      .then(response => {
        var series = response.data;
        this.setState({
          series: response.data
        });
        axios
          .get(API_URL + "/activeteams")
          .then(response => {
            this.setState({
              teams: response.data,
              team0players: response.data.find(
                x => x._id === series.team0.toLowerCase()
              ).players,
              team1players: response.data.find(
                x => x._id === series.team1.toLowerCase()
              ).players
            });

            axios
              .get(
                API_URL +
                  "/landing/series/" +
                  this.props.match.params.seriesId +
                  "/results"
              )
              .then(response => {
                this.setState({
                  loaded: true
                });
                if (response.data.length !== 0) {
                  this.setState({
                    results: response.data,
                    loadedResult: true
                  });
                }
              })
              .catch(error => {
                this.props.history.push("/");
              });
          })
          .catch(error => {
            this.props.history.push("/");
          });
      })
      .catch(error => {
        this.props.history.push("/");
      });
  }

  //if props change, re-render
  componentWillReceiveProps(nextProps) {
    this.setState({
      series: nextProps.series.find(
        x => x._id === this.props.match.params.seriesId
      )
    });
  }

  //handle input function
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  changeResult(index) {
    this.setState({
      selectedResult: index
    });
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  icon(props) {
    const { active } = props;
    return (
      <div
        className={active ? null : "grey-out"}
        style={{
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: "black"
        }}
      />
    );
  }

  render() {
    if (this.state.loaded === false) {
      return (
        <Grid item xs={12}>
          <div
            style={{
              paddingTop: "1rem"
            }}
          >
            <CircularProgress
              style={{
                color: "#11F211"
              }}
              color="secondary"
            />
          </div>
        </Grid>
      );
    } else {
      return (
        <div>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              <FormattedMessage id="error" /> {this.state.errorText}
            </Alert>
          </Snackbar>

          <Grid
            container
            spacing={3}
            style={{
              margin: 0,
              width: "100%"
            }}
          >
            <Grid item xs={12} sm={3} md={3} lg={4}>
              {/*this.state.series.date ? (
              <Typography
                variant="subtitle2"
                component="p"
                style={{
                  float: "left",
                  fontStyle: "italic"
                }}
              >
                {" "}
                <ScheduleIcon />{" "}
                {localStorage.getItem("language") === "is" ? (
                  <Moment locale="is" format="Do MMMM YYYY - HH:mm">
                    {this.state.series.date}
                  </Moment>
                ) : (
                  <Moment locale="en" format="MMMM Do YYYY - HH:mm">
                    {this.state.series.date}
                  </Moment>
                )}{" "}
              </Typography>
                ) : null */}
              <Typography variant="overline" className="date-result">
                <FormattedMessage
                  id="myId"
                  defaultMessage="{day}. "
                  values={{
                    day: (
                      <FormattedDate
                        value={this.state.series.date}
                        day="numeric"
                      />
                    )
                  }}
                />
                <FormattedMessage
                  id={new Date(this.state.series.date).getMonth() + "m"}
                />{" "}
                {new Date(this.state.series.date).getHours() +
                  ":" +
                  String(
                    new Date(this.state.series.date).getMinutes()
                  ).padStart(2, "0")}{" "}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Typography
                style={{ paddingBottom: "1rem" }}
                variant="h5"
                component="h5"
              >
                <img
                  style={{
                    width: "3rem",
                    marginRight: "1rem"
                  }}
                  alt="team"
                  src={Images.teams[this.state.series.team0.toLowerCase()]}
                />{" "}
                {this.state.series.team0r} - {this.state.series.team1r}{" "}
                <img
                  style={{
                    width: "3rem",
                    marginLeft: "1rem"
                  }}
                  alt="team"
                  src={Images.teams[this.state.series.team1.toLowerCase()]}
                />
              </Typography>
            </Grid>
            {this.state.results[this.state.selectedResult].map ? (
              <Grid item xs={12} sm={12} md={12}>
                <Card
                  style={{
                    maxWidth: 300
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      style={{
                        height: 75,
                        backgroundColor: "black"
                      }}
                      image={
                        Images.maps[
                          this.state.results[this.state.selectedResult].map
                        ]
                      }
                      title={this.state.results[this.state.selectedResult].map}
                    />
                    {/* 
                      <CardContent>
                        <Typography variant="body2" component="p">
                          {this.state.results[this.state.selectedResult].map}
                        </Typography>
                      </CardContent>
                      {this.state.results[this.state.selectedResult]
                        .pickedBy ? (
                        <Typography
                          style={{
                            fontStyle: "italic",
                            fontSize: "12px"
                          }}
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <FormattedMessage id="picked.by" />{" "}
                          {
                            this.state.results[this.state.selectedResult]
                              .pickedBy
                          }
                        </Typography>
                      ) : null}*/}
                  </CardActionArea>
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12} sm={3} md={3} lg={4}>
                {this.state.results[1]?.map
                  ? this.state.results.slice(1).map(x => (
                      <Card key={x._id}>
                        <CardActionArea>
                          <CardMedia
                            style={{
                              height: 75,
                              backgroundColor: "black"
                            }}
                            image={Images.maps[x.map]}
                            title={x.map}
                          />
                          {/*
                            <CardContent>
                              <Typography variant="body2" component="p">
                                {x.map}
                              </Typography>{" "}
                            </CardContent>
                            {x.pickedBy ? (
                              <Typography
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "12px"
                                }}
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                <FormattedMessage id="picked.by" /> {x.pickedBy}
                              </Typography>
                            ) : null}*/}
                        </CardActionArea>
                      </Card>
                    ))
                  : null}
              </Grid>
            )}
          </Grid>

          {this.state.loadedResult === false && this.state.loaded === true ? (
            <span>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team0players[0]}
                    src={
                      Images.players[
                        this.state.team0players[0].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team1players[0]}
                    src={
                      Images.players[
                        this.state.team1players[0].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team0players[1]}
                    src={
                      Images.players[
                        this.state.team0players[1].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team1players[1]}
                    src={
                      Images.players[
                        this.state.team1players[1].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team0players[2]}
                    src={
                      Images.players[
                        this.state.team0players[2].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team1players[2]}
                    src={
                      Images.players[
                        this.state.team1players[2].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team0players[3]}
                    src={
                      Images.players[
                        this.state.team0players[3].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team1players[3]}
                    src={
                      Images.players[
                        this.state.team1players[3].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team0players[4]}
                    src={
                      Images.players[
                        this.state.team0players[4].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    style={{
                      height: "120px",
                      marginTop: "0.5rem"
                    }}
                    alt={this.state.team1players[4]}
                    src={
                      Images.players[
                        this.state.team1players[4].toLowerCase()
                      ] || this.state.series.team0 + "unknown"
                    }
                  />
                </Grid>
              </Grid>
            </span>
          ) : null}
          {this.state.loadedResult === true && this.state.loaded === true ? (
            <span>
              <Grid
                container
                spacing={3}
                style={{
                  margin: 0,
                  width: "100%"
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Divider />
                </Grid>
                {/* 
              <Grid item xs={12} sm={3} md={3}>
                <Button
                  variant="outlined"
                  fullWidth={true}
                  style={{ marginBottom: "1rem" }}
                  onClick={() => {
                    this.setState({ selectedResult: 0 });
                  }}
                  className={0 === this.state.selectedResult ? "smoke" : ""}
                >
                  Overall
                </Button>
              </Grid>

              {this.state.results.slice(1).map((element, index) => {
                return (
                  <Grid item xs={12} sm={3} md={3} key={index}>
                    <Button
                      variant="outlined"
                      fullWidth={true}
                      style={{ marginBottom: "1rem" }}
                      onClick={() => {
                        this.setState({ selectedResult: index + 1 });
                      }}
                      className={
                        index + 1 === this.state.selectedResult ? "smoke" : ""
                      }
                    >
                      <img
                        style={{
                          width: "1.2rem",
                          marginRight: "0.3rem"
                        }}
                        alt="team"
                        src={Images.teams[element.team0.name.toLowerCase()]}
                      />{" "}
                      {element.team0.rounds} - {element.team1.rounds}{" "}
                      <img
                        style={{
                          width: "1.2rem",
                          marginLeft: "0.3rem"
                        }}
                        alt="team"
                        src={Images.teams[element.team1.name.toLowerCase()]}
                      />
                    </Button>
                  </Grid>
                );
              })}*/}
              </Grid>
              <Grid
                container
                spacing={5}
                style={{
                  margin: 0,
                  width: "100%"
                }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TableContainer>
                    <Table style={{ cursor: "default" }}>
                      <TableHead>
                        <TableRow
                          style={{
                            fontStyle: "italic"
                          }}
                        >
                          <TableCell style={{ width: "16%" }}>
                            <img
                              style={{
                                width: "2rem"
                              }}
                              alt="team"
                              src={
                                Images.teams[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.name.toLowerCase()
                                ]
                              }
                            />{" "}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "20%",
                              fontWeight: "bold"
                            }}
                          >
                            {" "}
                            <FormattedMessage id="player" />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "16%",
                              fontWeight: "bold"
                            }}
                          >
                            <FormattedMessage id="kills" />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "16%",
                              fontWeight: "bold"
                            }}
                          >
                            <FormattedMessage id="deaths" />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "16%",
                              fontWeight: "bold"
                            }}
                          >
                            <FormattedMessage id="mvp" />
                          </TableCell>
                          <TableCell
                            style={{
                              width: "16%",
                              fontWeight: "bold"
                            }}
                          >
                            <FormattedMessage id="points" />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team0.players[0].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.players[0].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[0].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[0].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[0].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[0].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[0].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team0.players[1].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.players[1].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[1].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[1].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[1].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[1].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[1].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team0.players[2].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.players[2].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[2].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[2].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[2].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[2].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[2].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team0.players[3].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.players[3].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[3].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[3].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[3].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[3].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[3].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team0.players[4].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.players[4].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team0.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[4].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[4].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[4].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[4].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team0.players[4].points
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TableContainer>
                    <Table style={{ cursor: "default" }}>
                      <TableHead>
                        <TableRow
                          style={{
                            fontStyle: "italic"
                          }}
                        >
                          <TableCell style={{ width: "16%" }}>
                            <img
                              style={{
                                width: "2rem"
                              }}
                              alt="team"
                              src={
                                Images.teams[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.name.toLowerCase()
                                ]
                              }
                            />{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "20%", fontWeight: "bold" }}
                          >
                            <FormattedMessage id="player" />
                          </TableCell>
                          <TableCell
                            style={{ width: "16%", fontWeight: "bold" }}
                          >
                            <FormattedMessage id="kills" />
                          </TableCell>
                          <TableCell
                            style={{ width: "16%", fontWeight: "bold" }}
                          >
                            <FormattedMessage id="deaths" />
                          </TableCell>
                          <TableCell
                            style={{ width: "16%", fontWeight: "bold" }}
                          >
                            <FormattedMessage id="mvp" />
                          </TableCell>
                          <TableCell
                            style={{ width: "16%", fontWeight: "bold" }}
                          >
                            <FormattedMessage id="points" />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team1.players[0].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.players[0].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[0].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[0].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[0].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[0].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[0].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team1.players[1].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.players[1].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[1].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[1].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[1].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[1].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[1].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team1.players[2].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.players[2].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[2].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[2].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[2].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[2].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[2].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team1.players[3].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.players[3].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[3].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[3].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[3].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[3].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[3].points
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell>
                            <img
                              style={{
                                height: "60px",
                                marginTop: "0.5rem"
                              }}
                              alt={
                                this.state.results[this.state.selectedResult]
                                  .team1.players[4].name
                              }
                              src={
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.players[4].name.toLowerCase()
                                ] ||
                                Images.players[
                                  this.state.results[
                                    this.state.selectedResult
                                  ].team1.name.toLowerCase() + "unknown"
                                ]
                              }
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[4].name
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[4].kills
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[4].deaths
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[4].mvp
                            }
                          </TableCell>
                          <TableCell>
                            {
                              this.state.results[this.state.selectedResult]
                                .team1.players[4].points
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </span>
          ) : null}

          {/** 

<Stepper alternativeLabel nonLinear orientation="vertical" activeStep={this.state.selectedResult}>

{this.state.results.map(
    (result, index) =>           
    <Step         onClick={() => this.changeResult(index)}
    >
    <StepLabel StepIconComponent={this.icon}>>
        <img
        style={{
        width: "2rem",
        marginRight: "2rem"
        }}
        alt="team"
        src={Images.teams[result.team0.name.toLowerCase()]}
    />{" "}
    {result.team0.rounds} - {result.team1.rounds}{" "}
    <img
        style={{
        width: "2rem",
        marginLeft: "2rem"
        }}
        alt="team"
        src={Images.teams[result.team1.name.toLowerCase()]}
    />
    </StepLabel>

</Step>

)}
</Stepper>
*/}
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(LaSeriesProfile);
