import React, { PropTypes, Component } from "react";
import { FormattedMessage } from "react-intl";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      loaded: false
    };
  }

  componentDidUpdate() {
    if (!this.state.loaded) {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
      this.setState({ loaded: true });
    }
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    };

    if (diff <= 0) return timeLeft;

    if (diff >= 365.25 * 86400) {
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;
    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;

    return (
      <span>
        <Hidden xsDown>
          <div className="Countdown">
            <span className="Countdown-col">
              <span className="Countdown-col-element">
                <strong>{this.addLeadingZeros(countDown.days)}</strong>
                <span>
                  {countDown.days === 1 ? (
                    <FormattedMessage id="day" />
                  ) : (
                    <FormattedMessage id="days" />
                  )}
                </span>
              </span>
            </span>

            <span className="Countdown-col">
              <span className="Countdown-col-element">
                <strong>{this.addLeadingZeros(countDown.hours)}</strong>
                <span>
                  <FormattedMessage id="hours" />
                </span>
              </span>
            </span>

            <span className="Countdown-col">
              <span className="Countdown-col-element">
                <strong>{this.addLeadingZeros(countDown.min)}</strong>
                <span>
                  <FormattedMessage id="minutes" />
                </span>
              </span>
            </span>

            <span className="Countdown-col">
              <span className="Countdown-col-element">
                <strong>{this.addLeadingZeros(countDown.sec)}</strong>
                <span>
                  <FormattedMessage id="seconds" />
                </span>
              </span>
            </span>
          </div>
        </Hidden>
        <Hidden smUp>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <span className="Countdown-col" style={{ width: "20%" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <strong>{this.addLeadingZeros(countDown.days)}</strong>
                  {countDown.days === 1 ? (
                    <FormattedMessage id="day" />
                  ) : (
                    <FormattedMessage id="days" />
                  )}
                </span>
              </span>
              <span className="Countdown-col" style={{ width: "20%" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <strong>{this.addLeadingZeros(countDown.hours)}</strong>
                  <span>
                    <FormattedMessage id="shorthours" />
                  </span>
                </span>
              </span>

              <span className="Countdown-col" style={{ width: "20%" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <strong>{this.addLeadingZeros(countDown.min)}</strong>
                  <span>
                    <FormattedMessage id="minutes" />
                  </span>
                </span>
              </span>
            </Grid>
          </Grid>
        </Hidden>
      </span>
    );
  }
}

Countdown.defaultProps = {
  date: new Date()
};

export default Countdown;
