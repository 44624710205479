import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class AdminEditPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = { active: true, price: 0, player: "" };

    this.editPlayer = this.editPlayer.bind(this);
  }

  //handle input changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChange = event => {
    this.setState({
      active: !this.state.active
    });
  };

  editPlayer() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(
        API_URL + "/players/" + this.state.player.toLowerCase(),
        {
          price: this.state.price,
          active: this.state.active
        },
        config
      )
      .then(response => {
        alert("Success!");
      })
      .catch(error => {
        alert("Error!");
      });
  }

  render() {
    console.log(this.state.team);
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <Grid container spacing={3}>
          {" "}
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <Typography variant="h4" component="h4">
              Edit Player
            </Typography>
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="player"
              label="Player"
              type="text"
              id="player"
              onChange={this.handleInputChange("player")}
            />
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="price"
              label="Price"
              type="number"
              id="price"
              onChange={this.handleInputChange("price")}
            />
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <InputLabel>Active</InputLabel>
            <Select value={this.state.active} onChange={this.handleChange}>
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <Button onClick={this.editPlayer}>Confirm</Button>
          </Grid>
          <Grid item xs={1} sm={5} />
        </Grid>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(AdminEditPlayer);
