import { combineReducers } from "redux";
import user from "./userReducer";
import teams from "./teamReducer";
import leagues from "./leagueReducer";
import results from "./resultsReducer";
import players from "./playerReducer";
import leaderboard from "./leaderboardReducer";
import settings from "./settingsReducer";
import series from "./seriesReducer";
import standings from "./standingsReducer";

const appReducer = combineReducers({
  user,
  teams,
  leagues,
  results,
  players,
  leaderboard,
  settings,
  series,
  standings
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    // for all keys defined in your persistConfig(s)
    //storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
