import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { FormattedMessage } from "react-intl";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ReplayIcon from "@material-ui/icons/Replay";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import EmailIcon from "@material-ui/icons/Email";
import { addUser } from "../actions/userActions";
import { addTeams } from "../actions/teamActions";
import { addLeagues } from "../actions/leagueActions";
import { addSeries } from "../actions/seriesActions";
import { addPlayers } from "../actions/playerActions";
import { addLeaderboard } from "../actions/leaderboardActions";
import { addStandings } from "../actions/standingsActions";
import { addSettings } from "../actions/settingsActions";
import { bindActionCreators } from "redux";
import { userLogout } from "../actions/miscActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Images from "../resources/index";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogEmailOpen: false,
      dialogPasswordOpen: false,
      currentPassword: "",
      newPassword: "",
      newPassword2: "",
      email: "",
      successOpen: false,
      errorOpen: false,
      errorText: "Error",
      successText: "",
      loaded: true
    };

    this.handlePasswordDialog = this.handlePasswordDialog.bind(this);
    this.handleEmailDialog = this.handleEmailDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  //handle input function
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handlePasswordDialog() {
    this.setState({
      dialogPasswordOpen: !this.state.dialogPasswordOpen
    });
  }

  handleEmailDialog() {
    this.setState({
      dialogEmailOpen: !this.state.dialogEmailOpen
    });
  }

  handleSuccess() {
    this.setState({
      successOpen: false
    });
  }

  changePassword() {
    if (this.state.newPassword.length < 5) {
      this.setState({
        errorOpen: true,
        errorText: "New password must be 5 characters or more!"
      });
    } else if (this.state.newPassword !== this.state.newPassword2) {
      this.setState({
        errorOpen: true,
        errorText: "Passwords don't match!"
      });
    } else {
      //try changing password
      let config = {
        headers: {
          Authorization: localStorage.getItem("JWT")
        }
      };

      axios
        .patch(
          API_URL + "/users/" + this.props.user._id + "/changepassword",
          {
            oldPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
          },
          config
        )
        .then(response => {
          this.setState({
            successOpen: true,
            successText: response.data,
            dialogPasswordOpen: false
          });
        })
        .catch(error => {
          this.setState({
            errorOpen: true,
            errorText: error.response.data
          });
        });
    }
  }

  changeEmail() {
    //try changing password
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(
        API_URL + "/users/" + this.props.user._id + "/changeemail",
        {
          password: this.state.currentPassword,
          newEmail: this.state.email
        },
        config
      )
      .then(response => {
        this.setState({
          successOpen: true,
          successText: response.data,
          dialogEmailOpen: false
        });
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: error.response.data
        });
      });
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  refreshData() {
    this.setState({
      loaded: false
    });

    var config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    //post login to API
    axios
      .patch(API_URL + "/users/me", null, config)
      .then(response => {
        this.props.userLogout();

        this.props.addUser(response.data);

        let user = response.data;
        //get all teams/players api call
        axios
          .get(API_URL + "/teams", config)
          .then(response => {
            this.props.addTeams(response.data);
            //get all settings api call
            axios
              .get(API_URL + "/settings", config)
              .then(response => {
                var season = response.data.season;
                this.props.addSettings(response.data);
                //get all series api call
                axios
                  .get(
                    API_URL + "/series/season/" + response.data.season,
                    config
                  )
                  .then(response => {
                    this.props.addSeries(response.data);
                    //get all teams/players api call
                    axios
                      .get(
                        API_URL +
                          "/leagues/user/" +
                          user._id +
                          "/season/" +
                          this.props.settings.season,
                        config
                      )
                      .then(response => {
                        this.props.addLeagues(response.data);
                        axios
                          .get(
                            API_URL + "/stats/leaderboard/season/" + season,
                            config
                          )
                          .then(response => {
                            this.props.addLeaderboard(response.data);
                            axios
                              .get(API_URL + "/players/active", config)
                              .then(response => {
                                this.props.addPlayers(response.data);
                                axios
                                  .get(
                                    API_URL +
                                      "/stats/standings/season/" +
                                      season,
                                    config
                                  )
                                  .then(response => {
                                    this.props.addStandings(response.data);

                                    this.setState({
                                      loaded: true,
                                      successOpen: true,
                                      successText:
                                        "Data refreshed successfully!"
                                    });
                                  })
                                  .catch(error => {
                                    this.setState({
                                      errorOpen: true,
                                      errorText: error.response.data
                                    });
                                  });
                              })
                              .catch(error => {
                                this.setState({
                                  errorOpen: true,
                                  errorText: error.response.data
                                });
                              });
                          })
                          .catch(error => {
                            this.setState({
                              errorOpen: true,
                              errorText: error.response.data
                            });
                          });
                      })
                      .catch(error => {
                        this.setState({
                          errorOpen: true,
                          errorText: error.response.data
                        });
                      });
                  });
              })
              .catch(error => {
                this.setState({
                  errorOpen: true,
                  errorText: error.response.data
                });
              });
          })
          .catch(error => {
            this.setState({
              errorOpen: true,
              errorText: error.response.data
            });
          });
      })
      .catch(error => {
        if (error.response) {
          this.setState({
            errorOpen: true,
            errorText: error.response.data,
            loaded: true
          });
        } else {
          this.setState({
            errorOpen: true,
            errorText: "Server is down.",
            loaded: true
          });
        }
      });
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <div
            style={{
              paddingTop: "2rem"
            }}
          >
            <CircularProgress
              style={{
                color: "#11F211"
              }}
              color="secondary"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Grid container spacing={5}>
            <Snackbar
              open={this.state.successOpen}
              autoHideDuration={4000}
              onClose={this.handleSuccess}
            >
              <Alert severity="success">{this.state.successText}</Alert>
            </Snackbar>

            <Snackbar
              open={this.state.errorOpen}
              autoHideDuration={4000}
              onClose={this.handleError}
            >
              <Alert severity="error">
                {" "}
                <FormattedMessage id="error" />
                {this.state.errorText}
              </Alert>
            </Snackbar>

            <Dialog
              onClose={this.handlePasswordDialog}
              open={this.state.dialogPasswordOpen}
            >
              <DialogTitle id="form-dialog-title">
                <FormattedMessage id="change.password" />
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <FormattedMessage id="change.password.text" />{" "}
                </DialogContentText>
                <TextField
                  margin="dense"
                  id="currentpassword"
                  label={<FormattedMessage id="current.password" />}
                  type="password"
                  fullWidth
                  inputProps={{ maxLength: 30, minLength: 5 }}
                  onChange={this.handleChange("currentPassword")}
                />
                <TextField
                  margin="dense"
                  id="newpassword"
                  label={<FormattedMessage id="new.password" />}
                  type="password"
                  fullWidth
                  inputProps={{ maxLength: 30, minLength: 5 }}
                  onChange={this.handleChange("newPassword")}
                />
                <TextField
                  margin="dense"
                  id="newpassword2"
                  label={<FormattedMessage id="confirm.new.password" />}
                  type="password"
                  fullWidth
                  inputProps={{ maxLength: 30, minLength: 5 }}
                  onChange={this.handleChange("newPassword2")}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.changePassword}
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage id="change.password" />
                </Button>

                <Button
                  onClick={this.handlePasswordDialog}
                  variant="contained"
                  color="secondary"
                >
                  <FormattedMessage id="cancel" />{" "}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              onClose={this.handleEmailDialog}
              open={this.state.dialogEmailOpen}
            >
              <DialogTitle id="form-dialog-title">
                <FormattedMessage id="change.email" />
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <FormattedMessage id="change.email.text" />{" "}
                </DialogContentText>
                <TextField
                  margin="dense"
                  id="email"
                  label={<FormattedMessage id="email" />}
                  type="email"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  onChange={this.handleChange("email")}
                />
                <TextField
                  margin="dense"
                  id="password"
                  label={<FormattedMessage id="password" />}
                  type="password"
                  fullWidth
                  inputProps={{ maxLength: 30, minLength: 5 }}
                  onChange={this.handleChange("currentPassword")}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.changeEmail}
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage id="change.email" />
                </Button>

                <Button
                  onClick={this.handleEmailDialog}
                  variant="contained"
                  color="secondary"
                >
                  <FormattedMessage id="cancel" />{" "}
                </Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={12} md={12}>
              <Typography
                style={{
                  paddingBottom: "1rem",
                  fontWeight: "lighter"
                }}
                className="front-title"
                variant="h4"
                component="h4"
              >
                <FormattedMessage id="settings" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Paper
                style={{
                  cursor: "pointer"
                }}
                onClick={this.handlePasswordDialog}
              >
                <VpnKeyIcon
                  style={{ fontSize: "40px", paddingTop: "0.5rem" }}
                />
                <Typography
                  style={{ padding: "0.5rem", fontWeight: "lighter" }}
                >
                  <FormattedMessage id="change.password" />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Paper
                style={{
                  cursor: "pointer"
                }}
                onClick={this.handleEmailDialog}
              >
                <EmailIcon style={{ fontSize: "40px", paddingTop: "0.5rem" }} />
                <Typography
                  style={{ padding: "0.5rem", fontWeight: "lighter" }}
                >
                  <FormattedMessage id="change.email" />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Paper
                style={{
                  cursor: "pointer"
                }}
                onClick={this.refreshData}
              >
                <ReplayIcon
                  style={{ fontSize: "40px", paddingTop: "0.5rem" }}
                />
                <Typography
                  style={{ padding: "0.5rem", fontWeight: "lighter" }}
                >
                  <FormattedMessage id="refresh.data" />
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addUser: addUser,
      addTeams: addTeams,
      addLeagues: addLeagues,
      addPlayers: addPlayers,
      addLeaderboard: addLeaderboard,
      addSeries: addSeries,
      addSettings: addSettings,
      addStandings: addStandings,
      userLogout: userLogout
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
