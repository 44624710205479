import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Images from "../resources/index";
import Divider from "@material-ui/core/Divider";
import CopyrightIcon from "@material-ui/icons/Copyright";
import GroupIcon from "@material-ui/icons/Group";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import GameweekPoints from "./Home/GameweekPoints";
import Gameweek from "./Home/Gameweek";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Button from "@material-ui/core/Button";

class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      user: {
        username: "",
        history: {
          season1: { points: [], rankhistory: [] },
          season2: { points: [], rankhistory: [] },
          season3: { points: [], rankhistory: [] },
          season4: { points: [], rankhistory: [] },
          season5: { points: [], rankhistory: [] }
        },
        lineup: []
      },
      stats: [{ lineup: [] }],
      gameweek: 1,
      leagues: [],
      gameweekMax: 1,
      loaded: false,
      disableDec: true,
      disableInc: true,
      errorOpen: false,
      errorText: "Error"
    };

    this.handleError = this.handleError.bind(this);
    this.toggle = this.toggle.bind(this);
    this.decGameweek = this.decGameweek.bind(this);
    this.incGameweek = this.incGameweek.bind(this);
    this.changeGameweek = this.changeGameweek.bind(this);
  }

  componentDidMount() {
    //GET FROM API
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .get(
        API_URL +
          "/stats/user/" +
          this.props.user._id +
          "/season/" +
          this.props.settings.season,
        config
      )
      .then(response => {
        this.setState({
          stats: response.data,
          statno: response.data.length - 1,
          loaded: true
        });
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: "Fetching data failed.",
          loaded: true
        });
      });

    let gameweek = this.state.gameweek;
    let gameweekMax = this.state.gameweekMax;

    if (this.props.series.length !== 0) {
      if (this.props.settings.round !== 1) {
        gameweek = this.props.settings.round - 1;
        gameweekMax = this.props.settings.round - 1;
        if (this.props.settings.status === "STARTED") {
          gameweek += 1;
          gameweekMax += 1;
        }
      }

      this.setState({
        gameweek,
        gameweekMax
      });
    }

    if (gameweekMax === gameweek) {
      this.setState({
        disableInc: true
      });
    } else {
      this.setState({
        disableInc: false
      });
    }

    if (gameweek === undefined || gameweek === 0 || gameweek === 1) {
      this.setState({
        disableDec: true
      });
    } else {
      this.setState({
        disableDec: false
      });
    }
  }

  changeGameweek(round) {
    this.setState({
      gameweek: round
    });
    if (this.state.gameweekMax === round) {
      this.setState({
        disableInc: true
      });
    } else {
      this.setState({
        disableInc: false
      });
    }
    if (this.state.gameweek === undefined || round === 0 || round === 1) {
      this.setState({
        disableDec: true
      });
    } else {
      this.setState({
        disableDec: false
      });
    }
  }

  incGameweek() {
    if (this.state.gameweek < this.state.gameweekMax) {
      this.setState({
        gameweek: this.state.gameweek + 1,
        disableDec: false
      });
    }

    if (this.state.gameweekMax === this.state.gameweek + 1) {
      this.setState({
        disableInc: true
      });
    } else {
      this.setState({
        disableInc: false
      });
    }
  }

  decGameweek() {
    if (this.state.gameweek > 1) {
      this.setState({
        gameweek: this.state.gameweek - 1,
        disableInc: false
      });
    }

    if (
      this.state.gameweek === undefined ||
      this.state.gameweek - 1 === 0 ||
      this.state.gameweek - 1 === 1
    ) {
      this.setState({
        disableDec: true
      });
    } else {
      this.setState({
        disableDec: false
      });
    }
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  render() {
    if (!this.state.loaded) {
      return (
        <CircularProgress
          style={{
            height: "100px",
            width: "100px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#11F211"
          }}
          color="secondary"
        />
      );
    } else {
      return (
        <div>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              <FormattedMessage id="error" /> {this.state.errorText}
            </Alert>
          </Snackbar>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={6} lg={12}>
                  <Gameweek gameweek={this.state.gameweek} />
                  <Button
                    variant="outlined"
                    onClick={this.decGameweek}
                    disabled={this.state.disableDec}
                    style={{ width: "49%", float: "left", marginTop: "1rem" }}
                  >
                    <ArrowLeftIcon style={{ cursor: "pointer" }} />
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={this.incGameweek}
                    disabled={this.state.disableInc}
                    style={{ width: "49%", float: "right", marginTop: "1rem" }}
                  >
                    <ArrowRightIcon style={{ cursor: "pointer" }} />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={12}>
                  <GameweekPoints
                    parentUser={this.props.user}
                    gameweek={this.state.gameweek}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <Paper
                square={false}
                style={{
                  display: "block",
                  float: "left",
                  width: "100%"
                }}
              >
                <Typography
                  className="frontpage-paper-header"
                  variant="overline"
                  component="h3"
                >
                  <span
                    style={{
                      marginRight: "1.7rem"
                    }}
                  >
                    <GroupIcon /> <FormattedMessage id="team" />
                  </span>
                </Typography>
                <Divider />
                {this.state.stats.find(x => x.round === this.state.gameweek) ===
                undefined ? (
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "grey",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      textDecoration: "none",
                      cursor: "default"
                    }}
                  >
                    <FormattedMessage id="no.lineup" />
                  </Typography>
                ) : (
                  <Grid
                    style={{ paddingTop: "1rem" }}
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                  >
                    {this.state.stats
                      .find(x => x.round === this.state.gameweek)
                      .lineup.map(
                        item =>
                          this.state.stats.find(
                            x => x.round === this.state.gameweek
                          ).captain === item ? (
                            <Grid key={item} item xs={12} sm={6} md={2}>
                              <Typography
                                key={item}
                                style={{ position: "relative" }}
                                variant="caption"
                              >
                                <Tooltip
                                  disableTouchListener={true}
                                  title={
                                    <FormattedMessage id="captain.tooltip" />
                                  }
                                >
                                  <CopyrightIcon
                                    style={{
                                      color: "#eda321",
                                      position: "absolute",
                                      right: "-20px",
                                      marginLeft: "3.5rem",
                                      fontSize: "1.5rem"
                                    }}
                                  />
                                </Tooltip>
                                <img
                                  style={{
                                    height: "120px",
                                    marginBottom: "0.5rem"
                                  }}
                                  alt={item}
                                  src={Images.players[item.toLowerCase()]}
                                />
                                <p
                                  style={{
                                    cursor: "default"
                                  }}
                                >
                                  {item}{" "}
                                </p>
                                <Tooltip
                                  disableTouchListener
                                  title={
                                    <div>
                                      <FormattedMessage id="kills" />: {" "}
                                      {this.props.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.props.settings.season
                                        )
                                        ?.rounds.filter(
                                          y => y.round === this.state.gameweek
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.kills;
                                        }, 0) || 0}
                                      <br />
                                      <FormattedMessage id="deaths" />:{" "}
                                      {this.props.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.props.settings.season
                                        )
                                        ?.rounds.filter(
                                          y => y.round === this.state.gameweek
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.deaths;
                                        }, 0) || 0}
                                      <br />
                                      <FormattedMessage id="mvp" />:{" "}
                                      {this.props.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.props.settings.season
                                        )
                                        ?.rounds.filter(
                                          y => y.round === this.state.gameweek
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.mvp;
                                        }, 0) || 0}
                                    </div>
                                  }
                                  aria-label="add"
                                >
                                  <p
                                    style={{
                                      cursor: "default"
                                    }}
                                  >
                                    {this.props.players
                                      .find(x => x.name === item)
                                      .stats.find(
                                        y =>
                                          y.season ===
                                          this.props.settings.season
                                      )
                                      ?.rounds.find(
                                        y => y.round === this.state.gameweek
                                      ) !== undefined
                                      ? this.props.players
                                          .find(x => x.name === item)
                                          .stats.find(
                                            y =>
                                              y.season ===
                                              this.props.settings.season
                                          )
                                          .rounds.filter(
                                            y => y.round === this.state.gameweek
                                          )
                                          .reduce(function(a, b) {
                                            return a + b.points;
                                          }, 0) * 2
                                      : 0 || 0}
                                  </p>
                                </Tooltip>
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid key={item} item xs={12} sm={6} md={2}>
                              <Typography variant="caption">
                                <img
                                  style={{
                                    height: "120px",
                                    marginBottom: "0.5rem"
                                  }}
                                  alt={item}
                                  src={Images.players[item.toLowerCase()]}
                                />
                                <p
                                  style={{
                                    cursor: "default"
                                  }}
                                >
                                  {item}{" "}
                                </p>
                                <Tooltip
                                  disableTouchListener={true}
                                  title={
                                    <div>
                                      <FormattedMessage id="kills" />: {" "}
                                      {this.props.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.props.settings.season
                                        )
                                        ?.rounds.filter(
                                          y => y.round === this.state.gameweek
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.kills;
                                        }, 0) || 0}
                                      <br />
                                      <FormattedMessage id="deaths" />:{" "}
                                      {this.props.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.props.settings.season
                                        )
                                        ?.rounds.filter(
                                          y => y.round === this.state.gameweek
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.deaths;
                                        }, 0) || 0}
                                      <br />
                                      <FormattedMessage id="mvp" />:{" "}
                                      {this.props.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.props.settings.season
                                        )
                                        ?.rounds.filter(
                                          y => y.round === this.state.gameweek
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.mvp;
                                        }, 0) || 0}
                                    </div>
                                  }
                                  aria-label="add"
                                >
                                  <p
                                    style={{
                                      cursor: "default"
                                    }}
                                  >
                                    {this.props.players
                                      .find(x => x.name === item)
                                      .stats.find(
                                        y =>
                                          y.season ===
                                          this.props.settings.season
                                      )
                                      ?.rounds.find(
                                        y => y.round === this.state.gameweek
                                      ) !== undefined
                                      ? this.props.players
                                          .find(x => x.name === item)
                                          .stats.find(
                                            y =>
                                              y.season ===
                                              this.props.settings.season
                                          )
                                          .rounds.filter(
                                            y => y.round === this.state.gameweek
                                          )
                                          .reduce(function(a, b) {
                                            return a + b.points;
                                          }, 0)
                                      : 0}
                                  </p>
                                </Tooltip>
                              </Typography>
                            </Grid>
                          )
                      )}
                  </Grid>
                )}
              </Paper>
            </Grid>
            {/* COMMENTING OUT GAMEWEEK HISTORY
            <Grid item xs={12} sm={5} md={4} lg={3}>
              <Paper
                style={{
                  width: "100%",
                  display: "block",
                  float: "left",
                  position: "relative"
                }}
              >
                <div>
                  <Typography
                    variant="subtitle1"
                    className="frontpage-paper-header"
                    style={{
                      cursor: "default"
                    }}
                    component="h3"
                  >
                    <FormattedMessage id="gameweek.history" />
                  </Typography>
                  {this.state.stats.length === 0 && (
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: "grey",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        textDecoration: "none"
                      }}
                    >
                      <FormattedMessage id="no.gameweek.history" />
                    </Typography>
                  )}
                </div>
                {this.state.stats
                  .slice(0)
                  .reverse()
                  .map((stats, i) => (
                    <span key={i}>
                      <Divider />
                      <Typography
                        key={i}
                        className="result-entry-mainpage"
                        component="div"
                        variant="body2"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.changeGameweek(stats.round)}
                      >
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            style={{ fontWeight: "bold" }}
                          >
                            {stats.round}
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            {stats.points}{" "}
                            <FormattedMessage id="points.lower" />
                          </Grid>
                        </Grid>
                      </Typography>
                    </span>
                  ))}
              </Paper>
            </Grid>

              */}
          </Grid>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Points);
