import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { addTeams } from "../../actions/teamActions";
import { addLeagues } from "../../actions/leagueActions";
import { addSeries } from "../../actions/seriesActions";
import { addPlayers } from "../../actions/playerActions";
import { addLeaderboard } from "../../actions/leaderboardActions";
import { addStandings } from "../../actions/standingsActions";
import { addSettings } from "../../actions/settingsActions";
import { bindActionCreators } from "redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import GroupIcon from "@material-ui/icons/Group";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Images from "../../resources/index";
import StandingsLanding from "../Home/StandingsLanding";
import Schedule from "../Home/Schedule";
import EventIcon from "@material-ui/icons/Event";
import { userLogout } from "../../actions/miscActions";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import LaNav from "./LaNav";
import { NavLink } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      form: "login",
      loginOpen: true,
      loggedIn: false,
      errorOpen: false,
      errorText: "Error",
      successOpen: false,
      dialogForgotOpen: false,
      forgotEmail: "",
      loaded: true
    };

    this.login = this.login.bind(this);
    this.register = this.register.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleForgotDialog = this.handleForgotDialog.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  componentDidMount() {
    document.body.style.background = "black";
  }

  //handle input changes
  handleChange = name => event => {
    if (name === "username") {
      if (
        /^([a-zA-Z0-9-_]+)$/.test(event.target.value) ||
        event.target.value === ""
      ) {
        this.setState({
          [name]: event.target.value
        });
      }
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };

  handleSuccess() {
    this.setState({
      successOpen: false
    });
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  handleForgotDialog() {
    this.setState({
      dialogForgotOpen: !this.state.dialogForgotOpen
    });
  }

  forgotPassword() {
    axios
      .post(API_URL + "/forgotpassword", {
        email: this.state.forgotEmail
      })
      .then(response => {
        this.setState({
          dialogForgotOpen: false,
          successOpen: true
        });
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: <FormattedMessage id="email.not.found" />
        });
      });
  }

  login(e) {
    if (e.target) {
      e.preventDefault();
    }

    this.loginUser();
  }

  loginUser() {
    const { email, password } = this.state;
    this.setState({
      loaded: false
    });
    if (this.state.email === "" || this.state.password === "") {
      this.setState({
        errorOpen: true,
        errorText: "Input missing"
      });
    } else {
      this.props.userLogout();

      //post login to API
      axios
        .post(API_URL + "/login", {
          email,
          password
        })
        .then(response => {
          //SAVE JWT TOKEN TO LOCALSTORAGE (very important, used for all later API calls)
          localStorage.setItem("JWT", response.data.token);
          this.props.addUser(response.data.user);

          let config = {
            headers: {
              Authorization: response.data.token
            }
          };

          let user = response.data.user;

          if (user.lineup.length === 0) {
            this.setState({
              register: true
            });
          }

          //get all teams/players api call
          axios
            .get(API_URL + "/teams", config)
            .then(response => {
              this.props.addTeams(response.data);
              //get all settings api call
              axios
                .get(API_URL + "/settings", config)
                .then(response => {
                  var season = response.data.season;
                  this.props.addSettings(response.data);
                  //get all series api call
                  axios
                    .get(
                      API_URL + "/series/season/" + response.data.season,
                      config
                    )
                    .then(response => {
                      this.props.addSeries(response.data);

                      axios
                        .get(
                          API_URL +
                            "/leagues/user/" +
                            user._id +
                            "/season/" +
                            season,
                          config
                        )
                        .then(response => {
                          this.props.addLeagues(response.data);
                          axios
                            .get(
                              API_URL + "/stats/leaderboard/season/" + season,
                              config
                            )
                            .then(response => {
                              this.props.addLeaderboard(response.data);
                              axios
                                .get(API_URL + "/players/active", config)
                                .then(response => {
                                  this.props.addPlayers(response.data);
                                  axios
                                    .get(
                                      API_URL +
                                        "/stats/standings/season/" +
                                        season,
                                      config
                                    )
                                    .then(response => {
                                      this.props.addStandings(response.data);

                                      this.setState({
                                        loggedIn: true,
                                        loaded: true
                                      });
                                    })
                                    .catch(error => {
                                      this.setState({
                                        errorOpen: true,
                                        errorText: error.response.data
                                      });
                                    });
                                })
                                .catch(error => {
                                  this.setState({
                                    errorOpen: true,
                                    errorText: error.response.data
                                  });
                                });
                            })
                            .catch(error => {
                              this.setState({
                                errorOpen: true,
                                errorText: error.response.data
                              });
                            });
                        })
                        .catch(error => {
                          this.setState({
                            errorOpen: true,
                            errorText: error.response.data
                          });
                        });
                    });
                })
                .catch(error => {
                  this.setState({
                    errorOpen: true,
                    errorText: error.response.data
                  });
                });
            })
            .catch(error => {
              this.setState({
                errorOpen: true,
                errorText: error.response.data
              });
            });
        })
        .catch(error => {
          if (error.response) {
            this.setState({
              errorOpen: true,
              errorText: error.response.data,
              loaded: true
            });
          } else {
            this.setState({
              errorOpen: true,
              errorText: "Server is down.",
              loaded: true
            });
          }
        });
    }
  }

  register(e) {
    e.preventDefault();
    const { email, password, username } = this.state;

    if (this.state.email === "" || this.state.password === "") {
      this.setState({
        errorOpen: true,
        errorText: "Missing email/username"
      });
    } else {
      //post login to API
      axios
        .post(API_URL + "/register", {
          email,
          password,
          username
        })
        .then(response => {
          this.setState({
            register: true
          });

          this.loginUser();
        })
        .catch(error => {
          //login failed, show error (currently only one error response being delivered, can change in the future)
          this.setState({
            errorOpen: true,
            errorText: error.response.data
          });
        });
    }
  }

  render() {
    if (this.state.loggedIn) {
      if (this.state.register === true) {
        return <Redirect to={`/team`} />;
      } else {
        return <Redirect to={`/home`} />;
      }
    } else if (!this.state.loaded) {
      return (
        <div>
          <div
            style={{
              paddingTop: "2rem"
            }}
          >
            <CircularProgress
              style={{
                color: "#11F211"
              }}
              color="secondary"
            />
          </div>
        </div>
      );
    } else {
      return (
        <section style={{ backgroundColor: "black" }}>
          <Snackbar
            open={this.state.successOpen}
            autoHideDuration={4000}
            onClose={this.handleSuccess}
          >
            <Alert severity="success">
              <FormattedMessage id="password.reset.sent" />
            </Alert>
          </Snackbar>

          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              <FormattedMessage id="error" /> {this.state.errorText}
            </Alert>
          </Snackbar>

          <Dialog
            onClose={this.handleForgotDialog}
            aria-labelledby="simple-dialog-title"
            open={this.state.dialogForgotOpen}
            PaperProps={{
              style: {
                backgroundColor: "black"
              }
            }}
          >
            <DialogTitle id="responsive-dialog-title">
              <FormattedMessage id="forgot.password.dialog" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage id="forgot.password.text" />
              </DialogContentText>
              <TextField
                fullWidth
                required
                onChange={this.handleChange("forgotEmail")}
                type="email"
                label="Email"
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.forgotPassword}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="confirm" />
              </Button>
              <Button
                onClick={this.handleForgotDialog}
                color="secondary"
                autoFocus
                variant="contained"
              >
                <FormattedMessage id="close" />
              </Button>
            </DialogActions>
          </Dialog>
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={12}>
                    <NavLink
                      style={{
                        cursor: "default"
                      }}
                      to="/home"
                    >
                      <img
                        alt="logo"
                        src={Images["frontlogow"]}
                        style={{
                          maxWidth: "250px"
                        }}
                      />
                    </NavLink>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: "-20px" }}
                  >
                    <ButtonGroup style={{ marginTop: "2rem" }} size="large">
                      <Button
                        onClick={() =>
                          this.setState({
                            form: "login",
                            username: "",
                            loginOpen: true
                          })
                        }
                        className={this.state.loginOpen ? null : "smoke"}
                      >
                        <FormattedMessage id="login" />
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            form: "register",
                            username: "",
                            loginOpen: false
                          })
                        }
                        className={this.state.loginOpen ? "smoke" : null}
                      >
                        <FormattedMessage id="register" />
                      </Button>
                    </ButtonGroup>

                    {this.state.form === "register" ? (
                      <form className="form" onSubmit={e => this.register(e)}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={true}
                            style={{ maxWidth: "400px" }}
                            variant="outlined"
                            margin="normal"
                            name="username"
                            required
                            label={<FormattedMessage id="username" />}
                            type="text"
                            id="username"
                            value={this.state.username}
                            inputProps={{ maxLength: 20, minLength: 3 }}
                            onChange={this.handleChange("username")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={true}
                            style={{ maxWidth: "400px" }}
                            variant="outlined"
                            margin="normal"
                            name="email"
                            required
                            label={<FormattedMessage id="email" />}
                            type="email"
                            id="email"
                            value={this.state.email}
                            inputProps={{ maxLength: 60 }}
                            onChange={this.handleChange("email")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={true}
                            style={{ maxWidth: "400px" }}
                            variant="outlined"
                            margin="normal"
                            required
                            name="password"
                            label={<FormattedMessage id="password" />}
                            type="password"
                            id="password"
                            inputProps={{ maxLength: 30, minLength: 5 }}
                            value={this.state.password}
                            onChange={this.handleChange("password")}
                          />
                        </Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          className="main-theme-color"
                          fullWidth={true}
                          style={{
                            marginTop: "1rem",
                            color: "white",
                            maxWidth: "400px"
                          }}
                        >
                          <FormattedMessage id="register" />
                        </Button>
                      </form>
                    ) : (
                      <form className="form" onSubmit={e => this.login(e)}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={true}
                            style={{ maxWidth: "400px" }}
                            variant="outlined"
                            margin="normal"
                            required
                            name="email"
                            label={<FormattedMessage id="email.username" />}
                            type="text"
                            id="email"
                            inputProps={{ maxLength: 40 }}
                            value={this.state.email}
                            onChange={this.handleChange("email")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={true}
                            style={{ maxWidth: "400px" }}
                            variant="outlined"
                            margin="normal"
                            required
                            name="password"
                            label={<FormattedMessage id="password" />}
                            type="password"
                            id="password"
                            inputProps={{ maxLength: 30, minLength: 5 }}
                            value={this.state.password}
                            onChange={this.handleChange("password")}
                          />
                        </Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          className="main-theme-color"
                          fullWidth={true}
                          style={{
                            marginTop: "1rem",
                            color: "white",
                            maxWidth: "400px"
                          }}
                        >
                          <FormattedMessage id="login" />
                        </Button>
                      </form>
                    )}
                    <Hidden xsDown>
                      <Typography
                        variant="subtitle2"
                        style={{
                          marginTop: "1rem",
                          marginRight: "300px",
                          marginBottom: "1rem"
                        }}
                      >
                        <Link onClick={this.handleForgotDialog} variant="body2">
                          <FormattedMessage id="forgot.password" />
                        </Link>
                      </Typography>
                    </Hidden>
                    <Hidden smUp>
                      <Typography
                        variant="subtitle2"
                        style={{
                          marginTop: "1rem",
                          marginRight: "55%",
                          marginBottom: "1rem"
                        }}
                      >
                        <Link onClick={this.handleForgotDialog} variant="body2">
                          <FormattedMessage id="forgot.password" />
                        </Link>
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addUser: addUser,
      addTeams: addTeams,
      addLeagues: addLeagues,
      addPlayers: addPlayers,
      addLeaderboard: addLeaderboard,
      addSeries: addSeries,
      addSettings: addSettings,
      addStandings: addStandings,
      userLogout: userLogout
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(Login);
