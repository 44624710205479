import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class AdminAddTeam extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", manager: "", players: "", team: [], active: true };

    this.addTeam = this.addTeam.bind(this);
  }

  //handle input changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChange = event => {
    this.setState({
      active: event.target.value
    });
  };

  addTeam() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    var lineup = this.state.players.split(",");
    axios
      .post(
        API_URL + "/teams/",
        {
          name: this.state.name,
          players: lineup,
          manager: this.state.manager,
          active: this.state.active
        },
        config
      )
      .then(response => {
        alert("Success!");
      })
      .catch(error => {
        alert("Error!");
      });
  }

  render() {
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <Grid container spacing={3}>
          {" "}
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <Typography variant="h4" component="h4">
              Add Team
            </Typography>
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="name"
              label="Name"
              type="text"
              id="name"
              onChange={this.handleInputChange("name")}
            />
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="players"
              label="Players"
              type="text"
              id="players"
              onChange={this.handleInputChange("players")}
            />
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="manager"
              label="Manager"
              type="text"
              id="manager"
              onChange={this.handleInputChange("manager")}
            />
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <InputLabel>Active</InputLabel>
            <Select value={this.state.active} onChange={this.handleChange}>
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <Button onClick={this.addTeam}>Confirm</Button>
          </Grid>
          <Grid item xs={1} sm={5} />
        </Grid>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(AdminAddTeam);
