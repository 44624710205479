import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import Images from "../../resources/index";
import { NavLink } from "react-router-dom";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

class Leagues extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  oneLeague(props) {
    const theme = useTheme();
    return props.league.branded !== true ? (
      <span key={props.league._id}>
        <Divider />
        <NavLink
          to={"/leagues/" + props.league._id}
          style={{
            textDecoration: "none",
            color: "inherit"
          }}
        >
          <Typography
            className={
              theme.palette.type === "light"
                ? "result-entry-mainpage smoke-hoverw"
                : "result-entry-mainpage smoke-hoverb"
            }
            component="div"
            variant="body2"
          >
            <Grid container spacing={3}>
              <Grid item xs={3} style={{ fontWeight: "bold" }}>
                {props.leaderboard
                  .filter(function(item) {
                    return props.league.members.includes(item._id);
                  })
                  .map(x => x._id)
                  .indexOf(props.parentUser._id) + 1}
              </Grid>
              <Grid item xs={6}>
                {props.league.name}
              </Grid>
            </Grid>
          </Typography>
        </NavLink>
      </span>
    ) : (
      <span key={props.league._id}>
        <Divider />
        <NavLink
          to={"/leagues/" + props.league._id}
          style={{
            textDecoration: "none",
            color: "black"
          }}
        >
          <Typography
            className="result-entry-mainpage"
            style={{
              background: props.league.theme
            }}
            component="div"
            variant="body2"
          >
            <Grid container spacing={3}>
              <Grid item xs={3} style={{ fontWeight: "bold" }}>
                {props.leaderboard
                  .filter(function(item) {
                    return props.league.members.includes(item._id);
                  })
                  .map(x => x._id)
                  .indexOf(props.parentUser._id) + 1}
              </Grid>
              <Grid item xs={6}>
                <img
                  style={{
                    width: "30px",
                    height: "auto"
                  }}
                  alt="team"
                  src={Images.teams[props.league.name.toLowerCase()]}
                />
              </Grid>
            </Grid>
          </Typography>
        </NavLink>
      </span>
    );
  }

  render() {
    return (
      <Paper
        style={{
          width: "100%",
          display: "block",
          float: "left",
          position: "relative"
        }}
      >
        <div>
          <Typography
            variant="overline"
            className="frontpage-paper-header"
            style={{
              cursor: "default"
            }}
            component="h3"
          >
            <span
              style={{
                marginRight: "1.7rem"
              }}
            >
              {" "}
              <SupervisedUserCircleIcon
                style={{
                  verticalAlign: "middle",
                  marginRight: "5px"
                }}
              />{" "}
              <FormattedMessage id="leagues" />
            </span>
          </Typography>
          {this.props.parentLeagues.length === 0 && (
            <>
              {" "}
              <Divider />
              <Typography
                variant="subtitle2"
                style={{
                  color: "grey",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  textDecoration: "none"
                }}
              >
                <FormattedMessage id="no.leagues" />
              </Typography>
            </>
          )}{" "}
        </div>

        {this.props.parentLeagues.map((league, i) => (
          <this.oneLeague
            key={i}
            leaderboard={this.props.leaderboard}
            parentUser={this.props.parentUser}
            league={league}
          />
        ))}
      </Paper>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Leagues);
