import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";

class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <Alert severity="error">404 NOT FOUND</Alert>
        <NavLink to="/">
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: "2rem" }}
            size="large"
          >
            Return
          </Button>
        </NavLink>
      </div>
    );
  }
}

export default NotFoundPage;
