const images = {
  logo: require("./logo.svg"),
  dev_logo: require("./dev-logo.svg"),
  teams: {
    dusty: require("./dusty.svg"),
    fh: require("./fh.svg"),
    "kr.black": require("./kr.black.svg"),
    "kr.white": require("./kr.svg"),
    fylkir: require("./fylkir.svg"),
    "[tilt]": require("./[TILT].svg"),
    þór: require("./þór.svg"),
    tropadeleet: require("./tropadeleet.svg"),
    rísí: require("./rísí.svg"),
    rafík: require("./rafík.svg"),
    teamexile: require("./teamexile.svg"),
    hafið: require("./hafið.svg"),
    goat: require("./goat.svg"),
    xy: require("./xy.svg"),
    aurora: require("./aurora.svg"),
    tindastóll: require("./tindastoll.svg"),
    saga: require("./saga.svg"),
    ármann: require("./armann.svg"),
    vallea: require("./vallea.svg"),
    kórdrengir: require("./kordrengir.svg"),
    logo: require("./logo.svg")
  },
  maps: {
    de_ancient: require("./maps/de_ancient.svg"),
    de_dust2: require("./maps/de_dust2.svg"),
    de_inferno: require("./maps/de_inferno.svg"),
    de_mirage: require("./maps/de_mirage.svg"),
    de_nuke: require("./maps/de_nuke.svg"),
    de_overpass: require("./maps/de_overpass.svg"),
    de_train: require("./maps/de_train.svg"),
    de_vertigo: require("./maps/de_vertigo.svg")
  },
  players: {
    hafiðunknown: require("./players/hafiðunknown.svg"),
    xyunknown: require("./players/xyunknown.svg"),
    dustyunknown: require("./players/dustyunknown.svg"),
    þórunknown: require("./players/thorunknown.svg"),
    auroraunknown: require("./players/auroraunknown.svg"),
    fylkirunknown: require("./players/fylkirunknown.svg"),
    tindastóllunknown: require("./players/tindastollunknown.svg"),
    sagaunknown: require("./players/sagaunknown.svg"),
    ármannunknown: require("./players/armannunknown.svg"),
    valleaunknown: require("./players/valleaunknown.svg"),
    kórdrengirunknown: require("./players/kordrengirunknown.svg"),
    "kr.whiteunknown": require("./players/krunknown.svg"),

    andrehh: require("./players/andrehh.svg"),
    auddzh: require("./players/auddzh.svg"),
    b0ndi: require("./players/b0ndi.svg"),
    bdsm: require("./players/thorunknown.svg"),
    bjarnii: require("./players/bjarnii.svg"),
    clvr: require("./players/dustyunknown.svg"),
    cryths: require("./players/cryths.svg"),
    dethkeik: require("./players/dethkeik.svg"),
    deviant: require("./players/deviant.svg"),
    dozen: require("./players/armannunknown.svg"),
    eddezennn: require("./players/eddezennn.svg"),
    furious: require("./players/furious.svg"),
    inyrmind: require("./players/inyrmind.svg"),
    jwalker: require("./players/jwalker.svg"),
    lefluff: require("./players/lefluff.svg"),
    lelillz: require("./players/lelillz.svg"),
    miðgarðsormur: require("./players/miðgarðsormur.svg"),
    monteliciouz: require("./players/auroraunknown.svg"),
    peter: require("./players/thorunknown.svg"),
    ronin: require("./players/ronin.svg"),
    skipid: require("./players/skipid.svg"),
    summy: require("./players/fylkirunknown.svg"),
    th0rsteinnf: require("./players/th0rsteinnf.svg"),
    tony: require("./players/tony.svg"),
    turbodrake: require("./players/sagaunknown.svg"),
    viruz: require("./players/viruz.svg"),
    vktr: require("./players/vktr.svg"),
    wanker: require("./players/fhunknown.svg"),
    zim: require("./players/fhunknown.svg"),
    zioni: require("./players/krunknown.svg"),
    zolo: require("./players/zolo.svg"),
    m0rall: require("./players/m0rall.svg"),

    rec0: require("./players/rec0.svg"),
    múfasa: require("./players/múfasa.svg"),
    yumikoi: require("./players/yumikoi.svg"),
    cqrax: require("./players/teamexileunknown.svg"),

    eiki47: require("./players/eiki47.svg"),
    snær: require("./players/snær.svg"),
    vikki: require("./players/vikki.svg"),
    hugo: require("./players/hugo.svg"),

    xeri: require("./players/xyunknown.svg"),
    kyro: require("./players/kyro.svg"),
    sveittur: require("./players/sveittur.svg"),

    shockg: require("./players/thorunknown.svg"),

    goa7er: require("./players/goa7er.svg"),
    stalz: require("./players/stalz.svg"),
    denos: require("./players/denos.svg"),
    spike: require("./players/spike.svg"),
    narfi: require("./players/narfi.svg"),

    tight: require("./players/valleaunknown.svg"),

    h0z1d3r: require("./players/h0z1d3r.svg"),
    j0n: require("./players/j0n.svg"),
    keliturbo: require("./players/keliturbo.svg"),
    // eslint-disable-next-line import/no-webpack-loader-syntax
    "minidegreez!": require("./players/minidegreez.svg"),
    tripleg: require("./players/tripleg.svg"),

    kruzer: require("./players/kruzer.svg"),
    hundzi: require("./players/hundzi.svg"),
    "7homsen": require("./players/7homsen.svg"),
    ofvirkur: require("./players/ofvirkur.svg"),
    vargur: require("./players/vargur.svg"),

    adhd: require("./players/adhd.svg"),
    brnr: require("./players/brnr.svg"),
    dom: require("./players/dom.svg"),
    pandaz: require("./players/pandaz.svg"),
    cris: require("./players/cris.svg"),
    guddi: require("./players/sagaunknown.svg"),
    skoon: require("./players/sagaunknown.svg"),

    detinate: require("./players/detinate.svg"),
    dell1: require("./players/dell1.svg"),
    rean: require("./players/thorunknown.svg"),
    allee: require("./players/allee.svg"),
    instant: require("./players/thorunknown.svg"),
    dabbehhh: require("./players/thorunknown.svg"),
    stebbic0c0: require("./players/stebbic0c0.svg"),

    blazter: require("./players/blazter.svg"),
    demantur: require("./players/demantur.svg"),
    xenyy: require("./players/xenyy.svg"),
    hyperactive: require("./players/hyperactive.svg"),
    snky: require("./players/snky.svg"),
    klassy: require("./players/kordrengirunknown.svg"),
    xate: require("./players/kordrengirunknown.svg"),

    zerq: require("./players/fylkirunknown.svg"),
    pat: require("./players/pat.svg"),
    andri2k: require("./players/andri2k.svg"),
    "k-dot": require("./players/k-dot.svg"),
    jolli: require("./players/jolli.svg"),

    // eslint-disable-next-line import/no-webpack-loader-syntax
    "capping!": require("./players/capping.svg"),

    ravle: require("./players/ravle.svg"),
    allimeister: require("./players/allimeister.svg"),
    ronald: require("./players/ronald.svg"),

    kiddidisc0: require("./players/armannunknown.svg"),
    snowy: require("./players/armannunknown.svg"),

    joejoe: require("./players/auroraunknown.svg")
  },
  frontlogow: require("./frontlogow.svg"),
  frontlogo: require("./frontlogo.svg"),
  landinglogo: require("./landinglogo.svg"),
  landing: require("./landings4.webp"),
  landingfallback: require("./landings4fallback.svg"),
  footerlogo: require("./footerlogo.svg"),
  medium: require("./medium.svg"),
  draumadeildin: require("./frontlogo-text.svg"),
  "draumadeildin-logo-black": require("./draumadeildin-logo-black.svg"),
  "draumadeildin-badge": require("./logo.svg")
};

export default images;
