import { ADD_PLAYERS } from "../constants/playerConstants";

const playerReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_PLAYERS:
      if (Array.isArray(action.payload)) {
        return [...state, ...action.payload];
      } else {
        return [...state, action.payload];
      }
    default:
      return state;
  }
};

export default playerReducer;
