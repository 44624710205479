import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import { connect } from "react-redux";
import { addLeagues } from "../actions/leagueActions";
import { addUser } from "../actions/userActions";
import { addTeams } from "../actions/teamActions";
import { addSeries } from "../actions/seriesActions";
import { addPlayers } from "../actions/playerActions";
import { addLeaderboard } from "../actions/leaderboardActions";
import { addStandings } from "../actions/standingsActions";
import { addSettings } from "../actions/settingsActions";
import { bindActionCreators } from "redux";
import { userLogout } from "../actions/miscActions";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CreateIcon from "@material-ui/icons/Create";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";
import Standings from "./Home/Standings";
import Rank from "./Home/Rank";
import Points from "./Home/Points";
import Team from "./Home/Team";
import Bank from "./Home/Bank";
import GameweekPoints from "./Home/GameweekPoints";
import Schedule from "./Home/Schedule";
import Leagues from "./Home/Leagues";
import CircularProgress from "@material-ui/core/CircularProgress";
import Images from "../resources/index";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      createDialogOpen: false,
      joinDialogOpen: false,
      name: "",
      passcode: "",
      leagueId: "",
      leaguePasscode: "",
      /*
      gameweek: this.props.results.reduce((p, c) => (p.round > c.round ? p : c))
        .round,
      gameweekMax: this.props.results.reduce(
        (p, c) => (p.round > c.round ? p : c)
      ).round,*/
      gameweek: 1,
      gameweekMax: 21,
      errorOpen: false,
      errorText: "",
      disableDec: true,
      disableInc: true,
      loaded: true
    };

    this.handleDialog = this.handleDialog.bind(this);
    this.handleJoinDialog = this.handleJoinDialog.bind(this);
    this.handleCreateDialog = this.handleCreateDialog.bind(this);
    this.closeJoinDialog = this.closeJoinDialog.bind(this);
    this.closeCreateDialog = this.closeCreateDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createLeague = this.createLeague.bind(this);
    this.joinLeague = this.joinLeague.bind(this);
    this.decGameweek = this.decGameweek.bind(this);
    this.incGameweek = this.incGameweek.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  fetchData() {
    var config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    //post login to API
    axios
      .patch(API_URL + "/users/me", null, config)
      .then(response => {
        let user = response.data;
        //get all teams/players api call
        axios
          .get(API_URL + "/teams", config)
          .then(response => {
            let teams = response.data;
            //get all settings api call
            axios
              .get(API_URL + "/settings", config)
              .then(response => {
                let settings = response.data;

                //get all series api call
                axios
                  .get(
                    API_URL + "/series/season/" + response.data.season,
                    config
                  )
                  .then(response => {
                    let series = response.data;

                    let gameweek = settings.round;
                    let gameweekMax = 21;

                    //if gameweek more than max, put at max so rounds don't go over allowed rounds
                    if (gameweek > gameweekMax) {
                      gameweek = gameweekMax;
                    }

                    this.setState({
                      gameweek,
                      gameweekMax
                    });

                    if (gameweekMax === gameweek) {
                      this.setState({
                        disableInc: true
                      });
                    } else {
                      this.setState({
                        disableInc: false
                      });
                    }

                    if (
                      gameweek === undefined ||
                      gameweek === 0 ||
                      gameweek === 1
                    ) {
                      this.setState({
                        disableDec: true
                      });
                    } else {
                      this.setState({
                        disableDec: false
                      });
                    }

                    //get all teams/players api call
                    axios
                      .get(
                        API_URL +
                          "/leagues/user/" +
                          user._id +
                          "/season/" +
                          this.props.settings.season,
                        config
                      )
                      .then(response => {
                        let leagues = response.data;
                        axios
                          .get(
                            API_URL +
                              "/stats/leaderboard/season/" +
                              this.props.settings.season,
                            config
                          )
                          .then(response => {
                            let leaderboard = response.data;
                            axios
                              .get(API_URL + "/players/active", config)
                              .then(response => {
                                let players = response.data;
                                axios
                                  .get(
                                    API_URL +
                                      "/stats/standings/season/" +
                                      this.props.settings.season,
                                    config
                                  )
                                  .then(response => {
                                    this.props.userLogout();

                                    this.props.addStandings(response.data);
                                    this.props.addUser(user);
                                    this.props.addTeams(teams);
                                    this.props.addSettings(settings);
                                    this.props.addSeries(series);
                                    this.props.addLeagues(leagues);
                                    this.props.addLeaderboard(leaderboard);
                                    this.props.addPlayers(players);

                                    this.setState({
                                      successOpen: true,
                                      successText:
                                        "Data refreshed successfully!",
                                      loaded: true
                                    });
                                  })
                                  .catch(error => {
                                    this.setState({
                                      errorOpen: true,
                                      errorText: error.response.data
                                    });
                                  });
                              })
                              .catch(error => {
                                this.setState({
                                  errorOpen: true,
                                  errorText: error.response.data
                                });
                              });
                          })
                          .catch(error => {
                            this.setState({
                              errorOpen: true,
                              errorText: error.response.data
                            });
                          });
                      })
                      .catch(error => {
                        this.setState({
                          errorOpen: true,
                          errorText: error.response.data
                        });
                      });
                  });
              })
              .catch(error => {
                this.setState({
                  errorOpen: true,
                  errorText: error.response.data
                });
              });
          })
          .catch(error => {
            this.setState({
              errorOpen: true,
              errorText: error.response.data
            });
          });
      })
      .catch(error => {
        if (error.response) {
          this.setState({
            errorOpen: true,
            errorText: error.response.data,
            loaded: true
          });
        } else {
          this.setState({
            errorOpen: true,
            errorText: "Server is down.",
            loaded: true
          });
        }
      });
  }

  componentDidMount() {
    if (localStorage.getItem("theme") === "light") {
      document.body.style.background = "white";
    } else {
      document.body.style.background = "#303030";
    }

    var today = new Date();
    var seen = new Date(this.props.user.seen);
    if (today - seen > 10 * 60 * 1000) {
      this.setState({
        loaded: false
      });
      this.fetchData();
    } else {
      let gameweek = this.props.settings.round;
      let gameweekMax = 21;

      //if gameweek more than max, put at max so rounds don't go over allowed rounds
      if (gameweek > gameweekMax) {
        gameweek = gameweekMax;
      }

      this.setState({
        gameweek,
        gameweekMax
      });

      if (gameweekMax === gameweek) {
        this.setState({
          disableInc: true
        });
      } else {
        this.setState({
          disableInc: false
        });
      }

      if (gameweek === undefined || gameweek === 0 || gameweek === 1) {
        this.setState({
          disableDec: true
        });
      } else {
        this.setState({
          disableDec: false
        });
      }
    }
  }

  //handle input function
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  handleDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen
    });
  }

  handleCreateDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen,

      createDialogOpen: !this.state.createDialogOpen
    });
  }

  handleJoinDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
      joinDialogOpen: !this.state.joinDialogOpen
    });
  }

  closeJoinDialog() {
    this.setState({
      joinDialogOpen: !this.state.joinDialogOpen
    });
  }

  closeCreateDialog() {
    this.setState({
      createDialogOpen: !this.state.createDialogOpen
    });
  }

  createLeague() {
    if (this.state.name === "" && this.state.passcode === "") {
      this.setState({
        errorOpen: true,
        errorText: "Please fill out necessary input."
      });
    } else {
      let config = {
        headers: {
          Authorization: localStorage.getItem("JWT")
        }
      };

      axios
        .post(
          API_URL + "/leagues",
          {
            name: this.state.name,
            passcode: this.state.passcode
          },
          config
        )
        .then(response => {
          this.props.addLeagues(response.data);
          this.setState({
            modal: false
          });
          const { history } = this.props;
          history.push("/leagues/" + response.data._id);
        })
        .catch(error => {
          this.setState({
            errorOpen: true,
            errorText: error.response.data
          });
        });
    }
  }

  joinLeague() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(
        API_URL + "/leagues/join/" + this.state.leagueId,
        {
          passcode: this.state.leaguePasscode
        },
        config
      )
      .then(response => {
        this.props.addLeagues(response.data);
        const { history } = this.props;
        history.push("/leagues/" + response.data._id);
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: "Joining league failed!"
        });
      });
  }

  incGameweek() {
    if (this.state.gameweek < this.state.gameweekMax) {
      this.setState({
        gameweek: this.state.gameweek + 1,
        disableDec: false
      });
    }

    if (this.state.gameweekMax === this.state.gameweek + 1) {
      this.setState({
        disableInc: true
      });
    } else {
      this.setState({
        disableInc: false
      });
    }
  }

  decGameweek() {
    if (this.state.gameweek > 1) {
      this.setState({
        gameweek: this.state.gameweek - 1,
        disableInc: false
      });
    }

    if (
      this.state.gameweek === undefined ||
      this.state.gameweek - 1 === 0 ||
      this.state.gameweek - 1 === 1
    ) {
      this.setState({
        disableDec: true
      });
    } else {
      this.setState({
        disableDec: false
      });
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <div
            style={{
              paddingTop: "2rem"
            }}
          >
            <CircularProgress
              style={{
                color: "#11F211"
              }}
              color="secondary"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ flexGrow: 1 }}>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              <FormattedMessage id="error" /> {this.state.errorText}
            </Alert>
          </Snackbar>

          <Dialog onClose={this.handleDialog} open={this.state.dialogOpen}>
            <DialogTitle id="simple-dialog-title">
              <FormattedMessage id="league.join.create" />{" "}
            </DialogTitle>
            <List>
              <ListItem autoFocus button onClick={this.handleCreateDialog}>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "#212121", color: "white" }}
                  >
                    <CreateIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<FormattedMessage id="league.create" />}
                />
              </ListItem>
              <ListItem button onClick={this.handleJoinDialog}>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "#212121", color: "white" }}
                  >
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={<FormattedMessage id="league.join" />} />
              </ListItem>
            </List>
          </Dialog>

          <Dialog
            open={this.state.createDialogOpen}
            onClose={this.handleCreateDialog}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <FormattedMessage id="league.create" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage id="league.create.text" />{" "}
              </DialogContentText>

              <TextField
                margin="dense"
                id="name"
                label={<FormattedMessage id="name" />}
                type="text"
                fullWidth
                inputProps={{ maxLength: 20 }}
                onChange={this.handleChange("name")}
              />
              <TextField
                margin="dense"
                id="passcode"
                label={<FormattedMessage id="passcode" />}
                type="password"
                fullWidth
                inputProps={{ maxLength: 20 }}
                onChange={this.handleChange("passcode")}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.createLeague}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="create" />
              </Button>

              <Button
                onClick={this.closeCreateDialog}
                variant="contained"
                color="secondary"
              >
                <FormattedMessage id="cancel" />{" "}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.joinDialogOpen}
            onClose={this.handleJoinDialog}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <FormattedMessage id="league.join" />{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage id="league.join.text" />{" "}
              </DialogContentText>
              <DialogContentText variant="subtitle2">
                <FormattedMessage id="league.id.example" />{" "}
              </DialogContentText>
              <TextField
                margin="dense"
                id="id"
                label={<FormattedMessage id="league.id" />}
                type="text"
                fullWidth
                inputProps={{ maxLength: 40 }}
                onChange={this.handleChange("leagueId")}
              />
              <TextField
                margin="dense"
                id="passcode"
                label={<FormattedMessage id="league.passcode" />}
                type="password"
                fullWidth
                inputProps={{ maxLength: 20 }}
                onChange={this.handleChange("leaguePasscode")}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.joinLeague}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="confirm" />
              </Button>
              <Button
                onClick={this.closeJoinDialog}
                variant="contained"
                color="secondary"
              >
                <FormattedMessage id="cancel" />
              </Button>
            </DialogActions>
          </Dialog>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <NavLink to="/leaderboard" style={{ textDecoration: "none" }}>
                <Rank parentUser={this.props.user} />
              </NavLink>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <NavLink to="/team" style={{ textDecoration: "none" }}>
                <Bank parentUser={this.props.user} />
              </NavLink>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <NavLink to="/points" style={{ textDecoration: "none" }}>
                <Points parentUser={this.props.user} />
              </NavLink>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <NavLink to="/points" style={{ textDecoration: "none" }}>
                <GameweekPoints
                  parentUser={this.props.user}
                  parentComponent="Home"
                  gameweek={this.props.settings.round - 1}
                />
              </NavLink>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <NavLink to="/team">
                <Team parentUser={this.props.user} />
              </NavLink>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Schedule
                landingPage={false}
                parentSeries={this.props.series}
                gameweek={this.state.gameweek}
              />

              <Button
                variant="outlined"
                onClick={this.decGameweek}
                disabled={this.state.disableDec}
                style={{ width: "49%", marginTop: "0.5em", float: "left" }}
              >
                <ArrowLeftIcon style={{ cursor: "pointer" }} />
              </Button>

              <Button
                variant="outlined"
                onClick={this.incGameweek}
                disabled={this.state.disableInc}
                style={{ width: "49%", marginTop: "0.5em", float: "right" }}
              >
                <ArrowRightIcon style={{ cursor: "pointer" }} />
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Leagues
                parentUser={this.props.user}
                parentLeagues={this.props.leagues}
              />

              <Button
                color="primary"
                style={{
                  float: "right",
                  top: "0.25rem",
                  right: "0.25em",
                  color: "inherit"
                }}
                size="small"
                onClick={this.handleDialog}
              >
                <FormattedMessage id="join.add" />
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Standings />
            </Grid>

            {/*
            <Grid item xs={12} sm={6} md={3}>
              <iframe
                title="lenovo"
                src="https://player.twitch.tv/?autoplay=false&channel=rafithrottir"
                frameBorder="0"
                allowFullScreen={true}
                autoPlay={false}
                scrolling="no"
                height="300"
                width="550"
              />
            </Grid> */}
          </Grid>
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addUser: addUser,
      addTeams: addTeams,
      addLeagues: addLeagues,
      addPlayers: addPlayers,
      addLeaderboard: addLeaderboard,
      addSeries: addSeries,
      addSettings: addSettings,
      addStandings: addStandings,
      userLogout: userLogout
    },
    dispatch
  );
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
