import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class AdminAddSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team1selected: "",
      team2selected: "",
      date: "",
      season: this.props.settings.season,
      round: 0
    };
    this.addSeries = this.addSeries.bind(this);
  }

  //handle input changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChange = event => {
    this.setState({
      active: event.target.value
    });
  };

  addSeries() {
    console.log(this.state.team1selected);
    console.log(this.state.team2selected);
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .post(
        API_URL + "/series/",
        {
          team0: this.state.team1selected,
          team1: this.state.team2selected,
          season: this.state.season,
          round: this.state.round,
          date: this.state.date
        },
        config
      )
      .then(response => {
        alert("Success!");
      })
      .catch(error => {
        alert("Error!");
      });
  }

  //handle input function
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleTeamChange = name => event => {
    let teamObject = this.props.teams.find(x => x.name === event.target.value);
    if (name === "team1selected") {
      this.setState({
        team1selected: teamObject.name
      });
    } else {
      this.setState({
        team2selected: teamObject.name
      });
    }
  };

  render() {
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            Add Series
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Team1</InputLabel>
            <Select
              value={this.state.team1selected}
              onChange={this.handleTeamChange("team1selected")}
            >
              {this.props.teams.map((x, index) => (
                <MenuItem value={x.name} key={index}>
                  {x.name}
                </MenuItem>
              ))}{" "}
            </Select>{" "}
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Team2</InputLabel>
            <Select
              value={this.state.team2selected}
              onChange={this.handleTeamChange("team2selected")}
            >
              {this.props.teams.map((x, index) => (
                <MenuItem value={x.name} key={index}>
                  {x.name}
                </MenuItem>
              ))}{" "}
            </Select>{" "}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="season"
              label="Season"
              type="number"
              id="season"
              value={this.state.season}
              onChange={this.handleInputChange("season")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              name="round"
              label="Round"
              type="number"
              id="round"
              value={this.state.round}
              onChange={this.handleInputChange("round")}
            />{" "}
            <TextField
              variant="outlined"
              margin="normal"
              name="round"
              type="datetime-local"
              id="round"
              value={this.state.date}
              onChange={this.handleInputChange("date")}
            />{" "}
          </Grid>
          <Grid item xs={12}>
            <Button onClick={this.addSeries}>Confirm</Button>
          </Grid>
        </Grid>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(AdminAddSeries);
