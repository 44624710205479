import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";

class AdminEditTeam extends Component {
  constructor(props) {
    super(props);
    this.state = { manager: null, players: null, team: "", active: null };

    this.editTeam = this.editTeam.bind(this);
  }

  //handle input changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChange = event => {
    this.setState({
      team: event.target.value
    });
  };

  handleChangeActive = event => {
    this.setState({
      active: event.target.value
    });
  };

  editTeam() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    if (this.state.players) {
      var lineup = this.state.players.split(",");
    }
    axios
      .patch(
        API_URL + "/teams/" + this.state.team.toLowerCase(),
        {
          players: lineup,
          manager: this.state.manager,
          active: this.state.active
        },
        config
      )
      .then(response => {
        alert("Success!");
      })
      .catch(error => {
        alert("Error!");
      });
  }

  render() {
    console.log(this.state.team);
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" component="h4">
              Edit Team
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl variant="filled">
              <InputLabel>Team</InputLabel>
              <Select value={this.state.team} onChange={this.handleChange}>
                {this.props.teams.map(
                  /*<MenuItem value={10}>Ten</MenuItem>*/ x => (
                    <MenuItem key={x.name} value={x.name}>
                      {x.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="players"
              label="Players"
              type="text"
              id="players"
              onChange={this.handleInputChange("players")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="manager"
              label="Manager"
              type="text"
              id="manager"
              onChange={this.handleInputChange("manager")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel>Active</InputLabel>
            <Select
              value={this.state.active}
              onChange={this.handleChangeActive}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button onClick={this.editTeam}>Confirm</Button>
          </Grid>
        </Grid>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(AdminEditTeam);
