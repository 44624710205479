import { ADD_SETTINGS } from "../constants/settingsConstants";

const settingsReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_SETTINGS:
      return action.payload;
    default:
      return state;
  }
};

export default settingsReducer;
