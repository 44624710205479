import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { addTeams } from "../../actions/teamActions";
import { addLeagues } from "../../actions/leagueActions";
import { addSeries } from "../../actions/seriesActions";
import { addPlayers } from "../../actions/playerActions";
import { addLeaderboard } from "../../actions/leaderboardActions";
import { addStandings } from "../../actions/standingsActions";
import { addSettings } from "../../actions/settingsActions";
import { bindActionCreators } from "redux";
import Images from "../../resources/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Schedule from "../Home/Schedule";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import GroupIcon from "@material-ui/icons/Group";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import { NavLink } from "react-router-dom";
import LaNav from "./LaNav";
import LaHero from "./LaHero";
import LaInfo from "./LaInfo";
import LaTri from "./LaTri";
import LaFooter from "./LaFooter";
import Divider from "@material-ui/core/Divider";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      form: "login",
      loginOpen: true,
      loggedIn: false,
      errorOpen: false,
      errorText: "Error",
      successOpen: false,
      dialogForgotOpen: false,
      loaded: false,
      forgotEmail: "",
      forgotEmail2: "",
      gameweek: 1,
      gameweekMax: 21,
      series: [],
      standings: [],
      disableDec: true,
      disableInc: true
    };
    this.decGameweek = this.decGameweek.bind(this);
    this.incGameweek = this.incGameweek.bind(this);
  }

  componentDidMount() {
    document.body.style.background = "black";

    axios
      .get(API_URL + "/getsettings", null)
      .then(response => {
        var gameweek = 1;
        var season = response.data.season;
        if (response.data.round > this.state.gameweekMax) {
          gameweek = this.state.gameweekMax;
          this.setState({
            gameweek: this.state.gameweekMax
          });
        } else {
          gameweek = response.data.round;
          this.setState({
            gameweek: response.data.round
          });
        }

        if (gameweek === undefined || gameweek === 0 || gameweek === 1) {
          this.setState({
            disableDec: true
          });
        } else {
          this.setState({
            disableDec: false
          });
        }

        axios
          .get(API_URL + "/standings/season/" + season, null)
          .then(response => {
            this.setState({
              standings: response.data
            });

            axios
              .get(API_URL + "/schedule/season/" + season, null)
              .then(response => {
                this.setState({
                  series: response.data,
                  loaded: true
                });
              })
              .catch(error => {
                this.setState({
                  errorOpen: true,
                  errorText: "Fetching data failed.",
                  loaded: true
                });
              });
          })
          .catch(error => {
            this.setState({
              errorOpen: true,
              errorText: "Fetching data failed.",
              loaded: true
            });
          });
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: "Fetching data failed.",
          loaded: true
        });
      });
  }

  //handle input changes
  handleChange = name => event => {
    if (name === "username") {
      if (
        /^([a-zA-Z0-9-_]+)$/.test(event.target.value) ||
        event.target.value === ""
      ) {
        this.setState({
          [name]: event.target.value
        });
      }
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };

  handleSuccess() {
    this.setState({
      successOpen: false
    });
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  handleForgotDialog() {
    this.setState({
      dialogForgotOpen: !this.state.dialogForgotOpen
    });
  }

  forgotPassword() {
    if (
      this.state.forgotEmail === "" ||
      this.state.forgotEmail !== this.state.forgotEmail2
    ) {
      this.setState({
        errorOpen: true,
        errorText: <FormattedMessage id="email.not.valid" />
      });
    } else {
      axios
        .post(API_URL + "/forgotpassword", {
          email: this.state.forgotEmail
        })
        .then(response => {
          this.setState({
            dialogForgotOpen: false,
            successOpen: true
          });
        })
        .catch(error => {
          this.setState({
            errorOpen: true,
            errorText: <FormattedMessage id="email.not.found" />
          });
        });
    }
  }

  login(e) {
    if (e.target) {
      e.preventDefault();
    }

    this.loginUser();
  }

  loginUser() {
    const { email, password } = this.state;
    this.setState({
      loaded: false
    });
    if (this.state.email === "" || this.state.password === "") {
      this.setState({
        errorOpen: true,
        errorText: "Input missing"
      });
    } else {
      this.props.userLogout();

      //post login to API
      axios
        .post(API_URL + "/login", {
          email,
          password
        })
        .then(response => {
          //SAVE JWT TOKEN TO LOCALSTORAGE (very important, used for all later API calls)
          localStorage.setItem("JWT", response.data.token);
          this.props.addUser(response.data.user);

          let config = {
            headers: {
              Authorization: response.data.token
            }
          };

          let user = response.data.user;
          //get all teams/players api call
          axios
            .get(API_URL + "/teams", config)
            .then(response => {
              this.props.addTeams(response.data);
              //get all settings api call
              axios
                .get(API_URL + "/settings", config)
                .then(response => {
                  var season = response.data.season;
                  this.props.addSettings(response.data);
                  //get all series api call
                  axios
                    .get(
                      API_URL + "/series/season/" + response.data.season,
                      config
                    )
                    .then(response => {
                      this.props.addSeries(response.data);

                      axios
                        .get(
                          API_URL +
                            "/leagues/user/" +
                            user._id +
                            "/season/" +
                            season,
                          config
                        )
                        .then(response => {
                          this.props.addLeagues(response.data);
                          axios
                            .get(
                              API_URL + "/stats/leaderboard/season/" + season,
                              config
                            )
                            .then(response => {
                              this.props.addLeaderboard(response.data);
                              axios
                                .get(API_URL + "/players/active", config)
                                .then(response => {
                                  this.props.addPlayers(response.data);
                                  axios
                                    .get(
                                      API_URL +
                                        "/stats/standings/season/" +
                                        season,
                                      config
                                    )
                                    .then(response => {
                                      this.props.addStandings(response.data);

                                      this.setState({
                                        loggedIn: true,
                                        loaded: true
                                      });
                                    })
                                    .catch(error => {
                                      this.setState({
                                        errorOpen: true,
                                        errorText: error.response.data
                                      });
                                    });
                                })
                                .catch(error => {
                                  this.setState({
                                    errorOpen: true,
                                    errorText: error.response.data
                                  });
                                });
                            })
                            .catch(error => {
                              this.setState({
                                errorOpen: true,
                                errorText: error.response.data
                              });
                            });
                        })
                        .catch(error => {
                          this.setState({
                            errorOpen: true,
                            errorText: error.response.data
                          });
                        });
                    });
                })
                .catch(error => {
                  this.setState({
                    errorOpen: true,
                    errorText: error.response.data
                  });
                });
            })
            .catch(error => {
              this.setState({
                errorOpen: true,
                errorText: error.response.data
              });
            });
        })
        .catch(error => {
          if (error.response) {
            this.setState({
              errorOpen: true,
              errorText: error.response.data,
              loaded: true
            });
          } else {
            this.setState({
              errorOpen: true,
              errorText: "Server is down.",
              loaded: true
            });
          }
        });
    }
  }

  register(e) {
    e.preventDefault();
    const { email, password, username } = this.state;

    if (this.state.email === "" || this.state.password === "") {
      this.setState({
        errorOpen: true,
        errorText: "Missing email/username"
      });
    } else {
      //post login to API
      axios
        .post(API_URL + "/register", {
          email,
          password,
          username
        })
        .then(response => {
          this.loginUser();
        })
        .catch(error => {
          //login failed, show error (currently only one error response being delivered, can change in the future)
          this.setState({
            errorOpen: true,
            errorText: error.response.data
          });
        });
    }
  }

  incGameweek() {
    if (this.state.gameweek < this.state.gameweekMax) {
      this.setState({
        gameweek: this.state.gameweek + 1,
        disableDec: false
      });
    }

    if (this.state.gameweekMax === this.state.gameweek + 1) {
      this.setState({
        disableInc: true
      });
    } else {
      this.setState({
        disableInc: false
      });
    }
  }

  decGameweek() {
    console.log(this.state);
    if (this.state.gameweek > 1) {
      this.setState({
        gameweek: this.state.gameweek - 1,
        disableInc: false
      });
    }

    if (
      this.state.gameweek === undefined ||
      this.state.gameweek - 1 === 0 ||
      this.state.gameweek - 1 === 1
    ) {
      this.setState({
        disableDec: true
      });
    } else {
      this.setState({
        disableDec: false
      });
    }
  }

  render() {
    if (this.state.loggedIn) {
      return <Redirect to={`/home`} />;
    } /*else if (!this.state.loaded) {
      return (
        <div
          style={{
            justifyContent: "center",
            height: "90vh",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Snackbar
            open={this.state.successOpen}
            autoHideDuration={4000}
            onClose={this.handleSuccess}
          >
            <Alert severity="success">
              <FormattedMessage id="password.reset.sent" />
            </Alert>
          </Snackbar>

          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              <FormattedMessage id="error" /> {this.state.errorText}
            </Alert>
          </Snackbar>

          <Dialog
            onClose={this.handleForgotDialog}
            aria-labelledby="simple-dialog-title"
            open={this.state.dialogForgotOpen}
          >
            <DialogTitle id="responsive-dialog-title">
              <FormattedMessage id="forgot.password.dialog" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage id="forgot.password.text" />
              </DialogContentText>
              <TextField
                fullWidth
                required
                onChange={this.handleChange("forgotEmail")}
                type="email"
                label="Email"
                margin="normal"
              />
              <TextField
                fullWidth
                required
                onChange={this.handleChange("forgotEmail2")}
                type="email"
                label="Confirm email"
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.forgotPassword}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="confirm" />
              </Button>
              <Button
                onClick={this.handleForgotDialog}
                color="secondary"
                autoFocus
                variant="contained"
              >
                <FormattedMessage id="close" />
              </Button>
            </DialogActions>
          </Dialog>

          <img
            alt="logo"
            src={Images["logo"]}
            style={{
              maxWidth: "110px",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          />
          <div
            style={{
              paddingTop: "2rem"
            }}
          >
            <CircularProgress
              style={{
                color: "#11F211"
              }}
              color="secondary"
            />
          </div>
        </div>
      );
    } */ else {
      return (
        <div className="landing">
          <LaNav textHidden={false} pathname={this.props.location.pathname}>
            <LaHero standings={this.state.standings} />
            <LaTri />
            <LaInfo
              series={this.state.series}
              gameweek={this.state.gameweek}
              standings={this.state.standings}
              disableInc={this.state.disableInc}
              disableDec={this.state.disableDec}
              decGameweekFunction={this.decGameweek}
              incGameweekFunction={this.incGameweek}
            />
            <LaFooter />
          </LaNav>
        </div>
      );
    }
  }
}

/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addUser: addUser,
      addTeams: addTeams,
      addLeagues: addLeagues,
      addPlayers: addPlayers,
      addLeaderboard: addLeaderboard,
      addSeries: addSeries,
      addSettings: addSettings,
      addStandings: addStandings,
      userLogout: userLogout
    },
    dispatch
  );
}*/

export default connect(
  null
  /*mapDispatchToProps*/
)(Landing);
