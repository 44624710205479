import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { FormattedMessage } from "react-intl";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Grid from "@material-ui/core/Grid";

class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="bank" />
            </Typography>
            <Typography variant="h6" gutterBottom>
              ${this.props.parentUser.bank?.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Avatar
              style={{
                color: "#FFFFFF",
                backgroundColor: "#43A047",
                left: "50%",
                top: "50%",
                WebkitTransform: "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)"
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Bank);
