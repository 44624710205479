import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";

import { API_URL } from "../constants/apiConstants";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { FormattedMessage } from "react-intl";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { FormattedDate } from "react-intl";
import Images from "../resources/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import CopyrightIcon from "@material-ui/icons/Copyright";
import CircularProgress from "@material-ui/core/CircularProgress";

class Casters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      casters: [
        {
          pid: "",
          totalpoints: "",
          round: "",
          season: "",
          points: "",
          captain: "",
          lineup: []
        }
      ],
      players: [{ name: "", stats: [{}] }],
      settings: {},
      loaded: false
    };
  }

  componentDidMount() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .get(API_URL + "/casters", config)
      .then(response => {
        this.setState({
          casters: response.data
        });
        axios
          .get(API_URL + "/getsettings", config)
          .then(response => {
            this.setState({
              settings: response.data
            });
            axios
              .get(API_URL + "/activeplayers", config)
              .then(response => {
                this.setState({
                  players: response.data,
                  loaded: true
                });
              })
              .catch(error => {
                console.log("Getting players failed");
              });
          })
          .catch(error => {
            console.log("Getting settings failed");
          });
      })
      .catch(error => {
        console.log("Getting casters league failed");
      });
  }

  userProfile(user) {
    this.props.history.push("/users/" + user);
  }

  render() {
    if (!this.state.loaded) {
      return (
        <CircularProgress
          style={{
            height: "100px",
            width: "100px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#11F211"
          }}
          color="secondary"
        />
      );
    } else {
      return (
        <div>
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow style={{ cursor: "default" }}>
                    <TableCell style={{ width: "1%", fontWeight: "bold" }}>
                      #
                    </TableCell>
                    <TableCell
                      style={{
                        width: "2%",
                        fontWeight: "bold"
                      }}
                    >
                      <FormattedMessage id="user" />{" "}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        textAlign: "center"
                      }}
                    >
                      <FormattedMessage id="team" />
                    </TableCell>
                    <TableCell style={{ width: "2%", fontWeight: "bold" }}>
                      <FormattedMessage id="points" />
                    </TableCell>
                    <TableCell style={{ width: "2%", fontWeight: "bold" }}>
                      <FormattedMessage id="total.points" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.casters.map((caster, i) => (
                    <TableRow
                      style={{ cursor: "pointer" }}
                      onClick={() => null /*this.userProfile(caster.pid)*/}
                      hover
                      key={i}
                    >
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "22px"
                        }}
                      >
                        {i + 1}
                      </TableCell>
                      <TableCell>
                        <Typography style={{ fontSize: "22px" }}>
                          {caster.pid}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={2}>
                          {caster.lineup.map(item => (
                            <span key={item} style={{ width: "20%" }}>
                              <Typography
                                style={{ position: "relative" }}
                                variant="caption"
                              >
                                <img
                                  style={{
                                    height: "140px",
                                    display: "block",
                                    margin: "auto"
                                  }}
                                  alt={item}
                                  src={Images.players[item.toLowerCase()]}
                                />
                                {caster.captain === item ? (
                                  <CopyrightIcon
                                    style={{
                                      color: "#eda321",
                                      position: "absolute",
                                      top: 0,
                                      marginLeft: "3.5rem",
                                      fontSize: "1.5rem"
                                    }}
                                  />
                                ) : null}

                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "22px"
                                  }}
                                >
                                  {item}
                                </p>
                                <Tooltip
                                  disableTouchListener
                                  title={
                                    <div style={{ fontSize: "22px" }}>
                                      <FormattedMessage id="kills" />: {" "}
                                      {this.state.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.state.settings.season
                                        )
                                        ?.rounds.filter(
                                          y =>
                                            y.round ===
                                            this.state.settings.round - 1
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.kills;
                                        }, 0) || 0}
                                      <br />
                                      <br />
                                      <FormattedMessage id="deaths" />:{" "}
                                      {this.state.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.state.settings.season
                                        )
                                        ?.rounds.filter(
                                          y =>
                                            y.round ===
                                            this.state.settings.round - 1
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.deaths;
                                        }, 0) || 0}
                                      <br />
                                      <br />
                                      <FormattedMessage id="mvp" />:{" "}
                                      {this.state.players
                                        .find(x => x.name === item)
                                        .stats.find(
                                          y =>
                                            y.season ===
                                            this.state.settings.season
                                        )
                                        ?.rounds.filter(
                                          y =>
                                            y.round ===
                                            this.state.settings.round - 1
                                        )
                                        .reduce(function(a, b) {
                                          return a + b.mvp;
                                        }, 0) || 0}
                                    </div>
                                  }
                                  aria-label="add"
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "22px"
                                    }}
                                  >
                                    {caster.captain === item
                                      ? this.state.players
                                          .find(x => x.name === item)
                                          ?.stats.find(
                                            y =>
                                              y.season ===
                                              this.state.settings.season
                                          )
                                          .rounds.find(
                                            y =>
                                              y.round ===
                                              this.state.settings.round - 1
                                          ) !== undefined
                                        ? this.state.players
                                            .find(x => x.name === item)
                                            .stats.find(
                                              y =>
                                                y.season ===
                                                this.state.settings.season
                                            )
                                            .rounds.filter(
                                              y =>
                                                y.round ===
                                                this.state.settings.round - 1
                                            )
                                            .reduce(function(a, b) {
                                              return a + b.points;
                                            }, 0) * 2
                                        : 0
                                      : this.state.players
                                          .find(x => x.name === item)
                                          ?.stats.find(
                                            y =>
                                              y.season ===
                                              this.state.settings.season
                                          )
                                          .rounds.find(
                                            y =>
                                              y.round ===
                                              this.state.settings.round - 1
                                          ) !== undefined
                                        ? this.state.players
                                            .find(x => x.name === item)
                                            .stats.find(
                                              y =>
                                                y.season ===
                                                this.state.settings.season
                                            )
                                            .rounds.filter(
                                              y =>
                                                y.round ===
                                                this.state.settings.round - 1
                                            )
                                            .reduce(function(a, b) {
                                              return a + b.points;
                                            }, 0)
                                        : 0}
                                  </p>
                                </Tooltip>
                              </Typography>
                            </span>
                          ))}
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ fontSize: "22px" }}>
                          {caster.points}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ fontSize: "30px" }}>
                          {caster.totalpoints}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {this.state.settings.status === "STOPPED" ? (
            <Typography style={{ marginTop: "10rem", fontSize: "22px" }}>
              {this.state.settings.round - 1}. UMFERÐ LOKIÐ
            </Typography>
          ) : (
            <Typography style={{ marginTop: "10rem", fontSize: "22px" }}>
              {this.state.settings.round}. UMFERÐ ENN Í GANGI
            </Typography>
          )}
        </div>
      );
    }
  }
}
export default Casters;
