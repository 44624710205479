import React, { Component } from "react";
import { Button, Input, ListGroup, ListGroupItem } from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class AdminResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ebotParams: "",
      team1selected: "",
      team2selected: "",
      matchselected: "",
      result: {},
      ebotResponse: {},
      team1: "",
      team1rw: 0,
      team2: "",
      team2rw: 0,
      player0name: "",
      player0kills: 0,
      player0deaths: 0,
      player0mvp: 0,
      player1name: "",
      player1kills: 0,
      player1deaths: 0,
      player1mvp: 0,
      player2name: "",
      player2kills: 0,
      player2deaths: 0,
      player2mvp: 0,
      player3name: "",
      player3kills: 0,
      player3deaths: 0,
      player3mvp: 0,
      player4name: "",
      player4kills: 0,
      player4deaths: 0,
      player4mvp: 0,
      player5name: "",
      player5kills: 0,
      player5deaths: 0,
      player5mvp: 0,
      player6name: "",
      player6kills: 0,
      player6deaths: 0,
      player6mvp: 0,
      player7name: "",
      player7kills: 0,
      player7deaths: 0,
      player7mvp: 0,
      player8name: "",
      player8kills: 0,
      player8deaths: 0,
      player8mvp: 0,
      player9name: "",
      player9kills: 0,
      player9deaths: 0,
      player9mvp: 0,
      datePlayed: "",
      round: this.props.settings.round,
      map: null,
      pickedBy: null
    };
  }

  confirm() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .post(
        API_URL + "/results/get",
        {
          params: this.state.ebotParams
        },
        config
      )
      .then(response => {
        this.setState({
          result: response.data.result,
          team1: response.data.team1.name,
          team1rw: response.data.team1.rw,
          team2: response.data.team2.name,
          team2rw: response.data.team2.rw,
          player0name: response.data.result[0].nickname,
          player0kills: response.data.result[0].kills,
          player0deaths: response.data.result[0].deaths,
          player0mvp: response.data.result[0].mvp,
          player1name: response.data.result[1].nickname,
          player1kills: response.data.result[1].kills,
          player1deaths: response.data.result[1].deaths,
          player1mvp: response.data.result[1].mvp,
          player2name: response.data.result[2].nickname,
          player2kills: response.data.result[2].kills,
          player2deaths: response.data.result[2].deaths,
          player2mvp: response.data.result[2].mvp,
          player3name: response.data.result[3].nickname,
          player3kills: response.data.result[3].kills,
          player3deaths: response.data.result[3].deaths,
          player3mvp: response.data.result[3].mvp,
          player4name: response.data.result[4].nickname,
          player4kills: response.data.result[4].kills,
          player4deaths: response.data.result[4].deaths,
          player4mvp: response.data.result[4].mvp,
          player5name: response.data.result[5].nickname,
          player5kills: response.data.result[5].kills,
          player5deaths: response.data.result[5].deaths,
          player5mvp: response.data.result[5].mvp,
          player6name: response.data.result[6].nickname,
          player6kills: response.data.result[6].kills,
          player6deaths: response.data.result[6].deaths,
          player6mvp: response.data.result[6].mvp,
          player7name: response.data.result[7].nickname,
          player7kills: response.data.result[7].kills,
          player7deaths: response.data.result[7].deaths,
          player7mvp: response.data.result[7].mvp,
          player8name: response.data.result[8].nickname,
          player8kills: response.data.result[8].kills,
          player8deaths: response.data.result[8].deaths,
          player8mvp: response.data.result[8].mvp,
          player9name: response.data.result[9].nickname,
          player9kills: response.data.result[9].kills,
          player9deaths: response.data.result[9].deaths,
          player9mvp: response.data.result[9].mvp
        });
      })
      .catch(error => {
        console.log(error);
        alert("get ebotStats failed");
      });
  }

  postToAPI() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    var postResult = {
      datePlayed: this.state.datePlayed,
      round: this.state.round,
      map: this.state.map,
      pickedBy: this.state.pickedBy,
      team0: {
        name: this.state.team1,
        rounds: parseInt(this.state.team1rw),
        players: [
          {
            name: this.state.player0name,
            kills: parseInt(this.state.player0kills),
            deaths: parseInt(this.state.player0deaths),
            mvp: parseInt(this.state.player0mvp)
          },
          {
            name: this.state.player1name,
            kills: parseInt(this.state.player1kills),
            deaths: parseInt(this.state.player1deaths),
            mvp: parseInt(this.state.player1mvp)
          },
          {
            name: this.state.player2name,
            kills: parseInt(this.state.player2kills),
            deaths: parseInt(this.state.player2deaths),
            mvp: parseInt(this.state.player2mvp)
          },
          {
            name: this.state.player3name,
            kills: parseInt(this.state.player3kills),
            deaths: parseInt(this.state.player3deaths),
            mvp: parseInt(this.state.player3mvp)
          },
          {
            name: this.state.player4name,
            kills: parseInt(this.state.player4kills),
            deaths: parseInt(this.state.player4deaths),
            mvp: parseInt(this.state.player4mvp)
          }
        ]
      },
      team1: {
        name: this.state.team2,
        rounds: parseInt(this.state.team2rw),
        players: [
          {
            name: this.state.player5name,
            kills: parseInt(this.state.player5kills),
            deaths: parseInt(this.state.player5deaths),
            mvp: parseInt(this.state.player5mvp)
          },
          {
            name: this.state.player6name,
            kills: parseInt(this.state.player6kills),
            deaths: parseInt(this.state.player6deaths),
            mvp: parseInt(this.state.player6mvp)
          },
          {
            name: this.state.player7name,
            kills: parseInt(this.state.player7kills),
            deaths: parseInt(this.state.player7deaths),
            mvp: parseInt(this.state.player7mvp)
          },
          {
            name: this.state.player8name,
            kills: parseInt(this.state.player8kills),
            deaths: parseInt(this.state.player8deaths),
            mvp: parseInt(this.state.player8mvp)
          },
          {
            name: this.state.player9name,
            kills: parseInt(this.state.player9kills),
            deaths: parseInt(this.state.player9deaths),
            mvp: parseInt(this.state.player9mvp)
          }
        ]
      }
    };

    axios
      .post(
        API_URL + "/results",
        {
          result: postResult
        },
        config
      )
      .then(response => {
        console.log(response.data);
        this.setState({
          ebotResponse: response.data
        });
      })
      .catch(error => {
        console.log(error);
        alert("join league failed");
      });
  }

  //handle input function
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleTeamChange = name => event => {
    let teamObject = this.props.teams.find(x => x.name === event.target.value);
    if (name === "team1selected") {
      this.setState({
        team1: teamObject.name,
        player0name: teamObject.players[0],
        player1name: teamObject.players[1],
        player2name: teamObject.players[2],
        player3name: teamObject.players[3],
        player4name: teamObject.players[4],
        pickedBy: teamObject.name
      });
    } else {
      this.setState({
        team2: teamObject.name,
        player5name: teamObject.players[0],
        player6name: teamObject.players[1],
        player7name: teamObject.players[2],
        player8name: teamObject.players[3],
        player9name: teamObject.players[4]
      });
    }
  };

  handleMatchChange = name => event => {
    console.log(event.target.value);
    let teamObject = this.props.teams.find(
      x => x.name === event.target.value.team0
    );
    this.setState({
      team1: teamObject.name,
      player0name: teamObject.players[0],
      player1name: teamObject.players[1],
      player2name: teamObject.players[2],
      player3name: teamObject.players[3],
      player4name: teamObject.players[4],
      pickedBy: teamObject.name
    });

    teamObject = this.props.teams.find(
      x => x.name === event.target.value.team1
    );
    this.setState({
      team2: teamObject.name,
      player5name: teamObject.players[0],
      player6name: teamObject.players[1],
      player7name: teamObject.players[2],
      player8name: teamObject.players[3],
      player9name: teamObject.players[4]
    });
  };

  randomize() {
    this.setState({
      team1rw: Math.floor(Math.random() * 16) + 1,
      team2rw: Math.floor(Math.random() * 16) + 1,
      player0kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player0deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player1kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player1deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player2kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player2deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player3kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player3deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player4kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player4deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player5kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player5deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player6kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player6deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player7kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player7deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player8kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player8deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player9kills: Math.floor(Math.random() * (35 - 7 + 1)) + 7,
      player9deaths: Math.floor(Math.random() * (35 - 7 + 1)) + 7
    });
  }

  render() {
    console.log(this.props);
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <div>
          <h1>Results</h1>
          <div className="middle">
            <div>
              <Input type="url" onChange={this.handleChange("ebotParams")} />
              <Button color="success" onClick={() => this.confirm()}>
                GET
              </Button>
            </div>
            <Input
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto"
              }}
              type="date"
              value={this.state.datePlayed}
              onChange={this.handleChange("datePlayed")}
            />
            <div>
              <Button color="primary" onClick={() => this.randomize()}>
                RANDOMIZE SCORE
              </Button>
            </div>

            <TextField
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "30%",
                marginRight: "auto",
                marginLeft: "auto"
              }}
              label="round"
              type="number"
              value={this.state.round}
              onChange={this.handleChange("round")}
            />
            <div>
              <Select
                value={this.state.matchselected}
                onChange={this.handleMatchChange("matchselected")}
              >
                {this.props.series
                  .filter(x => x.round === this.props.settings.round)
                  .map(
                    /*<MenuItem value={10}>Ten</MenuItem>*/ (x, index) => (
                      <MenuItem value={x} key={index}>
                        {x._id}
                      </MenuItem>
                    )
                  )}{" "}
              </Select>
            </div>
            <div>
              <ListGroup
                style={{
                  display: "flex",
                  width: "50%",
                  float: "left"
                }}
              >
                <InputLabel>Team1</InputLabel>
                <Select
                  value={this.state.team1selected}
                  onChange={this.handleTeamChange("team1selected")}
                >
                  {this.props.teams.map(
                    /*<MenuItem value={10}>Ten</MenuItem>*/ (x, index) => (
                      <MenuItem value={x.name} key={index}>
                        {x.name}
                      </MenuItem>
                    )
                  )}{" "}
                </Select>
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "80%",
                      float: "left"
                    }}
                    type="text"
                    value={this.state.team1}
                    onChange={this.handleChange("team1")}
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.team1rw}
                    onChange={this.handleChange("team1rw")}
                    type="number"
                  />
                </ListGroupItem>
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player0name}
                    onChange={this.handleChange("player0name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player0kills}
                    onChange={this.handleChange("player0kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player0deaths}
                    onChange={this.handleChange("player0deaths")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player0mvp}
                    onChange={this.handleChange("player0mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player1name}
                    onChange={this.handleChange("player1name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player1kills}
                    onChange={this.handleChange("player1kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player1deaths}
                    onChange={this.handleChange("player1deaths")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player1mvp}
                    onChange={this.handleChange("player1mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player2name}
                    onChange={this.handleChange("player2name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player2kills}
                    onChange={this.handleChange("player2kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player2deaths}
                    onChange={this.handleChange("player2deaths")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player2mvp}
                    onChange={this.handleChange("player2mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player3name}
                    onChange={this.handleChange("player3name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player3kills}
                    onChange={this.handleChange("player3kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player3deaths}
                    onChange={this.handleChange("player3deaths")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player3mvp}
                    onChange={this.handleChange("player3mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player4name}
                    onChange={this.handleChange("player4name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player4kills}
                    onChange={this.handleChange("player4kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player4deaths}
                    onChange={this.handleChange("player4deaths")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player4mvp}
                    onChange={this.handleChange("player4mvp")}
                    type="number"
                  />
                </ListGroupItem>
              </ListGroup>
              <ListGroup
                style={{
                  display: "flex",
                  width: "50%",
                  float: "left"
                }}
              >
                <InputLabel>Team2</InputLabel>
                <Select
                  value={this.state.team2selected}
                  onChange={this.handleTeamChange("team2selected")}
                >
                  {this.props.teams.map((x, index) => (
                    <MenuItem value={x.name} key={index}>
                      {x.name}
                    </MenuItem>
                  ))}{" "}
                </Select>
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "80%",
                      float: "left"
                    }}
                    value={this.state.team2}
                    onChange={this.handleChange("team2")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.team2rw}
                    onChange={this.handleChange("team2rw")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player5name}
                    onChange={this.handleChange("player5name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    type="number"
                    value={this.state.player5kills}
                    onChange={this.handleChange("player5kills")}
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    type="number"
                    value={this.state.player5deaths}
                    onChange={this.handleChange("player5deaths")}
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player5mvp}
                    onChange={this.handleChange("player5mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player6name}
                    onChange={this.handleChange("player6name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player6kills}
                    onChange={this.handleChange("player6kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    type="number"
                    value={this.state.player6deaths}
                    onChange={this.handleChange("player6deaths")}
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player6mvp}
                    onChange={this.handleChange("player6mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player7name}
                    onChange={this.handleChange("player7name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    type="number"
                    value={this.state.player7kills}
                    onChange={this.handleChange("player7kills")}
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    type="number"
                    value={this.state.player7deaths}
                    onChange={this.handleChange("player7deaths")}
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player7mvp}
                    onChange={this.handleChange("player7mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player8name}
                    onChange={this.handleChange("player8name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player8kills}
                    onChange={this.handleChange("player8kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player8deaths}
                    onChange={this.handleChange("player8deaths")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player8mvp}
                    onChange={this.handleChange("player8mvp")}
                    type="number"
                  />
                </ListGroupItem>{" "}
                <ListGroupItem>
                  <Input
                    style={{
                      display: "flex",
                      width: "40%",
                      float: "left"
                    }}
                    value={this.state.player9name}
                    onChange={this.handleChange("player9name")}
                    type="text"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player9kills}
                    onChange={this.handleChange("player9kills")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player9deaths}
                    onChange={this.handleChange("player9deaths")}
                    type="number"
                  />
                  <Input
                    style={{
                      display: "flex",
                      width: "20%",
                      float: "left"
                    }}
                    value={this.state.player9mvp}
                    onChange={this.handleChange("player9mvp")}
                    type="number"
                  />
                </ListGroupItem>
              </ListGroup>
              <ListGroup
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  width: "50%",
                  float: "left"
                }}
              >
                <InputLabel>Map</InputLabel>
                <Select
                  value={this.state.map}
                  onChange={this.handleChange("map")}
                >
                  <MenuItem value={"de_ancient"}>de_ancient</MenuItem>
                  <MenuItem value={"de_dust2"}>de_dust2</MenuItem>
                  <MenuItem value={"de_nuke"}>de_nuke</MenuItem>
                  <MenuItem value={"de_inferno"}>de_inferno</MenuItem>
                  <MenuItem value={"de_mirage"}>de_mirage</MenuItem>
                  <MenuItem value={"de_train"}>de_train</MenuItem>
                  <MenuItem value={"de_vertigo"}>de_vertigo</MenuItem>
                  <MenuItem value={"de_overpass"}>de_overpass</MenuItem>
                </Select>
              </ListGroup>
              <ListGroup
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  width: "50%",
                  float: "left"
                }}
              >
                <InputLabel>Picked By</InputLabel>
                <Select
                  value={this.state.pickedBy}
                  onChange={this.handleChange("pickedBy")}
                >
                  <MenuItem value={this.state.team1}>
                    {this.state.team1}
                  </MenuItem>
                  <MenuItem value={this.state.team2}>
                    {this.state.team2}
                  </MenuItem>
                  <MenuItem value="server">Server</MenuItem>
                </Select>
              </ListGroup>

              <Button
                style={{ marginTop: "1rem" }}
                color="success"
                onClick={() => this.postToAPI()}
              >
                POST
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(AdminResults);
