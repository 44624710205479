import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import EventIcon from "@material-ui/icons/Event";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

class Gameweek extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <span>
        <Paper>
          <Grid container spacing={2}>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              style={{ cursor: "default" }}
            >
              <Typography variant="overline" gutterBottom>
                <FormattedMessage id="gameweek" />
              </Typography>
              <Typography variant="h6" gutterBottom>
                {this.props.gameweek}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Avatar
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#212121",
                  left: "50%",
                  top: "50%",
                  WebkitTransform: "translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <EventIcon />
              </Avatar>
            </Grid>
          </Grid>
        </Paper>
      </span>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Gameweek);
