import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { addTeams } from "../../actions/teamActions";
import { addLeagues } from "../../actions/leagueActions";
import { addSeries } from "../../actions/seriesActions";
import { addPlayers } from "../../actions/playerActions";
import { addLeaderboard } from "../../actions/leaderboardActions";
import { addStandings } from "../../actions/standingsActions";
import { addSettings } from "../../actions/settingsActions";
import { userLogout } from "../../actions/miscActions";
import { bindActionCreators } from "redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Images from "../../resources/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import CopyrightIcon from "@material-ui/icons/Copyright";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TableContainer from "@material-ui/core/TableContainer";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Tooltip from "@material-ui/core/Tooltip";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Grow from "@material-ui/core/Grow";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import Hidden from "@material-ui/core/Hidden";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import LaNav from "./LaNav";
import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import LaForm from "./LaForm";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

class LaTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replacePlayers: [],
      addPlayers: [],
      lineup: [],
      players: [],
      teams: [],
      currentSort: "price",
      currentOrder: true, //true is desc,
      dialogOpen: false,
      playerFocus: "",
      successOpen: false,
      errorOpen: false,
      errorText: "Villa",
      bank: 16000,
      transfers: 5,
      season: 0,
      captainOpen: false,
      captain: null,
      registerOpen: false,
      username: "",
      email: "",
      password: "",
      snackOpen: true,
      loaded: false,
      welcome: true
    };
    this.handleDialog = this.handleDialog.bind(this);
    this.handleCaptainDialog = this.handleCaptainDialog.bind(this);
    this.handleRegisterDialog = this.handleRegisterDialog.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.loginConfirmed = this.loginConfirmed.bind(this);
  }

  handleDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen
    });
  }

  handleCaptainDialog() {
    this.setState({
      captainOpen: !this.state.captainOpen
    });
  }

  handleRegisterDialog() {
    this.setState({
      registerOpen: !this.state.registerOpen
    });
  }

  handleSuccess() {
    this.setState({
      successOpen: false
    });
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  //handle input changes
  handleChange = name => event => {
    if (name === "username") {
      if (
        /^([a-zA-Z0-9-_]+)$/.test(event.target.value) ||
        event.target.value === ""
      ) {
        this.setState({
          [name]: event.target.value
        });
      }
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };

  loginConfirmed() {
    this.setState({ loggedIn: true });
  }

  componentDidMount() {
    document.body.style.background = "black";

    axios
      .get(API_URL + "/getsettings", null)
      .then(response => {
        this.setState({ season: response.data.season });
        axios
          .get(API_URL + "/activeteams/", null)
          .then(response => {
            this.setState({
              teams: response.data
            });

            axios
              .get(API_URL + "/activeplayers/", null)
              .then(response => {
                this.setState({
                  players: response.data.sort(
                    (a, b) => (a.price < b.price ? 1 : -1)
                  ),
                  loaded: true
                });
              })
              .catch(error => {
                this.setState({
                  errorOpen: true,
                  errorText: "Fetching data failed.",
                  loaded: true
                });
              });
          })
          .catch(error => {
            this.setState({
              errorOpen: true,
              errorText: "Fetching data failed.",
              loaded: true
            });
          });
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: "Fetching data failed.",
          loaded: true
        });
      });

    this.setState({
      replacePlayers: ["", "", "", "", ""]
    });
  }

  transferOut(event, item) {
    event.stopPropagation();
    /*
        if (this.state.playerFocus !== "" && !item) {
          if (this.state.replacePlayers.includes(this.state.playerFocus)) {
            this.setState({
              replacePlayers: this.state.replacePlayers.filter(
                x => x !== this.state.playerFocus
              ),
              addPlayers: this.state.addPlayers.slice(0, -1)
            });
            if (this.state.replacePlayers.length === 1) {
              this.setState({
                addPlayers: []
              });
            }
          } else {
            this.setState({
              replacePlayers: [...this.state.replacePlayers, this.state.playerFocus]
            });
          }
    
          if (this.state.replacePlayers.length < this.state.addPlayers.length) {
            this.setState({
              addPlayers: this.state.addPlayers.slice(0, -1)
            });
          }
        }*/

    if (item) {
      if (this.state.replacePlayers.includes(item)) {
        if (this.state.addPlayers.length === this.state.replacePlayers.length) {
          this.setState({
            bank:
              this.state.bank -
              this.state.players.find(x => x.name === item).price +
              this.state.players.find(
                x =>
                  x.name ===
                  this.state.addPlayers[this.state.replacePlayers.indexOf(item)]
              ).price
          });
        } else {
          this.setState({
            bank:
              this.state.bank -
              this.state.players.find(x => x.name === item).price
          });
        }

        //user is transferring out player already in his team
        if (this.state.replacePlayers.length === this.state.addPlayers.length) {
          this.setState({
            transfers: this.state.transfers + 1,
            addPlayers: this.state.addPlayers
              .slice(0, this.state.replacePlayers.indexOf(item))
              .concat(
                this.state.addPlayers.slice(
                  this.state.replacePlayers.indexOf(item) + 1,
                  this.state.addPlayers.length
                )
              )
          });
        }
        this.setState({
          replacePlayers: this.state.replacePlayers.filter(x => x !== item)
        });
      } else {
        this.setState({
          bank:
            this.state.bank +
            this.state.players.find(x => x.name === item).price,
          replacePlayers: [...this.state.replacePlayers, item]
        });
      }
    }
  }

  sortBy(sort) {
    if (sort === "team") {
      if (this.state.currentSort === "team") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "team",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              this.state.teams
                .find(x => x.players.includes(a.name))
                .name.toLowerCase() >
              this.state.teams
                .find(x => x.players.includes(b.name))
                .name.toLowerCase()
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "name") {
      if (this.state.currentSort === "name") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "name",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
          )
        });
      }
    }

    if (sort === "kills") {
      if (this.state.currentSort === "kills") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "kills",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totalkills <
              b.stats.find(y => y.season === this.state.season)?.totalkills
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "deaths") {
      if (this.state.currentSort === "deaths") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "deaths",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totaldeaths <
              b.stats.find(y => y.season === this.state.season)?.totaldeaths
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "mvp") {
      if (this.state.currentSort === "mvp") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "mvp",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totalmvp <
              b.stats.find(y => y.season === this.state.season)?.totalmvp
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "points") {
      if (this.state.currentSort === "points") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "points",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totalpoints <
              b.stats.find(y => y.season === this.state.season)?.totalpoints
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "price") {
      if (this.state.currentSort === "price") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "price",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) => (a.price < b.price ? 1 : -1)
          )
        });
      }
    }
  }

  transferIn(event, item) {
    event.stopPropagation();

    if (this.state.addPlayers.includes(item)) {
      this.setState({
        addPlayers: this.state.addPlayers.filter(x => x !== item),
        bank:
          this.state.bank + this.state.players.find(x => x.name === item).price,
        transfers: this.state.transfers + 1
      });
    } else {
      if (this.state.addPlayers.length !== 5) {
        if (
          this.state.lineup.length > 0 &&
          this.state.addPlayers.length === this.state.replacePlayers.length
        ) {
          this.setState({
            errorOpen: true,
            errorText: "Þú þarft að fjarlægja leikmenn úr liðinu þínu fyrst!"
          });
        } else {
          if (this.state.replacePlayers.includes(item)) {
            this.setState({
              bank:
                this.state.bank +
                this.state.players.find(x => x.name === item).price,
              replacePlayers: this.state.replacePlayers.filter(x => x !== item)
            });
          } else {
            this.setState({
              transfers: this.state.transfers - 1,
              bank:
                this.state.bank -
                this.state.players.find(x => x.name === item).price,
              addPlayers: [...this.state.addPlayers, item]
            });
          }
        }
      }
    }
  }

  replace(item) {
    if (this.state.replacePlayers.includes(item)) {
      return true;
    } else {
      return false;
    }
  }

  returnOrder() {
    if (this.state.currentOrder) {
      return "desc";
    } else {
      return "asc";
    }
  }

  focusPlayer(item) {
    this.setState({
      playerFocus: item
    });
  }

  confirm() {
    if (this.state.replacePlayers.length > this.state.addPlayers.length) {
      this.setState({
        errorOpen: true,
        errorText: <FormattedMessage id="add.more.players" />
      });
    } else {
      var array = [];
      var allowed = true;

      var lineup = this.state.lineup,
        currentLineup = this.state.lineup;
      lineup = lineup.filter(el => !this.state.replacePlayers.includes(el));
      lineup = lineup.concat(this.state.addPlayers);

      lineup.forEach(x =>
        array.push(this.state.teams.find(y => y.players.includes(x)).name)
      );

      /* ONLY ONE PLAYER PER TEAM
              if (new Set(array).size !== array.length) {
                allowed = false;
              }
              */

      /* ONLY TWO PLAYER PER TEAM ALLOWED */
      var obj = {};
      array.forEach(function(x) {
        obj[x] = (obj[x] || 0) + 1;
      });

      var mostPickedTeam = Object.keys(obj).reduce(
        (a, b) => (obj[a] > obj[b] ? a : b)
      );

      if (obj[mostPickedTeam] > 2) {
        allowed = false;
      }

      if (!allowed) {
        this.setState({
          errorOpen: true,
          errorText: <FormattedMessage id="too.many.players" />
        });
      } else if (this.state.bank < 0) {
        this.setState({
          errorOpen: true,
          errorText: <FormattedMessage id="money.short" />
        });
      } else {
        this.setState({
          captainOpen: true,
          snackOpen: false
        });
      }
    }
  }

  confirmCaptain(player) {
    this.setState({ captain: player, captainOpen: false, registerOpen: true });
  }

  removeFromAddPlayers(player) {
    this.setState({
      transfers: this.state.transfers + 1,
      addPlayers: this.state.addPlayers.filter(x => x !== player),
      bank:
        this.state.bank + this.state.players.find(x => x.name === player).price
    });
  }

  reset() {
    this.setState({
      transfers: 5,
      addPlayers: [],
      bank: 16000
    });

    if (this.state.lineup.length !== 0) {
      this.setState({
        replacePlayers: []
      });
    }
  }

  render() {
    if (this.state.loggedIn) {
      return <Redirect to={`/home`} />;
    } else {
      return (
        <div style={{ backgroundColor: "black" }}>
          <LaNav
            mobileHidden={false}
            textHidden={true}
            pathname={this.props.location.pathname}
          />
          <Grid container spacing={0} style={{ marginTop: "7rem" }}>
            <Dialog
              open={this.state.welcome}
              onClick={() => this.setState({ welcome: false })}
              disableEscapeKeyDown={false}
              aria-labelledby="max-width-dialog-title"
            >
              <span
                style={{
                  color: "rgb(166, 213, 250)",
                  backgroundColor: "#121212"
                }}
              >
                <DialogTitle
                  id="max-width-dialog-title"
                  style={{ color: "#11f211" }}
                >
                  Velkomin/n í Draumadeildina!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    style={{
                      color: "white"
                    }}
                  >
                    <FormattedHTMLMessage id="welcome.msg1" />
                  </DialogContentText>
                  <DialogContentText
                    style={{
                      color: "white"
                    }}
                  >
                    <FormattedHTMLMessage id="welcome.msg2" />
                  </DialogContentText>
                  <DialogContentText
                    style={{
                      color: "white"
                    }}
                  >
                    <FormattedHTMLMessage id="welcome.msg3" />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({ welcome: false })}>
                    <FormattedHTMLMessage id="close" />
                  </Button>
                </DialogActions>
              </span>
            </Dialog>

            <Snackbar
              style={{ marginBottom: "4rem" }}
              open={this.state.successOpen}
              autoHideDuration={4000}
              onClose={this.handleSuccess}
            >
              <Alert severity="success">
                <FormattedMessage id="success.transfers" />
              </Alert>
            </Snackbar>

            <Hidden xsDown>
              <Snackbar
                open={this.state.errorOpen}
                autoHideDuration={4000}
                onClose={this.handleError}
              >
                <Alert severity="error">
                  {" "}
                  <FormattedMessage id="error" />
                  {this.state.errorText}
                </Alert>
              </Snackbar>
            </Hidden>

            <Hidden smUp>
              <Snackbar
                style={{ marginBottom: "15rem" }}
                open={this.state.errorOpen}
                autoHideDuration={4000}
                onClose={this.handleError}
              >
                <Alert severity="error">
                  {" "}
                  <FormattedMessage id="error" />
                  {this.state.errorText}
                </Alert>
              </Snackbar>
            </Hidden>

            <Dialog
              disableEscapeKeyDown={true}
              disableBackdropClick={true}
              onClose={this.handleCaptainDialog}
              open={this.state.captainOpen}
              fullWidth={true}
              maxWidth={"lg"}
            >
              <DialogTitle
                style={{
                  fontWeight: "bold",
                  textAlign: "center"
                }}
                id="simple-dialog-title"
              >
                <FormattedMessage id="pick.captain" />
              </DialogTitle>
              <Grid
                container
                item
                xs={12}
                spacing={0}
                style={{ justifyContent: "center", marginBottom: "1rem" }}
              >
                {this.state.addPlayers.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    xs={6}
                    sm={6}
                    md={2}
                    style={{
                      textAlign: "center"
                    }}
                  >
                    <ButtonBase
                      style={{
                        display: "initial",
                        cursor: "pointer",
                        width: "90%"
                      }}
                      onClick={() => {
                        this.confirmCaptain(item);
                      }}
                      className="smoke-hoverb"
                    >
                      <img
                        style={{
                          height: "120px",
                          marginTop: "0.5rem"
                        }}
                        alt={item}
                        src={Images.players[item.toLowerCase()]}
                      />
                      <Typography
                        style={{
                          paddingBottom: "1em",
                          marginTop: "0.5rem"
                        }}
                        component="p"
                        variant="body2"
                      >
                        {item}
                      </Typography>
                      <Typography
                        style={{
                          paddingBottom: "1em"
                        }}
                        component="p"
                        variant="subtitle2"
                      >
                        {" "}
                        $
                        {this.state.players
                          .find(x => x._id === item.toLowerCase())
                          .price.toLocaleString()}
                      </Typography>
                    </ButtonBase>
                  </Grid>
                ))}{" "}
              </Grid>
            </Dialog>

            <Dialog
              disableEscapeKeyDown={true}
              disableBackdropClick={true}
              onClose={this.handleRegisterDialog}
              open={this.state.registerOpen}
              fullWidth={true}
              maxWidth={"xs"}
            >
              <span
                style={{
                  backgroundColor: "#121212"
                }}
              >
                <LaForm
                  sendLogin={this.loginConfirmed}
                  lineup={this.state.addPlayers}
                  captain={this.state.captain}
                />
              </span>
            </Dialog>

            <Dialog
              onClose={this.handleDialog}
              open={this.state.dialogOpen}
              fullWidth={true}
              maxWidth={"xs"}
            >
              <DialogTitle
                style={{
                  fontWeight: "bold",
                  textAlign: "center"
                }}
                id="simple-dialog-title"
              >
                <img
                  style={{
                    height: "100px",
                    float: "left"
                  }}
                  alt={this.state.playerFocus}
                  src={Images.players[this.state.playerFocus.toLowerCase()]}
                />
                {this.state.playerFocus}
                <Typography component="p" variant="subtitle2">
                  {" "}
                  $
                  {this.state.playerFocus !== ""
                    ? this.state.players
                        .find(
                          x => x._id === this.state.playerFocus.toLowerCase()
                        )
                        .price.toLocaleString()
                    : null}
                </Typography>
              </DialogTitle>

              <List>
                {this.state.replacePlayers.includes(this.state.playerFocus) &&
                this.state.lineup.includes(this.state.playerFocus) ? (
                  <ListItem
                    button
                    onClick={event => {
                      this.handleDialog();

                      this.transferOut(event, this.state.playerFocus);
                    }}
                    alignItems={"center"}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#212121"
                        }}
                      >
                        <CloseIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="restore.player" />}
                    />
                  </ListItem>
                ) : this.state.addPlayers.includes(this.state.playerFocus) ? (
                  <ListItem
                    button
                    onClick={event => {
                      this.handleDialog();
                      this.removeFromAddPlayers(this.state.playerFocus);
                    }}
                    alignItems={"center"}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#212121"
                        }}
                      >
                        <CloseIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="remove.player" />}
                    />
                  </ListItem>
                ) : !this.state.replacePlayers.includes(
                  this.state.playerFocus
                ) && this.state.lineup.includes(this.state.playerFocus) ? (
                  <ListItem
                    button
                    onClick={event => {
                      this.handleDialog();
                      this.transferOut(event, this.state.playerFocus);
                    }}
                    alignItems={"center"}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#212121"
                        }}
                      >
                        <CloseIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="remove.player" />}
                    />
                  </ListItem>
                ) : (
                  <ListItem
                    button
                    onClick={event => {
                      this.handleDialog();
                      this.transferIn(event, this.state.playerFocus);
                    }}
                    alignItems={"center"}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#212121"
                        }}
                      >
                        <AddIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="add.player" />}
                    />
                  </ListItem>
                )}
              </List>
            </Dialog>
            <Grid
              container
              item
              xs={12}
              spacing={1}
              style={{ justifyContent: "center" }}
            >
              {0 === 0
                ? this.state.addPlayers.length !== 0
                  ? this.state.addPlayers.map((item, index) => (
                      <Grid key={index} item xs={6} sm={6} md={2}>
                        <Paper
                          style={{ cursor: "default", position: "relative" }}
                        >
                          <Fab
                            color="secondary"
                            aria-label="remove"
                            size="small"
                            style={{
                              position: "absolute",
                              left: 0,
                              marginLeft: "0.5rem",
                              marginTop: "0.5rem",
                              maxWidth: "30px",
                              maxHeight: "30px",
                              minWidth: "30px",
                              minHeight: "30px"
                            }}
                            onClick={() => this.removeFromAddPlayers(item)}
                          >
                            <CloseIcon />
                          </Fab>
                          <img
                            style={{
                              height: "120px",
                              marginTop: "0.5rem"
                            }}
                            alt={item}
                            src={Images.players[item.toLowerCase()]}
                          />
                          <Typography
                            style={{
                              paddingBottom: "1em",
                              marginTop: "0.5rem"
                            }}
                            component="p"
                            variant="body2"
                          >
                            {item}
                          </Typography>
                          <Typography
                            style={{
                              paddingBottom: "1em"
                            }}
                            component="p"
                            variant="subtitle2"
                          >
                            {" "}
                            $
                            {this.state.players
                              .find(x => x._id === item.toLowerCase())
                              .price.toLocaleString()}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))
                  : null
                : this.props.user.lineup.map((item, index) => (
                    <Grid key={index} item xs={6} sm={6} md={2}>
                      {this.props.user.captain === item ? (
                        <Paper
                          style={{ cursor: "pointer", position: "relative" }}
                          className={
                            this.replace(item) ? "grey-out" : "smoke-hover"
                          }
                          onClick={() => {
                            this.focusPlayer(item);
                            this.handleDialog();
                          }}
                        >
                          <Fab
                            color="secondary"
                            aria-label="remove"
                            size="small"
                            style={{
                              position: "absolute",
                              left: 0,
                              marginLeft: "0.5rem",
                              marginTop: "0.5rem",
                              maxWidth: "30px",
                              maxHeight: "30px",
                              minWidth: "30px",
                              minHeight: "30px"
                            }}
                            onClick={event => {
                              this.transferOut(event, item);
                            }}
                          >
                            <CloseIcon />
                          </Fab>
                          <Tooltip
                            disableTouchListener={true}
                            title={<FormattedMessage id="captain.tooltip" />}
                          >
                            <CopyrightIcon
                              style={{
                                color: "#eda321",
                                position: "absolute",
                                marginRight: "0.5rem",
                                marginTop: "0.5rem",
                                right: 0,
                                fontSize: "2rem"
                              }}
                            />
                          </Tooltip>
                          {/* 
                          <Avatar
                            alt="c"
                            style={{
                              backgroundColor: "#eda321",
                              position: "absolute",
                              marginRight: "0.5rem",
                              marginTop: "0.5rem",
                              right: 0
                            }}
                          >
                            c
                          </Avatar>
      
                          */}

                          <img
                            style={{
                              height: "120px",
                              marginTop: "0.5rem"
                            }}
                            alt={item}
                            src={Images.players[item.toLowerCase()]}
                          />

                          <Typography
                            style={{
                              marginTop: "0.5rem"
                            }}
                            component="p"
                            variant="body2"
                          >
                            {item}
                          </Typography>

                          <Typography
                            style={{
                              paddingBottom: "1em"
                            }}
                            component="p"
                            variant="subtitle2"
                          >
                            {" "}
                            $
                            {this.state.players
                              .find(x => x._id === item.toLowerCase())
                              .price.toLocaleString()}
                          </Typography>
                        </Paper>
                      ) : (
                        <Paper
                          style={{ cursor: "pointer", position: "relative" }}
                          className={
                            this.replace(item) ? "grey-out" : "smoke-hover"
                          }
                          onClick={() => {
                            this.focusPlayer(item);
                            this.handleDialog();
                          }}
                        >
                          <Fab
                            color="secondary"
                            aria-label="remove"
                            size="small"
                            style={{
                              position: "absolute",
                              left: 0,
                              marginLeft: "0.5rem",
                              marginTop: "0.5rem",
                              maxWidth: "30px",
                              maxHeight: "30px",
                              minWidth: "30px",
                              minHeight: "30px"
                            }}
                            onClick={event => {
                              this.transferOut(event, item);
                            }}
                          >
                            <CloseIcon />
                          </Fab>
                          <img
                            style={{
                              height: "120px",
                              marginTop: "0.5rem"
                            }}
                            alt={item}
                            src={Images.players[item.toLowerCase()]}
                          />
                          <Typography
                            style={{
                              marginTop: "0.5rem"
                            }}
                            component="p"
                            variant="body2"
                          >
                            {item}
                          </Typography>

                          <Typography
                            style={{
                              paddingBottom: "1em"
                            }}
                            component="p"
                            variant="subtitle2"
                          >
                            {" "}
                            $
                            {this.state.players
                              .find(x => x._id === item.toLowerCase())
                              .price.toLocaleString()}
                          </Typography>
                        </Paper>
                      )}
                    </Grid>
                  ))}
            </Grid>

            {this.state.addPlayers.length !== 0 ? (
              <Grid
                item
                xs={12}
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
              >
                <Divider />
              </Grid>
            ) : null}

            <Grid item xs={12} md={3}>
              <Paper
                style={{
                  marginBottom: "2rem",
                  marginLeft: "2%",
                  marginRight: "2%",
                  backgroundColor: "#303030"
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Typography variant="overline" gutterBottom>
                      <FormattedMessage id="bank" />
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {this.state.bank >= 0
                        ? "$" + this.state.bank.toLocaleString()
                        : "-$" + Math.abs(this.state.bank).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Avatar
                      className={this.state.bank >= 0 ? "green" : "red"}
                      style={{
                        color: "#FFFFFF",

                        left: "50%",
                        top: "50%",
                        WebkitTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%)"
                      }}
                    >
                      <AttachMoneyIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </Paper>

              {this.state.replacePlayers.length !== 0 ? (
                <Typography component="div">
                  {this.state.addPlayers.length !== 5 ? (
                    <span>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormattedMessage id="pick" />
                          <FormattedMessage
                            id={5 - this.state.addPlayers.length}
                          />

                          <FormattedMessage id="to.create" />
                        </Grid>{" "}
                      </Grid>
                    </span>
                  ) : null}
                </Typography>
              ) : null}
            </Grid>
            {this.state.loaded === false ? (
              <Grid item xs={12} md={9}>
                <div
                  style={{
                    paddingTop: "1rem"
                  }}
                >
                  <CircularProgress
                    style={{
                      color: "#11F211"
                    }}
                    color="secondary"
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Hidden xsDown>
                  <Grid item xs={12} md={9}>
                    <Paper>
                      <TableContainer style={{ maxHeight: 400 }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: "1%" }} />
                              <TableCell
                                key={"team"}
                                onClick={() => this.sortBy("team")}
                                style={{ width: "2%" }}
                              >
                                <TableSortLabel
                                  active={this.state.currentSort === "team"}
                                  direction={this.returnOrder()}
                                >
                                  <FormattedMessage id="team" />{" "}
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                onClick={() => this.sortBy("name")}
                                key={"name"}
                                style={{ cursor: "pointer", width: "5%" }}
                              >
                                <TableSortLabel
                                  active={this.state.currentSort === "name"}
                                  direction={this.returnOrder()}
                                >
                                  <FormattedMessage id="player" />{" "}
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                onClick={() => this.sortBy("price")}
                                key={"price"}
                                style={{ cursor: "pointer", width: "5%" }}
                              >
                                <TableSortLabel
                                  active={this.state.currentSort === "price"}
                                  direction={this.returnOrder()}
                                >
                                  <FormattedMessage id="price" />{" "}
                                </TableSortLabel>{" "}
                              </TableCell>
                              <TableCell
                                onClick={() => this.sortBy("points")}
                                key={"points"}
                                style={{ cursor: "pointer", width: "5%" }}
                              >
                                <TableSortLabel
                                  active={this.state.currentSort === "points"}
                                  direction={this.returnOrder()}
                                >
                                  <FormattedMessage id="points" />{" "}
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                onClick={() => this.sortBy("kills")}
                                key={"kills"}
                                style={{ cursor: "pointer", width: "5%" }}
                              >
                                <TableSortLabel
                                  active={this.state.currentSort === "kills"}
                                  direction={this.returnOrder()}
                                >
                                  <FormattedMessage id="kills" />{" "}
                                </TableSortLabel>{" "}
                              </TableCell>
                              <TableCell
                                onClick={() => this.sortBy("deaths")}
                                key={"deaths"}
                                style={{ cursor: "pointer", width: "5%" }}
                              >
                                <TableSortLabel
                                  active={this.state.currentSort === "deaths"}
                                  direction={this.returnOrder()}
                                >
                                  <FormattedMessage id="deaths" />{" "}
                                </TableSortLabel>{" "}
                              </TableCell>
                              <TableCell
                                onClick={() => this.sortBy("mvp")}
                                key={"mvp"}
                                style={{ cursor: "pointer", width: "5%" }}
                              >
                                <TableSortLabel
                                  active={this.state.currentSort === "mvp"}
                                  direction={this.returnOrder()}
                                >
                                  <FormattedMessage id="mvp" />{" "}
                                </TableSortLabel>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.players.map(item => {
                              //var picked = "transferPlayerEntry";
                              var teamlogo = "team-logo";
                              if (
                                this.state.replacePlayers.includes(item.name)
                              ) {
                                teamlogo = "grey-out";
                              }
                              if (this.state.addPlayers.includes(item.name)) {
                                teamlogo = "grey-out";
                              }
                              if (this.state.lineup.includes(item.name)) {
                                teamlogo = "grey-out";
                              }
                              return (
                                <TableRow
                                  hover
                                  key={item.name}
                                  onClick={() => {
                                    this.focusPlayer(item.name);
                                    this.handleDialog();
                                  }}
                                >
                                  {this.state.lineup.includes(item.name) ? (
                                    <TableCell
                                      onClick={event => {
                                        this.transferOut(event, item.name);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <IndeterminateCheckBoxIcon />
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      onClick={event => {
                                        this.transferIn(event, item.name);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <AddBoxIcon />
                                    </TableCell>
                                  )}

                                  <TableCell>
                                    <img
                                      style={{
                                        width: "2rem",
                                        height: "auto"
                                      }}
                                      alt="logo"
                                      src={
                                        Images.teams[
                                          (this.state.teams.find(x =>
                                            x.players.includes(item.name)
                                          )?._id)
                                        ]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={teamlogo}
                                    style={{
                                      fontWeight: "bold"
                                    }}
                                  >
                                    {item.name}
                                  </TableCell>
                                  <TableCell className={teamlogo}>
                                    ${item.price.toLocaleString()}
                                  </TableCell>
                                  <TableCell className={teamlogo}>
                                    {item.stats.find(
                                      y => y.season === this.state.season
                                    )?.totalpoints || 0}
                                  </TableCell>
                                  <TableCell className={teamlogo}>
                                    {item.stats.find(
                                      y => y.season === this.state.season
                                    )?.totalkills || 0}
                                  </TableCell>
                                  <TableCell className={teamlogo}>
                                    {item.stats.find(
                                      y => y.season === this.state.season
                                    )?.totaldeaths || 0}
                                  </TableCell>
                                  <TableCell className={teamlogo}>
                                    {item.stats.find(
                                      y => y.season === this.state.season
                                    )?.totalmvp || 0}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                </Hidden>

                <Hidden smUp>
                  <Paper style={{ maxHeight: 300, overflow: "auto" }}>
                    <List>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          position: "-webkit-sticky" /* Safari */,
                          position: "sticky",
                          backgroundColor: "#424242",
                          color: "#FFFFFF",
                          top: 0
                        }}
                      >
                        <Grid item xs={1} />

                        <Grid item xs={2} onClick={() => this.sortBy("team")}>
                          <FormattedMessage id="team" />{" "}
                        </Grid>
                        <Grid item xs={5} onClick={() => this.sortBy("name")}>
                          <FormattedMessage id="player" />{" "}
                        </Grid>
                        <Grid item xs={4} onClick={() => this.sortBy("price")}>
                          <FormattedMessage id="price" />{" "}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        {this.state.players.map(item => {
                          //var picked = "transferPlayerEntry";
                          var teamlogo = "team-logo";
                          if (this.state.replacePlayers.includes(item.name)) {
                            teamlogo = "grey-out";
                          }
                          if (this.state.addPlayers.includes(item.name)) {
                            teamlogo = "grey-out";
                          }
                          if (this.state.lineup.includes(item.name)) {
                            teamlogo = "grey-out";
                          }
                          return (
                            <React.Fragment key={item.name}>
                              {this.state.lineup.includes(item.name) ? (
                                <Grid
                                  item
                                  xs={1}
                                  onClick={event => {
                                    this.transferOut(event, item.name);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <IndeterminateCheckBoxIcon />
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={1}
                                  onClick={event => {
                                    this.transferIn(event, item.name);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <AddBoxIcon />
                                </Grid>
                              )}

                              <Grid
                                item
                                xs={2}
                                onClick={() => {
                                  this.focusPlayer(item.name);
                                  this.handleDialog();
                                }}
                              >
                                <img
                                  style={{
                                    width: "2rem",
                                    height: "auto"
                                  }}
                                  alt="logo"
                                  src={
                                    Images.teams[
                                      (this.state.teams.find(x =>
                                        x.players.includes(item.name)
                                      )?._id)
                                    ]
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                className={teamlogo}
                                style={{
                                  fontWeight: "bold"
                                }}
                                onClick={() => {
                                  this.focusPlayer(item.name);
                                  this.handleDialog();
                                }}
                              >
                                {item.name}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className={teamlogo}
                                onClick={() => {
                                  this.focusPlayer(item.name);
                                  this.handleDialog();
                                }}
                              >
                                ${item.price}
                              </Grid>
                            </React.Fragment>
                          );
                        })}
                      </Grid>
                    </List>
                  </Paper>
                </Hidden>
              </>
            )}
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              open={
                (this.state.snackOpen &&
                  this.state.addPlayers.length > 0 &&
                  this.state.lineup[3] != null) ||
                (this.state.addPlayers.length === 5 && this.state.snackOpen)
              }
              message={this.state.replacePlayers.map((item, index) => (
                <Typography style={{ fontStyle: "italic" }} key={index}>
                  <Typography component="span">{item} </Typography>
                  {/*this.state.addPlayers.length > index &&
                      this.props.user.lineup.length === 5 ? (
                        <Typography component="span">
                          <ArrowForwardIcon style={{ width: "18px" }} />{" "}
                          {this.state.addPlayers[index]}
                        </Typography>
                      ) : null*/}
                </Typography>
              ))}
              TransitionComponent={Grow}
              transitionDuration={1000}
              action={
                this.state.addPlayers.length ===
                  this.state.replacePlayers.length &&
                this.state.addPlayers.length !== 0 ? (
                  <>
                    <Button onClick={() => this.confirm()} color="secondary">
                      <FormattedMessage id="confirm" />
                    </Button>
                    <Fab
                      color="secondary"
                      aria-label="remove"
                      size="small"
                      style={{
                        position: "absolute",
                        left: "-15px",
                        top: "-15px",
                        marginLeft: "0.5rem",
                        marginTop: "0.5rem",
                        maxWidth: "30px",
                        maxHeight: "30px",
                        minWidth: "30px",
                        minHeight: "30px"
                      }}
                      onClick={() => this.reset()}
                    >
                      <CloseIcon />
                    </Fab>
                  </>
                ) : (
                  <>
                    <Fab
                      color="secondary"
                      aria-label="remove"
                      size="small"
                      style={{
                        position: "absolute",
                        left: "-15px",
                        top: "-15px",
                        marginLeft: "0.5rem",
                        marginTop: "0.5rem",
                        maxWidth: "30px",
                        maxHeight: "30px",
                        minWidth: "30px",
                        minHeight: "30px"
                      }}
                      onClick={() => this.reset()}
                    >
                      <CloseIcon />
                    </Fab>
                  </>
                )
              }
            />
          </Grid>
        </div>
      );
    }
  }
}
export default LaTeam;
