import React, { Component, Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
//import asyncComponent from "./components/AsyncComponent";
import localeData from "./resources/translations.json";
import { IntlProvider } from "react-intl";
import { Redirect } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Profile from "./components/Profile";
import PlayerProfile from "./components/PlayerProfile";
import Team from "./components/Team";
import Results from "./components/Results";
import LeagueProfile from "./components/LeagueProfile";
import Admin from "./components/Admin/Admin";
import AdminResults from "./components/Admin/AdminResults";
import SeriesProfile from "./components/SeriesProfile";
import MiniDrawer from "./components/Menu";
import Leaderboard from "./components/Leaderboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import Points from "./components/Points";
import NotFoundPage from "./components/NotFoundPage";
import AddPlayer from "./components/Admin/AdminAddPlayer";
import AddTeam from "./components/Admin/AdminAddTeam";
import EditTeam from "./components/Admin/AdminEditTeam";
import EditPlayer from "./components/Admin/AdminEditPlayer";
import AddSeries from "./components/Admin/AdminAddSeries";
import AddPlayerToTeam from "./components/Admin/AdminAddPlayerToTeam";
import AddBrandedLeague from "./components/Admin/AdminAddBrandedLeague";
import LanguageSwitch from "./components/LanguageSwitch";
import NavBar from "./components/NavBar";
import About from "./components/About";
import ScrollToTop from "./components/ScrollToTop";
import Settings from "./components/Settings";
import ResetPassword from "./components/ResetPassword";
import Stats from "./components/Stats";
import Casters from "./components/Casters";
import Landing from "./components/Landing/Landing";
import LandingSchedule from "./components/Landing/LandingSchedule";
import LaLogin from "./components/Landing/LaLogin";
import LaTeam from "./components/Landing/LaTeam";
import { CssBaseline, createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import LaNav from "./components/Landing/LaNav";
import LaSeriesProfile from "./components/Landing/LaSeriesProfile";

/*
const themeLight = createMuiTheme({
  palette: {
    background: {
      default: "#e4f0e2"
    }
  }
});

const themeDark = createMuiTheme({
  palette: {
    background: {
      default: "#222222"
    },
    text: {
      primary: "#ffffff"
    }
  }
});
*/

//get the browser language
const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: localeData.is,
      theme: "dark"
    };
  }
  //if language is set to icelandic, set messages to is
  //otherwise english
  componentDidMount() {
    var theme = localStorage.getItem("theme");
    if (!theme) {
      localStorage.setItem("theme", "dark");
    }
    if (theme === "light") {
      this.setState({
        theme: "light"
      });
    }
    /*  NOT USING LANGUAGE OPTION
    if (localStorage.getItem("language") === "en") {
      this.setState({
        messages: localeData.en
      });
    } else {
      this.setState({
        messages: localeData.is
      });
    }
    */
  }

  //when client clicks change theme button, change theme
  handleTheme = theme => {
    if (this.state.theme === "dark") {
      this.setState({
        theme: "light"
      });
      document.body.style.background = "white";

      localStorage.setItem("theme", "light");
    } else {
      this.setState({
        theme: "dark"
      });
      document.body.style.background = "#303030";

      localStorage.setItem("theme", "dark");
    }
  };

  //when client clicks change language flag, change language
  handleLanguage = langValue => {
    if (langValue === "en") {
      this.setState({
        messages: localeData.en
      });
    } else {
      this.setState({
        messages: localeData.is
      });
    }
  };

  render() {
    const theme = createMuiTheme({
      palette: {
        type: this.state.theme
        /*
        background: {
          default: "",
          paper: ""
        }*/
      }
    });

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider locale={"en"} messages={this.state.messages}>
          <Router>
            <ScrollToTop>
              <div className="app">
                <Switch>
                  <LaNav exact path="/" textHidden={false}>
                    <PublicRoute
                      exact
                      path="/"
                      component={Landing}
                      key="landing"
                    />
                  </LaNav>
                  <LaNav textHidden={false} exact path="(/schedule)">
                    <PublicRoute
                      exact
                      path="/schedule"
                      component={LandingSchedule}
                      key="landingschedule"
                    />
                  </LaNav>
                  <LaNav textHidden={false} exact path="/schedule/:seriesid">
                    <PublicRoute
                      exact
                      path="/schedule/:seriesId"
                      component={LaSeriesProfile}
                      key="schedule"
                    />
                  </LaNav>
                  <LaNav textHidden={true} exact path="/login">
                    <PublicRoute
                      exact
                      path="/login"
                      component={LaLogin}
                      key="loginpage"
                    />
                  </LaNav>
                  <PublicRoute
                    exact
                    path="/create"
                    component={LaTeam}
                    key="create"
                  />

                  {/*<NavBar exact path="(/|/help)">
                    <PublicRoute exact path="/" component={Login} key="login" />
                    {/* <PublicRoute
                      exact
                      path="/help"
                      component={About}
                      key="help"
                    />
                    </NavBar>*/}

                  <NavBar exact path="/resetpassword/:userid/:token">
                    <PublicRoute
                      exact
                      path="/resetpassword/:userId/:token"
                      component={ResetPassword}
                    />
                  </NavBar>
                  <NavBar exact path="(/casters)">
                    <Casters exact path="/casters" key="casters" />
                  </NavBar>

                  <MiniDrawer
                    onSelectTheme={this.handleTheme}
                    path="/(home|users|players|team|points|leagues|results|results/:resultsId|admin|admin/results|leaderboard|about|settings|stats|casters)/"
                  >
                    <ProtectedRoute exact path="/home" component={Home} />
                    <ProtectedRoute
                      exact
                      path="/about"
                      component={About}
                      key="about"
                    />
                    <ProtectedRoute
                      exact
                      path="/stats"
                      component={Stats}
                      key="stats"
                    />
                    <ProtectedRoute
                      exact
                      path="/users/:userId"
                      component={Profile}
                    />
                    <ProtectedRoute
                      exact
                      path="/players/:playerId"
                      component={PlayerProfile}
                    />
                    <ProtectedRoute exact path="/team" component={Team} />
                    <ProtectedRoute exact path="/points" component={Points} />
                    <ProtectedRoute
                      exact
                      path="/leagues/:leagueId"
                      component={LeagueProfile}
                    />
                    <ProtectedRoute exact path="/results" component={Results} />
                    <ProtectedRoute
                      exact
                      path="/results/:seriesId"
                      component={SeriesProfile}
                    />
                    <ProtectedRoute exact path="/admin" component={Admin} />
                    <ProtectedRoute
                      exact
                      path="/admin/results"
                      component={AdminResults}
                    />
                    <ProtectedRoute
                      exact
                      path="/admin/addplayer"
                      component={AddPlayer}
                    />{" "}
                    <ProtectedRoute
                      exact
                      path="/admin/addteam"
                      component={AddTeam}
                    />
                    <ProtectedRoute
                      exact
                      path="/admin/editteam"
                      component={EditTeam}
                    />
                    <ProtectedRoute
                      exact
                      path="/admin/editplayer"
                      component={EditPlayer}
                    />
                    <ProtectedRoute
                      exact
                      path="/admin/addplayertoteam"
                      component={AddPlayerToTeam}
                    />
                    <ProtectedRoute
                      exact
                      path="/admin/addseries"
                      component={AddSeries}
                    />
                    <ProtectedRoute
                      exact
                      path="/admin/addbrandedleague"
                      component={AddBrandedLeague}
                    />
                    <ProtectedRoute
                      exact
                      path="/leaderboard"
                      component={Leaderboard}
                    />
                    <ProtectedRoute
                      exact
                      path="/settings"
                      component={Settings}
                    />
                  </MiniDrawer>
                  <Redirect to="/" />

                  {/*<Route component={NotFoundPage} />*/}
                </Switch>
              </div>
            </ScrollToTop>
          </Router>
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

export default App;
