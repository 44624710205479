import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import { FormattedHTMLMessage } from "react-intl";
import Images from "../resources/index";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {" "}
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={{
                fontWeight: "lighter"
              }}
              className="front-title"
              variant="h4"
              component="h4"
            >
              <FormattedMessage id="scoring" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              className="front-title"
              variant="subtitle1"
              component="p"
            >
              <FormattedHTMLMessage id="scoring.text" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={{
                fontWeight: "lighter"
              }}
              className="front-title"
              variant="h4"
              component="h4"
            >
              <FormattedMessage id="rules" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              className="front-title"
              variant="subtitle1"
              component="p"
            >
              <FormattedHTMLMessage id="rules.text" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={{
                paddingBottom: "1rem",
                fontWeight: "lighter"
              }}
              className="front-title"
              variant="h4"
              component="h4"
            >
              <FormattedMessage id="about.dd" />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Typography
              className="front-title"
              variant="subtitle1"
              component="p"
            >
              <FormattedHTMLMessage id="about.text" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <img
              style={{
                width: "200px",
                height: "auto"
              }}
              alt="draumadeildin"
              src={Images["draumadeildin-badge"]}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default About;
