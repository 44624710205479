import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import RemoveIcon from "@material-ui/icons/Remove";
import { FormattedMessage } from "react-intl";

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      season: this.props.settings.season
    };
  }

  userProfile(user) {
    this.props.history.push("/users/" + user._id);
  }

  render() {
    return (
      <div
        style={{
          cursor: "default",
          align: "center"
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={{
                paddingBottom: "1rem",
                fontWeight: "lighter"
              }}
              className="front-title"
              variant="h4"
              component="h4"
            >
              <FormattedMessage id="leaderboard" />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper>
              <TableContainer style={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "1%", fontWeight: "bold" }}>
                        #
                      </TableCell>
                      <TableCell style={{ width: "13%", fontWeight: "bold" }} />
                      <TableCell style={{ width: "22%", fontWeight: "bold" }}>
                        <FormattedMessage id="user" />
                      </TableCell>
                      <TableCell style={{ width: "22%", fontWeight: "bold" }}>
                        <FormattedMessage id="total.points" />
                      </TableCell>

                      <TableCell style={{ width: "22%", fontWeight: "bold" }}>
                        <FormattedMessage id="gameweek.points" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.leaderboard.map(
                      (entry, i) =>
                        this.props.user.username === entry.username ? (
                          <TableRow
                            hover
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#d89191"
                            }}
                            onClick={() => this.userProfile(entry)}
                            key={i}
                          >
                            <TableCell
                              style={{ fontWeight: "bold", color: "white" }}
                            >
                              {
                                entry.history["season" + this.state.season]
                                  .rankhistory[
                                  entry.history["season" + this.state.season]
                                    .rankhistory.length - 1
                                ] || i + 1
                              }
                            </TableCell>
                            <TableCell>
                              {entry.history["season" + this.state.season]
                                .rankhistory.length <= 1 ? (
                                <RemoveIcon
                                  style={{
                                    color: "grey"
                                  }}
                                />
                              ) : entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 1
                              ] >
                              entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 2
                              ] ? (
                                <TrendingDownIcon
                                  style={{
                                    color: "red"
                                  }}
                                />
                              ) : entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 1
                              ] ===
                              entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 2
                              ] ? (
                                <RemoveIcon
                                  style={{
                                    color: "grey"
                                  }}
                                />
                              ) : (
                                <TrendingUpIcon
                                  style={{
                                    color: "green"
                                  }}
                                />
                              )}
                            </TableCell>

                            <TableCell
                              style={{ fontWeight: "bold", color: "white" }}
                            >
                              {entry.username}
                            </TableCell>

                            <TableCell
                              style={{ fontWeight: "bold", color: "white" }}
                            >
                              {
                                entry.history["season" + this.state.season]
                                  .totalpoints
                              }
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "white" }}
                            >
                              {entry.history["season" + this.state.season]
                                .points.length === 0
                                ? 0
                                : entry.history["season" + this.state.season]
                                    .points[
                                    entry.history["season" + this.state.season]
                                      .points.length - 1
                                  ]}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                            hover
                            style={{ cursor: "pointer" }}
                            onClick={() => this.userProfile(entry)}
                            key={i}
                          >
                            <TableCell style={{ fontWeight: "bold" }}>
                              {
                                entry.history["season" + this.state.season]
                                  .rankhistory[
                                  entry.history["season" + this.state.season]
                                    .rankhistory.length - 1
                                ] || i + 1
                              }
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {entry.history["season" + this.state.season]
                                .rankhistory.length <= 1 ? (
                                <RemoveIcon
                                  style={{
                                    color: "grey"
                                  }}
                                />
                              ) : entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 1
                              ] >
                              entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 2
                              ] ? (
                                <TrendingDownIcon
                                  style={{
                                    color: "red"
                                  }}
                                />
                              ) : entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 1
                              ] ===
                              entry.history["season" + this.state.season]
                                .rankhistory[
                                entry.history["season" + this.state.season]
                                  .rankhistory.length - 2
                              ] ? (
                                <RemoveIcon
                                  style={{
                                    color: "grey"
                                  }}
                                />
                              ) : (
                                <TrendingUpIcon
                                  style={{
                                    color: "green"
                                  }}
                                />
                              )}
                            </TableCell>

                            <TableCell style={{ fontWeight: "bold" }}>
                              {entry.username}
                            </TableCell>

                            <TableCell>
                              {
                                entry.history["season" + this.state.season]
                                  .totalpoints
                              }
                            </TableCell>
                            <TableCell>
                              {entry.history["season" + this.state.season]
                                .points.length === 0
                                ? 0
                                : entry.history["season" + this.state.season]
                                    .points[
                                    entry.history["season" + this.state.season]
                                      .points.length - 1
                                  ]}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Leaderboard);
