import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { bindActionCreators } from "redux";
import { addLeagues } from "../actions/leagueActions";
import { FormattedMessage } from "react-intl";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { FormattedDate } from "react-intl";
import Images from "../resources/index";
import TableContainer from "@material-ui/core/TableContainer";

class LeagueProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueId: this.props.match.params.leagueId,
      league: { members: [] },
      dialogInviteOpen: false,
      dialogJoinOpen: false,
      inviteText: true,
      passcode: "",
      errorOpen: false,
      errorText: "Error"
    };
    this.handleJoinDialog = this.handleJoinDialog.bind(this);
    this.handleInviteDialog = this.handleInviteDialog.bind(this);
    this.joinLeague = this.joinLeague.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  handleInviteDialog() {
    this.setState({
      dialogInviteOpen: !this.state.dialogInviteOpen
    });
  }

  handleJoinDialog() {
    this.setState({
      dialogJoinOpen: !this.state.dialogJoinOpen
    });
  }

  componentDidMount() {
    var league = this.props.leagues.find(x => x._id === this.state.leagueId);

    if (league) {
      this.setState({
        league
      });
    } else {
      let config = {
        headers: {
          Authorization: localStorage.getItem("JWT")
        }
      };

      axios
        .get(API_URL + "/leagues/" + this.state.leagueId, config)
        .then(response => {
          this.setState({
            league: response.data
          });
        })
        .catch(error => {
          this.props.history.push("/home");
        });
    }
  }

  userProfile(user) {
    this.props.history.push("/users/" + user);
  }

  copyToClipboard = e => {
    //var text = "To join my league, go to this url: " + window.location.href + " and press the JOIN button. The passcode is: " + this.state.league.passcode;
    this.setState(
      {
        inviteText: false
      },
      this.otherFunction
    );
  };

  otherFunction() {
    var dummy = document.getElementById("test");

    dummy.select();
    document.execCommand("copy");
    this.setState({
      inviteText: true
    });
  }

  //handle input changes
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  joinLeague() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(
        API_URL + "/leagues/join/" + this.state.league._id,
        {
          passcode: this.state.passcode
        },
        config
      )
      .then(response => {
        this.props.addLeagues(response.data);
        const { history } = this.props;
        history.push("/leagues/" + response.data._id);
        this.setState({
          league: response.data,
          dialogJoinOpen: false
        });
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: "Joining league failed!"
        });
      });
  }

  render() {
    return (
      <div>
        <Snackbar
          open={this.state.errorOpen}
          autoHideDuration={4000}
          onClose={this.handleError}
        >
          <Alert severity="error">
            <FormattedMessage id="error" /> {this.state.errorText}
          </Alert>
        </Snackbar>
        {this.state.league.branded === true ? (
          <Typography
            variant="subtitle1"
            className="front-title"
            component="h3"
            style={{
              background: this.state.league.theme,
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem"
            }}
          >
            <img
              style={{
                width: "30px",
                height: "auto"
              }}
              alt="team"
              src={Images.teams[this.state.league.name.toLowerCase()]}
            />
          </Typography>
        ) : (
          <Typography
            variant="h4"
            className="front-title"
            style={{
              background: this.state.league.theme,
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem"
            }}
          >
            {this.state.league.name}
          </Typography>
        )}

        <Paper>
          <TableContainer style={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "12%", fontWeight: "bold" }}>
                    #
                  </TableCell>
                  <TableCell style={{ width: "22%", fontWeight: "bold" }}>
                    <FormattedMessage id="user" />{" "}
                  </TableCell>
                  <TableCell style={{ width: "22%", fontWeight: "bold" }}>
                    <FormattedMessage id="total.points" />
                  </TableCell>
                  <TableCell style={{ width: "22%", fontWeight: "bold" }}>
                    <FormattedMessage id="gameweek.points" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.settings.round === 1
                  ? this.state.league.members.map((entry, i) => (
                      <TableRow
                        style={{ cursor: "pointer" }}
                        onClick={() => this.userProfile(entry)}
                        hover
                        key={i}
                      >
                        <TableCell style={{ fontWeight: "bold" }}>
                          {i + 1}
                        </TableCell>
                        <TableCell>{entry}</TableCell>
                        <TableCell>0 </TableCell>
                        <TableCell>0 </TableCell>
                      </TableRow>
                    ))
                  : this.props.leaderboard
                      .filter(item => {
                        return this.state.league.members.includes(item._id);
                      })
                      .map((entry, i) => (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          onClick={() => this.userProfile(entry._id)}
                          hover
                          key={i}
                        >
                          <TableCell style={{ fontWeight: "bold" }}>
                            {i + 1}
                          </TableCell>
                          <TableCell>{entry.username}</TableCell>
                          <TableCell>
                            {
                              entry.history[
                                "season" + this.props.settings.season
                              ].totalpoints
                            }
                          </TableCell>
                          <TableCell>
                            {entry.history[
                              "season" + this.props.settings.season
                            ].points.length !== 0
                              ? entry.history[
                                  "season" + this.props.settings.season
                                ].points[
                                  entry.history[
                                    "season" + this.props.settings.season
                                  ].points.length - 1
                                ]
                              : 0}
                          </TableCell>
                        </TableRow>
                      ))}
              </TableBody>
            </Table>
          </TableContainer>
          {this.state.league.members.includes(this.props.user._id) ? (
            <Button
              color="primary"
              style={{ float: "right", color: "inherit" }}
              onClick={this.handleInviteDialog}
              size="small"
            >
              <FormattedMessage id="invite.to.league" />
            </Button>
          ) : (
            <Button
              color="primary"
              style={{ float: "right", color: "inherit" }}
              onClick={this.handleJoinDialog}
            >
              <FormattedMessage id="league.join" />
            </Button>
          )}
        </Paper>

        <Dialog
          onClose={this.handleInviteDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogInviteOpen}
        >
          <DialogTitle id="responsive-dialog-title">
            <FormattedMessage id="invite.user.to" /> '{this.state.league.name}'
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="invite.league.text" />
            </DialogContentText>
            <TextField
              readOnly
              fullWidth={true}
              value={window.location.href}
              label={<FormattedMessage id="league.page" />}
              margin="normal"
            />
            <TextField
              id="test"
              readOnly
              fullWidth={true}
              hidden={this.state.inviteText}
              value={window.location.href}
              label="League passcode"
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.copyToClipboard}
              color="primary"
            >
              <FormattedMessage id="invite.copy" />
            </Button>
            <Button
              variant="contained"
              onClick={this.handleInviteDialog}
              color="secondary"
              autoFocus
            >
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          onClose={this.handleJoinDialog}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogJoinOpen}
        >
          <DialogTitle id="responsive-dialog-title">
            <FormattedMessage id="league.join" /> '{this.state.league.name}'
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="provide.league.passcode" />
            </DialogContentText>
            <form onSubmit={e => this.register(e)}>
              <TextField
                fullWidth={true}
                required
                onChange={this.handleChange("passcode")}
                type="password"
                label={<FormattedMessage id="league.passcode" />}
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.joinLeague}
              color="primary"
            >
              <FormattedMessage id="league.join" />
            </Button>
            <Button
              variant="contained"
              onClick={this.handleJoinDialog}
              color="secondary"
              autoFocus
            >
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addLeagues: addLeagues
    },
    dispatch
  );
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeagueProfile);
