import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addUser } from "../actions/userActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Images from "../resources/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import CopyrightIcon from "@material-ui/icons/Copyright";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TableContainer from "@material-ui/core/TableContainer";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Tooltip from "@material-ui/core/Tooltip";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Grow from "@material-ui/core/Grow";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import Hidden from "@material-ui/core/Hidden";
import Countdown from "./Countdown";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replacePlayers: [],
      addPlayers: [],
      lineup: this.props.user.lineup,
      players: this.props.players,
      currentSort: "price",
      currentOrder: true, //true is desc,
      dialogOpen: false,
      playerFocus: "",
      successOpen: false,
      errorOpen: false,
      errorText: "Villa",
      bank: this.props.user.bank,
      transfers: this.props.user.transfers,
      season: this.props.settings.season,
      countdownDate: null
    };
    this.handleDialog = this.handleDialog.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen
    });
  }

  handleSuccess() {
    this.setState({
      successOpen: false
    });
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  componentDidMount() {
    if (localStorage.getItem("theme") === "light") {
      document.body.style.background = "white";
    } else {
      document.body.style.background = "#303030";
    }

    var nextGameweek = null;
    if (this.props.settings.round >= 21) {
      //season finished
    } else if (this.props.settings.status === "STARTED") {
      nextGameweek = this.props.series[this.props.settings.round * 4].date;
    } else {
      nextGameweek = this.props.series[(this.props.settings.round - 1) * 4]
        .date;
    }
    this.setState({
      players: this.state.players.sort((a, b) => (a.price < b.price ? 1 : -1)),
      countdownDate: nextGameweek
    });
    if (this.state.lineup.length === 0) {
      this.setState({
        replacePlayers: ["", "", "", "", ""]
      });
    }
  }

  transferOut(event, item) {
    event.stopPropagation();
    /*
    if (this.state.playerFocus !== "" && !item) {
      if (this.state.replacePlayers.includes(this.state.playerFocus)) {
        this.setState({
          replacePlayers: this.state.replacePlayers.filter(
            x => x !== this.state.playerFocus
          ),
          addPlayers: this.state.addPlayers.slice(0, -1)
        });
        if (this.state.replacePlayers.length === 1) {
          this.setState({
            addPlayers: []
          });
        }
      } else {
        this.setState({
          replacePlayers: [...this.state.replacePlayers, this.state.playerFocus]
        });
      }

      if (this.state.replacePlayers.length < this.state.addPlayers.length) {
        this.setState({
          addPlayers: this.state.addPlayers.slice(0, -1)
        });
      }
    }*/

    if (item) {
      if (this.state.replacePlayers.includes(item)) {
        if (this.state.addPlayers.length === this.state.replacePlayers.length) {
          this.setState({
            bank:
              this.state.bank -
              this.props.players.find(x => x.name === item).price +
              this.props.players.find(
                x =>
                  x.name ===
                  this.state.addPlayers[this.state.replacePlayers.indexOf(item)]
              ).price
          });
        } else {
          this.setState({
            bank:
              this.state.bank -
              this.props.players.find(x => x.name === item).price
          });
        }

        //user is transferring out player already in his team
        if (this.state.replacePlayers.length === this.state.addPlayers.length) {
          this.setState({
            transfers: this.state.transfers + 1,
            addPlayers: this.state.addPlayers
              .slice(0, this.state.replacePlayers.indexOf(item))
              .concat(
                this.state.addPlayers.slice(
                  this.state.replacePlayers.indexOf(item) + 1,
                  this.state.addPlayers.length
                )
              )
          });
        }
        this.setState({
          replacePlayers: this.state.replacePlayers.filter(x => x !== item)
        });
      } else {
        this.setState({
          bank:
            this.state.bank +
            this.props.players.find(x => x.name === item).price,
          replacePlayers: [...this.state.replacePlayers, item]
        });
      }
    }
  }

  sortBy(sort) {
    if (sort === "team") {
      if (this.state.currentSort === "team") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "team",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              this.props.teams
                .find(x => x.players.includes(a.name))
                .name.toLowerCase() >
              this.props.teams
                .find(x => x.players.includes(b.name))
                .name.toLowerCase()
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "name") {
      if (this.state.currentSort === "name") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "name",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
          )
        });
      }
    }

    if (sort === "kills") {
      if (this.state.currentSort === "kills") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "kills",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totalkills <
              b.stats.find(y => y.season === this.state.season)?.totalkills
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "deaths") {
      if (this.state.currentSort === "deaths") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "deaths",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totaldeaths <
              b.stats.find(y => y.season === this.state.season)?.totaldeaths
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "mvp") {
      if (this.state.currentSort === "mvp") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "mvp",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totalmvp <
              b.stats.find(y => y.season === this.state.season)?.totalmvp
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "points") {
      if (this.state.currentSort === "points") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "points",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) =>
              a.stats.find(y => y.season === this.state.season)?.totalpoints <
              b.stats.find(y => y.season === this.state.season)?.totalpoints
                ? 1
                : -1
          )
        });
      }
    }
    if (sort === "price") {
      if (this.state.currentSort === "price") {
        this.setState({
          players: this.state.players.reverse(),
          currentOrder: !this.state.currentOrder
        });
      } else {
        this.setState({
          currentSort: "price",
          currentOrder: true,
          players: this.state.players.sort(
            (a, b) => (a.price < b.price ? 1 : -1)
          )
        });
      }
    }
  }

  transferIn(event, item) {
    event.stopPropagation();

    if (this.state.addPlayers.includes(item)) {
      this.setState({
        addPlayers: this.state.addPlayers.filter(x => x !== item),
        bank:
          this.state.bank + this.props.players.find(x => x.name === item).price,
        transfers: this.state.transfers + 1
      });
    } else {
      if (this.state.addPlayers.length !== 5) {
        if (
          this.state.lineup.length > 0 &&
          this.state.addPlayers.length === this.state.replacePlayers.length
        ) {
          this.setState({
            errorOpen: true,
            errorText: "Þú þarft að fjarlægja leikmenn úr liðinu þínu fyrst!"
          });
        } else {
          if (this.state.replacePlayers.includes(item)) {
            this.setState({
              bank:
                this.state.bank +
                this.props.players.find(x => x.name === item).price,
              replacePlayers: this.state.replacePlayers.filter(x => x !== item)
            });
          } else {
            this.setState({
              transfers: this.state.transfers - 1,
              bank:
                this.state.bank -
                this.props.players.find(x => x.name === item).price,
              addPlayers: [...this.state.addPlayers, item]
            });
          }
        }
      }
    }
  }

  replace(item) {
    if (this.state.replacePlayers.includes(item)) {
      return true;
    } else {
      return false;
    }
  }

  returnOrder() {
    if (this.state.currentOrder) {
      return "desc";
    } else {
      return "asc";
    }
  }

  focusPlayer(item) {
    this.setState({
      playerFocus: item
    });
  }

  confirm() {
    if (this.state.replacePlayers.length > this.state.addPlayers.length) {
      this.setState({
        errorOpen: true,
        errorText: <FormattedMessage id="add.more.players" />
      });
    } else {
      var array = [];
      var allowed = true;

      var lineup = this.state.lineup,
        currentLineup = this.state.lineup;
      lineup = lineup.filter(el => !this.state.replacePlayers.includes(el));
      lineup = lineup.concat(this.state.addPlayers);

      lineup.forEach(x =>
        array.push(this.props.teams.find(y => y.players.includes(x)).name)
      );

      /* ONLY ONE PLAYER PER TEAM
          if (new Set(array).size !== array.length) {
            allowed = false;
          }
          */

      /* ONLY TWO PLAYER PER TEAM ALLOWED */
      var obj = {};
      array.forEach(function(x) {
        obj[x] = (obj[x] || 0) + 1;
      });

      var mostPickedTeam = Object.keys(obj).reduce(
        (a, b) => (obj[a] > obj[b] ? a : b)
      );

      if (obj[mostPickedTeam] > 2) {
        allowed = false;
      }

      if (!allowed) {
        this.setState({
          errorOpen: true,
          errorText: <FormattedMessage id="too.many.players" />
        });
      } else {
        if (
          (this.props.settings.round === 1 &&
            this.props.settings.status !== "STARTED") ||
          currentLineup.filter(x => !lineup.includes(x)).length <=
            this.props.user.transfers
        ) {
          let config = {
            headers: {
              Authorization: localStorage.getItem("JWT")
            }
          };

          axios
            .post(
              API_URL + "/users/" + this.props.user._id + "/lineup",
              { lineup: lineup },
              config
            )
            .then(response => {
              if (this.props.user.lineup.length === 0) {
                this.setState({ transfers: 0 });
              }
              this.setState({
                successOpen: true,
                lineup,
                replacePlayers: [],
                addPlayers: []
              });

              if (!lineup.includes(this.props.user.captain)) {
                axios
                  .post(
                    API_URL + "/users/" + this.props.user._id + "/captain",
                    { captain: lineup[0] },
                    config
                  )
                  .then(response => {
                    this.props.addUser(response.data);
                  })
                  .catch(error => {
                    this.setState({
                      errorOpen: true,
                      errorText: "Ekki gekk að staðfesta fyrirliða!"
                    });
                  });
              } else {
                this.props.addUser(response.data);
              }
            })
            .catch(error => {
              this.setState({
                errorOpen: true,
                errorText: error.response.data
              });
            });
        } else {
          this.setState({
            errorOpen: true,
            errorText: "Of margar liðsbreytingar valdar!"
          });
        }
      }
    }
  }

  captain() {
    var captain = this.state.playerFocus;

    //TODO: post to api
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .post(
        API_URL + "/users/" + this.props.user._id + "/captain",
        { captain: captain },
        config
      )
      .then(response => {
        this.props.addUser(response.data);
      })
      .catch(error => {
        this.setState({
          errorOpen: true,
          errorText: "Confirming captain failed!"
        });
      });
  }

  removeFromAddPlayers(player) {
    this.setState({
      transfers: this.state.transfers + 1,
      addPlayers: this.state.addPlayers.filter(x => x !== player),
      bank:
        this.state.bank + this.props.players.find(x => x.name === player).price
    });
  }

  playerProfile(player) {
    this.props.history.push("/players/" + player);
  }

  reset() {
    this.setState({
      transfers: this.props.user.transfers,
      addPlayers: [],
      bank: this.props.user.bank
    });

    if (this.state.lineup.length !== 0) {
      this.setState({
        replacePlayers: []
      });
    }
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Snackbar
          style={{ marginBottom: "4rem" }}
          open={this.state.successOpen}
          autoHideDuration={4000}
          onClose={this.handleSuccess}
        >
          <Alert severity="success">
            <FormattedMessage id="success.transfers" />
          </Alert>
        </Snackbar>

        <Hidden xsDown>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              {" "}
              <FormattedMessage id="error" />
              {this.state.errorText}
            </Alert>
          </Snackbar>
        </Hidden>

        <Hidden smUp>
          <Snackbar
            style={{ marginBottom: "15rem" }}
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              {" "}
              <FormattedMessage id="error" />
              {this.state.errorText}
            </Alert>
          </Snackbar>
        </Hidden>

        <Dialog
          onClose={this.handleDialog}
          open={this.state.dialogOpen}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle
            style={{
              fontWeight: "bold",
              textAlign: "center"
            }}
            id="simple-dialog-title"
          >
            <img
              style={{
                height: "100px",
                float: "left"
              }}
              alt={this.state.playerFocus}
              src={Images.players[this.state.playerFocus.toLowerCase()]}
            />
            {this.state.playerFocus}
            <Typography component="p" variant="subtitle2">
              {" "}
              $
              {this.state.playerFocus !== ""
                ? this.state.players
                    .find(x => x._id === this.state.playerFocus.toLowerCase())
                    .price.toLocaleString()
                : null}
            </Typography>
          </DialogTitle>

          <List>
            <ListItem
              button
              onClick={() => this.playerProfile(this.state.playerFocus)}
              alignItems="center"
            >
              <ListItemAvatar>
                <Avatar
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: "#212121"
                  }}
                >
                  <PersonOutlineIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<FormattedMessage id="player.profile" />}
              />
            </ListItem>

            {this.props.user.captain !== this.state.playerFocus &&
            this.state.lineup.includes(this.state.playerFocus) ? (
              <ListItem
                autoFocus
                button
                onClick={() => {
                  this.handleDialog();
                  this.captain();
                }}
                alignItems={"center"}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#212121"
                    }}
                  >
                    <CopyrightIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={<FormattedMessage id="captain.player" />}
                />
              </ListItem>
            ) : null}

            {this.state.replacePlayers.includes(this.state.playerFocus) &&
            this.state.lineup.includes(this.state.playerFocus) ? (
              <ListItem
                button
                onClick={event => {
                  this.handleDialog();

                  this.transferOut(event, this.state.playerFocus);
                }}
                alignItems={"center"}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#212121"
                    }}
                  >
                    <CloseIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<FormattedMessage id="restore.player" />}
                />
              </ListItem>
            ) : this.state.addPlayers.includes(this.state.playerFocus) ? (
              <ListItem
                button
                onClick={event => {
                  this.handleDialog();
                  this.removeFromAddPlayers(this.state.playerFocus);
                }}
                alignItems={"center"}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#212121"
                    }}
                  >
                    <CloseIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<FormattedMessage id="remove.player" />}
                />
              </ListItem>
            ) : !this.state.replacePlayers.includes(this.state.playerFocus) &&
            this.state.lineup.includes(this.state.playerFocus) ? (
              <ListItem
                button
                onClick={event => {
                  this.handleDialog();
                  this.transferOut(event, this.state.playerFocus);
                }}
                alignItems={"center"}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#212121"
                    }}
                  >
                    <CloseIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<FormattedMessage id="remove.player" />}
                />
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={event => {
                  this.handleDialog();
                  this.transferIn(event, this.state.playerFocus);
                }}
                alignItems={"center"}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#212121"
                    }}
                  >
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={<FormattedMessage id="add.player" />} />
              </ListItem>
            )}
          </List>
        </Dialog>
        <Grid item xs={12}>
          {this.props.settings.status === "STARTED" ? (
            <Typography
              style={{
                display: "flex",
                color: "rgb(255, 213, 153)",
                fontSize: "0.875rem",
                marginBottom: "1rem",
                padding: "6px 16px"
              }}
            >
              <ReportProblemOutlinedIcon
                style={{
                  color: "#ffb300",
                  marginRight: "12px",
                  opacity: "0.9"
                }}
              />
              Umferð í gangi - breytingar taka gildi í næstu umferð
            </Typography>
          ) : null}
          <Countdown date={this.state.countdownDate} />
        </Grid>
        <Grid container spacing={3} style={{ justifyContent: "center" }}>
          {this.props.user.lineup.length === 0
            ? this.state.addPlayers.length !== 0
              ? this.state.addPlayers.map((item, index) => (
                  <Grid key={index} item xs={6} sm={6} md={2}>
                    <Paper style={{ cursor: "default", position: "relative" }}>
                      <Fab
                        color="secondary"
                        aria-label="remove"
                        size="small"
                        style={{
                          position: "absolute",
                          left: 0,
                          marginLeft: "0.5rem",
                          marginTop: "0.5rem",
                          maxWidth: "30px",
                          maxHeight: "30px",
                          minWidth: "30px",
                          minHeight: "30px"
                        }}
                        onClick={() => this.removeFromAddPlayers(item)}
                      >
                        <CloseIcon />
                      </Fab>
                      <img
                        style={{
                          height: "120px",
                          marginTop: "0.5rem"
                        }}
                        alt={item}
                        src={Images.players[item.toLowerCase()]}
                      />
                      <Typography
                        style={{
                          paddingBottom: "1em",
                          marginTop: "0.5rem"
                        }}
                        component="p"
                        variant="body2"
                      >
                        {item}
                      </Typography>
                      <Typography
                        style={{
                          paddingBottom: "1em"
                        }}
                        component="p"
                        variant="subtitle2"
                      >
                        {" "}
                        $
                        {this.state.players
                          .find(x => x._id === item.toLowerCase())
                          .price.toLocaleString()}
                      </Typography>
                    </Paper>
                  </Grid>
                ))
              : null
            : this.props.user.lineup.map((item, index) => (
                <Grid key={index} item xs={6} sm={6} md={2}>
                  {this.props.user.captain === item ? (
                    <Paper
                      style={{ cursor: "pointer", position: "relative" }}
                      className={
                        this.replace(item) ? "grey-out" : "smoke-hover"
                      }
                      onClick={() => {
                        this.focusPlayer(item);
                        this.handleDialog();
                      }}
                    >
                      <Fab
                        color="secondary"
                        aria-label="remove"
                        size="small"
                        style={{
                          position: "absolute",
                          left: 0,
                          marginLeft: "0.5rem",
                          marginTop: "0.5rem",
                          maxWidth: "30px",
                          maxHeight: "30px",
                          minWidth: "30px",
                          minHeight: "30px"
                        }}
                        onClick={event => {
                          this.transferOut(event, item);
                        }}
                      >
                        <CloseIcon />
                      </Fab>
                      <Tooltip
                        disableTouchListener={true}
                        title={<FormattedMessage id="captain.tooltip" />}
                      >
                        <CopyrightIcon
                          style={{
                            color: "#eda321",
                            position: "absolute",
                            marginRight: "0.5rem",
                            marginTop: "0.5rem",
                            right: 0,
                            fontSize: "2rem"
                          }}
                        />
                      </Tooltip>
                      {/* 
                    <Avatar
                      alt="c"
                      style={{
                        backgroundColor: "#eda321",
                        position: "absolute",
                        marginRight: "0.5rem",
                        marginTop: "0.5rem",
                        right: 0
                      }}
                    >
                      c
                    </Avatar>

                    */}

                      <img
                        style={{
                          height: "120px",
                          marginTop: "0.5rem"
                        }}
                        alt={item}
                        src={Images.players[item.toLowerCase()]}
                      />

                      <Typography
                        style={{
                          marginTop: "0.5rem"
                        }}
                        component="p"
                        variant="body2"
                      >
                        {item}
                      </Typography>

                      <Typography
                        style={{
                          paddingBottom: "1em"
                        }}
                        component="p"
                        variant="subtitle2"
                      >
                        {" "}
                        $
                        {this.state.players
                          .find(x => x._id === item.toLowerCase())
                          .price.toLocaleString()}
                      </Typography>
                    </Paper>
                  ) : (
                    <Paper
                      style={{ cursor: "pointer", position: "relative" }}
                      className={
                        this.replace(item) ? "grey-out" : "smoke-hover"
                      }
                      onClick={() => {
                        this.focusPlayer(item);
                        this.handleDialog();
                      }}
                    >
                      <Fab
                        color="secondary"
                        aria-label="remove"
                        size="small"
                        style={{
                          position: "absolute",
                          left: 0,
                          marginLeft: "0.5rem",
                          marginTop: "0.5rem",
                          maxWidth: "30px",
                          maxHeight: "30px",
                          minWidth: "30px",
                          minHeight: "30px"
                        }}
                        onClick={event => {
                          this.transferOut(event, item);
                        }}
                      >
                        <CloseIcon />
                      </Fab>
                      <img
                        style={{
                          height: "120px",
                          marginTop: "0.5rem"
                        }}
                        alt={item}
                        src={Images.players[item.toLowerCase()]}
                      />
                      <Typography
                        style={{
                          marginTop: "0.5rem"
                        }}
                        component="p"
                        variant="body2"
                      >
                        {item}
                      </Typography>

                      <Typography
                        style={{
                          paddingBottom: "1em"
                        }}
                        component="p"
                        variant="subtitle2"
                      >
                        {" "}
                        $
                        {this.state.players
                          .find(x => x._id === item.toLowerCase())
                          .price.toLocaleString()}
                      </Typography>
                    </Paper>
                  )}
                </Grid>
              ))}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper style={{ marginBottom: "2rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="overline" gutterBottom>
                  <FormattedMessage id="bank" />
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {this.state.bank >= 0
                    ? "$" + this.state.bank.toLocaleString()
                    : "-$" + Math.abs(this.state.bank).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Avatar
                  className={this.state.bank >= 0 ? "green" : "red"}
                  style={{
                    color: "#FFFFFF",

                    left: "50%",
                    top: "50%",
                    WebkitTransform: "translate(-50%, -50%)",
                    transform: "translate(-50%, -50%)"
                  }}
                >
                  <AttachMoneyIcon />
                </Avatar>
              </Grid>
            </Grid>
          </Paper>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper style={{ marginBottom: "2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Typography variant="overline" gutterBottom>
                    <FormattedMessage id="transfers.available" />
                  </Typography>
                  {(this.props.settings.round === 1 &&
                    this.props.settings.status !== "STARTED") ||
                  this.props.user.lineup.length === 0 ? (
                    <Typography variant="h6" gutterBottom>
                      <FormattedMessage id="unlimited" />
                    </Typography>
                  ) : (
                    <Typography variant="h6" gutterBottom>
                      {this.state.transfers}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Avatar
                    className={
                      this.state.transfers >= 0 ||
                      this.props.user.lineup.length === 0
                        ? "green"
                        : "red"
                    }
                    style={{
                      color: "#FFFFFF",

                      backgroundColor: "#0bd1b7",
                      left: "50%",
                      top: "50%",
                      WebkitTransform: "translate(-50%, -50%)",
                      transform: "translate(-50%, -50%)"
                    }}
                  >
                    <SwapHorizIcon />
                  </Avatar>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {this.state.replacePlayers.length !== 0 ? (
            <Typography component="div">
              {this.state.addPlayers.length !== 5 &&
              this.props.user.lineup.length !== 5 ? (
                <span>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormattedMessage id="pick" />
                      <FormattedMessage id={5 - this.state.addPlayers.length} />
                      <FormattedMessage id="to.create" />
                    </Grid>{" "}
                  </Grid>
                </span>
              ) : null}
            </Typography>
          ) : null}
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} md={9}>
            <Paper>
              <TableContainer style={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "1%" }} />
                      <TableCell
                        key={"team"}
                        onClick={() => this.sortBy("team")}
                        style={{ width: "2%" }}
                      >
                        <TableSortLabel
                          active={this.state.currentSort === "team"}
                          direction={this.returnOrder()}
                        >
                          <FormattedMessage id="team" />{" "}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        onClick={() => this.sortBy("name")}
                        key={"name"}
                        style={{ cursor: "pointer", width: "5%" }}
                      >
                        <TableSortLabel
                          active={this.state.currentSort === "name"}
                          direction={this.returnOrder()}
                        >
                          <FormattedMessage id="player" />{" "}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        onClick={() => this.sortBy("price")}
                        key={"price"}
                        style={{ cursor: "pointer", width: "5%" }}
                      >
                        <TableSortLabel
                          active={this.state.currentSort === "price"}
                          direction={this.returnOrder()}
                        >
                          <FormattedMessage id="price" />{" "}
                        </TableSortLabel>{" "}
                      </TableCell>
                      <TableCell
                        onClick={() => this.sortBy("points")}
                        key={"points"}
                        style={{ cursor: "pointer", width: "5%" }}
                      >
                        <TableSortLabel
                          active={this.state.currentSort === "points"}
                          direction={this.returnOrder()}
                        >
                          <FormattedMessage id="points" />{" "}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        onClick={() => this.sortBy("kills")}
                        key={"kills"}
                        style={{ cursor: "pointer", width: "5%" }}
                      >
                        <TableSortLabel
                          active={this.state.currentSort === "kills"}
                          direction={this.returnOrder()}
                        >
                          <FormattedMessage id="kills" />{" "}
                        </TableSortLabel>{" "}
                      </TableCell>
                      <TableCell
                        onClick={() => this.sortBy("deaths")}
                        key={"deaths"}
                        style={{ cursor: "pointer", width: "5%" }}
                      >
                        <TableSortLabel
                          active={this.state.currentSort === "deaths"}
                          direction={this.returnOrder()}
                        >
                          <FormattedMessage id="deaths" />{" "}
                        </TableSortLabel>{" "}
                      </TableCell>
                      <TableCell
                        onClick={() => this.sortBy("mvp")}
                        key={"mvp"}
                        style={{ cursor: "pointer", width: "5%" }}
                      >
                        <TableSortLabel
                          active={this.state.currentSort === "mvp"}
                          direction={this.returnOrder()}
                        >
                          <FormattedMessage id="mvp" />{" "}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.players.map(item => {
                      //var picked = "transferPlayerEntry";
                      var teamlogo = "team-logo";
                      if (this.state.replacePlayers.includes(item.name)) {
                        teamlogo = "grey-out";
                      }
                      if (this.state.addPlayers.includes(item.name)) {
                        teamlogo = "grey-out";
                      }
                      if (this.state.lineup.includes(item.name)) {
                        teamlogo = "grey-out";
                      }
                      return (
                        <TableRow
                          hover
                          key={item.name}
                          onClick={() => {
                            this.focusPlayer(item.name);
                            this.handleDialog();
                          }}
                        >
                          {this.state.lineup.includes(item.name) ? (
                            <TableCell
                              onClick={event => {
                                this.transferOut(event, item.name);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <IndeterminateCheckBoxIcon />
                            </TableCell>
                          ) : (
                            <TableCell
                              onClick={event => {
                                this.transferIn(event, item.name);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <AddBoxIcon />
                            </TableCell>
                          )}

                          <TableCell>
                            <img
                              style={{
                                width: "2rem",
                                height: "2rem"
                              }}
                              alt="logo"
                              src={
                                Images.teams[
                                  (this.props.teams.find(x =>
                                    x.players.includes(item.name)
                                  )?._id)
                                ]
                              }
                            />
                          </TableCell>
                          <TableCell
                            className={teamlogo}
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {item.name}
                          </TableCell>
                          <TableCell className={teamlogo}>
                            ${item.price.toLocaleString()}
                          </TableCell>
                          <TableCell className={teamlogo}>
                            {item.stats.find(
                              y => y.season === this.state.season
                            )?.totalpoints || 0}
                          </TableCell>
                          <TableCell className={teamlogo}>
                            {item.stats.find(
                              y => y.season === this.state.season
                            )?.totalkills || 0}
                          </TableCell>
                          <TableCell className={teamlogo}>
                            {item.stats.find(
                              y => y.season === this.state.season
                            )?.totaldeaths || 0}
                          </TableCell>
                          <TableCell className={teamlogo}>
                            {item.stats.find(
                              y => y.season === this.state.season
                            )?.totalmvp || 0}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Paper style={{ maxHeight: 300, overflow: "auto" }}>
            <List>
              <Grid
                container
                spacing={1}
                style={{
                  position: "-webkit-sticky" /* Safari */,
                  position: "sticky",
                  backgroundColor: "#424242",
                  color: "#FFFFFF",
                  top: 0
                }}
              >
                <Grid item xs={1} />

                <Grid item xs={2} onClick={() => this.sortBy("team")}>
                  <FormattedMessage id="team" />{" "}
                </Grid>
                <Grid item xs={5} onClick={() => this.sortBy("name")}>
                  <FormattedMessage id="player" />{" "}
                </Grid>
                <Grid item xs={4} onClick={() => this.sortBy("price")}>
                  <FormattedMessage id="price" />{" "}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {this.state.players.map(item => {
                  //var picked = "transferPlayerEntry";
                  var teamlogo = "team-logo";
                  if (this.state.replacePlayers.includes(item.name)) {
                    teamlogo = "grey-out";
                  }
                  if (this.state.addPlayers.includes(item.name)) {
                    teamlogo = "grey-out";
                  }
                  if (this.state.lineup.includes(item.name)) {
                    teamlogo = "grey-out";
                  }
                  return (
                    <React.Fragment key={item.name}>
                      {this.state.lineup.includes(item.name) ? (
                        <Grid
                          item
                          xs={1}
                          onClick={event => {
                            this.transferOut(event, item.name);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <IndeterminateCheckBoxIcon />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={1}
                          onClick={event => {
                            this.transferIn(event, item.name);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <AddBoxIcon />
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={2}
                        onClick={() => {
                          this.focusPlayer(item.name);
                          this.handleDialog();
                        }}
                      >
                        <img
                          style={{
                            width: "2rem",
                            height: "2rem"
                          }}
                          alt="logo"
                          src={
                            Images.teams[
                              (this.props.teams.find(x =>
                                x.players.includes(item.name)
                              )?._id)
                            ]
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        className={teamlogo}
                        style={{
                          fontWeight: "bold"
                        }}
                        onClick={() => {
                          this.focusPlayer(item.name);
                          this.handleDialog();
                        }}
                      >
                        {item.name}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className={teamlogo}
                        onClick={() => {
                          this.focusPlayer(item.name);
                          this.handleDialog();
                        }}
                      >
                        ${item.price}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </List>
          </Paper>
        </Hidden>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={
            (this.state.addPlayers.length > 0 &&
              this.state.lineup[3] != null) ||
            this.state.addPlayers.length === 5
          }
          message={this.state.replacePlayers.map((item, index) => (
            <Typography style={{ fontStyle: "italic" }} key={index}>
              <Typography component="span">{item} </Typography>
              {this.state.addPlayers.length > index &&
              this.props.user.lineup.length === 5 ? (
                <Typography component="span">
                  <ArrowForwardIcon style={{ width: "18px" }} />{" "}
                  {this.state.addPlayers[index]}
                </Typography>
              ) : null}
            </Typography>
          ))}
          TransitionComponent={Grow}
          transitionDuration={1000}
          action={
            this.state.addPlayers.length === this.state.replacePlayers.length &&
            this.state.addPlayers.length !== 0 ? (
              <>
                <Button onClick={() => this.confirm()} color="secondary">
                  <FormattedMessage id="confirm" />
                </Button>
                <Fab
                  color="secondary"
                  aria-label="remove"
                  size="small"
                  style={{
                    position: "absolute",
                    left: "-15px",
                    top: "-15px",
                    marginLeft: "0.5rem",
                    marginTop: "0.5rem",
                    maxWidth: "30px",
                    maxHeight: "30px",
                    minWidth: "30px",
                    minHeight: "30px"
                  }}
                  onClick={() => this.reset()}
                >
                  <CloseIcon />
                </Fab>
              </>
            ) : (
              <>
                <Fab
                  color="secondary"
                  aria-label="remove"
                  size="small"
                  style={{
                    position: "absolute",
                    left: "-15px",
                    top: "-15px",
                    marginLeft: "0.5rem",
                    marginTop: "0.5rem",
                    maxWidth: "30px",
                    maxHeight: "30px",
                    minWidth: "30px",
                    minHeight: "30px"
                  }}
                  onClick={() => this.reset()}
                >
                  <CloseIcon />
                </Fab>
              </>
            )
          }
        />
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addUser: addUser }, dispatch);
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Team);
