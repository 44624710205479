import React, { Component } from "react";
import Images from "../../resources/index";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { addTeams } from "../../actions/teamActions";
import { addLeagues } from "../../actions/leagueActions";
import { addSeries } from "../../actions/seriesActions";
import { addPlayers } from "../../actions/playerActions";
import { addLeaderboard } from "../../actions/leaderboardActions";
import { addStandings } from "../../actions/standingsActions";
import { addSettings } from "../../actions/settingsActions";
import { userLogout } from "../../actions/miscActions";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

class LaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      lineup: this.props.lineup,
      captain: this.props.captain,
      loaded: true,
      form: "register",
      successOpen: false,
      errorOpen: false,
      errorText: "Error"
    };
    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleSuccess() {
    this.setState({
      successOpen: false
    });
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  //handle input changes
  handleChange = name => event => {
    if (name === "username") {
      if (
        /^([a-zA-Z0-9-_]+)$/.test(event.target.value) ||
        event.target.value === ""
      ) {
        this.setState({
          [name]: event.target.value
        });
      }
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };

  success = async data => {
    await this.props.addUser(data);
    await this.props.sendLogin();
  };

  register(e) {
    e.preventDefault();
    const { email, password, username } = this.state;

    if (this.state.email === "" || this.state.password === "") {
      this.setState({
        errorOpen: true,
        errorText: "Missing email/username"
      });
    } else {
      //post login to API
      axios
        .post(API_URL + "/register", {
          email,
          password,
          username
        })
        .then(response => {
          this.loginUser();
        })
        .catch(error => {
          //login failed, show error (currently only one error response being delivered, can change in the future)
          this.setState({
            errorOpen: true,
            errorText: error.response.data
          });
        });
    }
  }

  loginUser() {
    const { email, password, lineup, captain } = this.state;
    this.setState({
      loaded: false
    });
    if (this.state.email === "" || this.state.password === "") {
      this.setState({
        errorOpen: true,
        errorText: "Input missing"
      });
    } else {
      //post login to API
      axios
        .post(API_URL + "/login", {
          email,
          password
        })
        .then(response => {
          //SAVE JWT TOKEN TO LOCALSTORAGE (very important, used for all later API calls)
          localStorage.setItem("JWT", response.data.token);

          let config = {
            headers: {
              Authorization: response.data.token
            }
          };

          let user = response.data.user;
          //get all teams/players api call
          axios
            .get(API_URL + "/teams", config)
            .then(response => {
              this.props.addTeams(response.data);
              //get all settings api call
              axios
                .get(API_URL + "/settings", config)
                .then(response => {
                  var season = response.data.season;
                  this.props.addSettings(response.data);
                  //get all series api call
                  axios
                    .get(
                      API_URL + "/series/season/" + response.data.season,
                      config
                    )
                    .then(response => {
                      this.props.addSeries(response.data);

                      axios
                        .get(
                          API_URL +
                            "/leagues/user/" +
                            user._id +
                            "/season/" +
                            season,
                          config
                        )
                        .then(response => {
                          this.props.addLeagues(response.data);
                          axios
                            .get(
                              API_URL + "/stats/leaderboard/season/" + season,
                              config
                            )
                            .then(response => {
                              this.props.addLeaderboard(response.data);
                              axios
                                .get(API_URL + "/players/active", config)
                                .then(response => {
                                  this.props.addPlayers(response.data);
                                  axios
                                    .get(
                                      API_URL +
                                        "/stats/standings/season/" +
                                        season,
                                      config
                                    )
                                    .then(response => {
                                      this.props.addStandings(response.data);

                                      axios
                                        .post(
                                          API_URL +
                                            "/users/" +
                                            user._id +
                                            "/lineup",
                                          {
                                            lineup
                                          },
                                          config
                                        )
                                        .then(response => {
                                          axios
                                            .post(
                                              API_URL +
                                                "/users/" +
                                                user._id +
                                                "/captain",
                                              {
                                                captain
                                              },
                                              config
                                            )
                                            .then(response => {
                                              this.success(response.data);
                                            })
                                            .catch(error => {
                                              this.props.userLogout();
                                              localStorage.removeItem("JWT");

                                              this.setState({
                                                errorOpen: true,
                                                errorText: error.response.data,
                                                loaded: true
                                              });
                                            });
                                        })
                                        .catch(error => {
                                          this.props.userLogout();
                                          localStorage.removeItem("JWT");

                                          this.setState({
                                            errorOpen: true,
                                            errorText: error.response.data,
                                            loaded: true
                                          });
                                        });
                                    })
                                    .catch(error => {
                                      this.props.userLogout();
                                      localStorage.removeItem("JWT");

                                      this.setState({
                                        errorOpen: true,
                                        errorText: error.response.data,
                                        loaded: true
                                      });
                                    });
                                })
                                .catch(error => {
                                  this.props.userLogout();
                                  localStorage.removeItem("JWT");

                                  this.setState({
                                    errorOpen: true,
                                    errorText: error.response.data,
                                    loaded: true
                                  });
                                });
                            })
                            .catch(error => {
                              this.props.userLogout();
                              localStorage.removeItem("JWT");

                              this.setState({
                                errorOpen: true,
                                errorText: error.response.data,
                                loaded: true
                              });
                            });
                        })
                        .catch(error => {
                          this.props.userLogout();
                          localStorage.removeItem("JWT");

                          this.setState({
                            errorOpen: true,
                            errorText: error.response.data,
                            loaded: true
                          });
                        });
                    });
                })
                .catch(error => {
                  this.props.userLogout();
                  localStorage.removeItem("JWT");

                  this.setState({
                    errorOpen: true,
                    errorText: error.response.data,
                    loaded: true
                  });
                });
            })
            .catch(error => {
              this.props.userLogout();
              localStorage.removeItem("JWT");

              this.setState({
                errorOpen: true,
                errorText: error.response.data,
                loaded: true
              });
            });
        })
        .catch(error => {
          this.props.userLogout();

          if (error.response) {
            this.setState({
              errorOpen: true,
              errorText: error.response.data,
              loaded: true
            });
          } else {
            this.setState({
              errorOpen: true,
              errorText: "Server is down.",
              loaded: true
            });
          }
        });
    }
  }

  login(e) {
    if (e.target) {
      e.preventDefault();
    }

    this.loginUser();
  }

  forgot(e) {
    if (e.target) {
      e.preventDefault();
    }

    this.forgotPassword();
  }

  forgotPassword() {
    if (this.state.email === "") {
      this.setState({
        errorOpen: true,
        errorText: <FormattedMessage id="email.not.valid" />
      });
    } else {
      axios
        .post(API_URL + "/forgotpassword", {
          email: this.state.email
        })
        .then(response => {
          this.setState({
            form: "login",
            successOpen: true
          });
        })
        .catch(error => {
          this.setState({
            errorOpen: true,
            errorText: <FormattedMessage id="email.not.found" />
          });
        });
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Grid
          item
          xs={12}
          style={{
            margin: "20px",
            textAlign: "center"
          }}
        >
          <div>
            <CircularProgress
              style={{
                color: "#11F211"
              }}
              color="secondary"
            />
          </div>
        </Grid>
      );
    } else {
      return (
        <span>
          <Snackbar
            open={this.state.successOpen}
            autoHideDuration={8000}
            onClose={this.handleSuccess}
          >
            <Alert severity="success">
              <FormattedMessage id="password.reset.sent" />
            </Alert>
          </Snackbar>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              <FormattedMessage id="error" /> {this.state.errorText}
            </Alert>
          </Snackbar>

          {this.state.form === "register" ? (
            <span>
              <DialogTitle
                style={{
                  fontWeight: "bold",
                  textAlign: "center"
                }}
                id="simple-dialog-title"
              >
                <FormattedMessage id="register" />
              </DialogTitle>

              <form
                style={{ textAlign: "center", margin: "0px 20px" }}
                onSubmit={e => this.register(e)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: "400px" }}
                      variant="outlined"
                      margin="normal"
                      name="username"
                      required
                      label={<FormattedMessage id="username" />}
                      type="text"
                      id="username"
                      value={this.state.username}
                      inputProps={{ maxLength: 20, minLength: 3 }}
                      onChange={this.handleChange("username")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: "400px" }}
                      variant="outlined"
                      margin="normal"
                      name="email"
                      required
                      label={<FormattedMessage id="email" />}
                      type="email"
                      id="email"
                      value={this.state.email}
                      inputProps={{ maxLength: 60 }}
                      onChange={this.handleChange("email")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: "400px" }}
                      variant="outlined"
                      margin="normal"
                      required
                      name="password"
                      label={<FormattedMessage id="password" />}
                      type="password"
                      id="password"
                      inputProps={{ maxLength: 30, minLength: 5 }}
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                    />
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    className="main-theme-color"
                    fullWidth={true}
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      color: "white",
                      maxWidth: "400px"
                    }}
                  >
                    <FormattedMessage id="create.account" />
                  </Button>
                  <div style={{ marginBottom: "1rem" }}>
                    <FormattedMessage id="existing.account" />{" "}
                    <span
                      onClick={() => this.setState({ form: "login" })}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      <FormattedMessage id="login" />
                    </span>
                  </div>
                </Grid>
              </form>
            </span>
          ) : this.state.form === "login" ? (
            <span>
              <DialogTitle
                style={{
                  fontWeight: "bold",
                  textAlign: "center"
                }}
                id="simple-dialog-title"
              >
                <FormattedMessage id="login" />
              </DialogTitle>
              <form
                style={{ textAlign: "center", margin: "0px 20px" }}
                onSubmit={e => this.login(e)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      label={<FormattedMessage id="email.username" />}
                      type="text"
                      id="email"
                      inputProps={{ maxLength: 40 }}
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: "400px" }}
                      variant="outlined"
                      margin="normal"
                      required
                      name="password"
                      label={<FormattedMessage id="password" />}
                      type="password"
                      id="password"
                      inputProps={{ maxLength: 30, minLength: 5 }}
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                    />
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    className="main-theme-color"
                    fullWidth={true}
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      color: "white",
                      maxWidth: "400px"
                    }}
                  >
                    <FormattedMessage id="login" />
                  </Button>
                  <div
                    style={{
                      marginBottom: "1rem",
                      width: "100%",
                      textAlign: "left"
                    }}
                  >
                    <FormattedMessage id="no.account" />

                    <span
                      onClick={() => this.setState({ form: "register" })}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      <FormattedMessage id="register" />
                    </span>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <span
                      onClick={() => this.setState({ form: "forgot" })}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      <FormattedMessage id="forgot.password" />
                    </span>
                  </div>
                </Grid>
              </form>
            </span>
          ) : (
            <span>
              <DialogTitle
                style={{
                  fontWeight: "bold",
                  textAlign: "center"
                }}
                id="simple-dialog-title"
              >
                <FormattedMessage id="forgot.password" />
              </DialogTitle>
              <DialogContentText style={{ padding: "0px 20px" }}>
                <FormattedMessage id="forgot.password.text" />
              </DialogContentText>

              <form
                style={{ textAlign: "center", margin: "0px 20px" }}
                onSubmit={e => this.forgot(e)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: "400px" }}
                      variant="outlined"
                      margin="normal"
                      name="email"
                      required
                      label={<FormattedMessage id="email" />}
                      type="email"
                      id="email"
                      value={this.state.email}
                      inputProps={{ maxLength: 60 }}
                      onChange={this.handleChange("email")}
                    />
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    className="main-theme-color"
                    fullWidth={true}
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      color: "white",
                      maxWidth: "400px"
                    }}
                  >
                    <FormattedMessage id="confirm" />
                  </Button>
                  <div
                    style={{
                      marginBottom: "1rem",
                      width: "100%",
                      textAlign: "left"
                    }}
                  >
                    <FormattedMessage id="no.account" />

                    <span
                      onClick={() => this.setState({ form: "register" })}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      <FormattedMessage id="register" />
                    </span>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <FormattedMessage id="existing.account" />{" "}
                    <span
                      onClick={() => this.setState({ form: "login" })}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      <FormattedMessage id="login" />
                    </span>
                  </div>
                </Grid>
              </form>
            </span>
          )}
        </span>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addUser: addUser,
      addTeams: addTeams,
      addLeagues: addLeagues,
      addPlayers: addPlayers,
      addLeaderboard: addLeaderboard,
      addSeries: addSeries,
      addSettings: addSettings,
      addStandings: addStandings,
      userLogout: userLogout
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(LaForm);
