import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import { Redirect } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";
import { addUser } from "../actions/userActions";
import { addTeams } from "../actions/teamActions";
import { addLeagues } from "../actions/leagueActions";
import { addSeries } from "../actions/seriesActions";
import { addPlayers } from "../actions/playerActions";
import { addLeaderboard } from "../actions/leaderboardActions";
import { addStandings } from "../actions/standingsActions";
import { addSettings } from "../actions/settingsActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      errorOpen: false,
      errorText: "Error!",
      newPassword: "",
      newPassword2: "",
      season: 2
    };

    this.resetPassword = this.resetPassword.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const { userId, token } = this.props.match.params;

    axios
      .post(API_URL + "/validreset", { token, id: userId }, null)
      .then(response => {
        //Show page, token and user is valid
      })
      .catch(error => {
        this.setState({
          valid: false
        });
      });
  }

  //handle input changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  resetPassword() {
    if (
      this.state.newPassword === "" ||
      this.state.newPassword !== this.state.newPassword2
    ) {
      this.setState({
        errorOpen: true,
        errorText: <FormattedMessage id="password.not.valid" />
      });
    } else {
      const { userId, token } = this.props.match.params;

      axios
        .patch(
          API_URL + "/resetpassword/",
          { id: userId, token, newPassword: this.state.newPassword },
          null
        )
        .then(response => {
          //post login to API
          axios
            .post(API_URL + "/login", {
              email: response.data,
              password: this.state.newPassword
            })
            .then(response => {
              //SAVE JWT TOKEN TO LOCALSTORAGE (very important, used for all later API calls)
              localStorage.setItem("JWT", response.data.token);
              this.props.addUser(response.data.user);

              let config = {
                headers: {
                  Authorization: response.data.token
                }
              };

              let user = response.data.user;
              //get all teams/players api call
              axios
                .get(API_URL + "/teams", config)
                .then(response => {
                  this.props.addTeams(response.data);
                  //get all settings api call
                  axios
                    .get(API_URL + "/settings", config)
                    .then(response => {
                      var season = response.data.season;
                      this.props.addSettings(response.data);
                      //get all series api call
                      axios
                        .get(
                          API_URL + "/series/season/" + response.data.season,
                          config
                        )
                        .then(response => {
                          this.props.addSeries(response.data);
                          //get all teams/players api call
                          axios
                            .get(
                              API_URL +
                                "/leagues/user/" +
                                user._id +
                                "/season/" +
                                season,
                              config
                            )
                            .then(response => {
                              this.props.addLeagues(response.data);
                              axios
                                .get(
                                  API_URL +
                                    "/stats/leaderboard/season/" +
                                    season,
                                  config
                                )
                                .then(response => {
                                  this.props.addLeaderboard(response.data);
                                  axios
                                    .get(API_URL + "/players/active", config)
                                    .then(response => {
                                      this.props.addPlayers(response.data);
                                      axios
                                        .get(
                                          API_URL +
                                            "/stats/standings/season/" +
                                            season,
                                          config
                                        )
                                        .then(response => {
                                          this.props.addStandings(
                                            response.data
                                          );

                                          this.props.history.push("/home");
                                        })
                                        .catch(error => {
                                          this.setState({
                                            errorOpen: true,
                                            errorText: error.response.data
                                          });
                                        });
                                    })
                                    .catch(error => {
                                      this.setState({
                                        errorOpen: true,
                                        errorText: error.response.data
                                      });
                                    });
                                })
                                .catch(error => {
                                  this.setState({
                                    errorOpen: true,
                                    errorText: error.response.data
                                  });
                                });
                            })
                            .catch(error => {
                              this.setState({
                                errorOpen: true,
                                errorText: error.response.data
                              });
                            });
                        });
                    })
                    .catch(error => {
                      this.setState({
                        errorOpen: true,
                        errorText: error.response.data
                      });
                    });
                })
                .catch(error => {
                  this.setState({
                    errorOpen: true,
                    errorText: error.response.data
                  });
                });
            })
            .catch(error => {
              if (error.response) {
                this.setState({
                  errorOpen: true,
                  errorText: error.response.data,
                  loaded: true
                });
              } else {
                this.setState({
                  errorOpen: true,
                  errorText: "Server is down.",
                  loaded: true
                });
              }
            });
        })
        .catch(error => {
          this.setState({
            errorOpen: true,
            errorText: <FormattedMessage id="error" />
          });
        });
    }
  }

  render() {
    if (!this.state.valid) {
      return <Redirect to={`/`} />;
    } else {
      return (
        <Grid container spacing={3}>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">{this.state.errorText}</Alert>
          </Snackbar>{" "}
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" component="h4">
              Reset Password
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="password"
              label="Password"
              type="password"
              id="password"
              inputProps={{ maxLength: 30, minLength: 5 }}
              onChange={this.handleInputChange("newPassword")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="password2"
              label="Confirm password"
              type="password"
              id="password2"
              onChange={this.handleInputChange("newPassword2")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button onClick={this.resetPassword}>Confirm</Button>
          </Grid>
        </Grid>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addUser: addUser,
      addTeams: addTeams,
      addLeagues: addLeagues,
      addPlayers: addPlayers,
      addLeaderboard: addLeaderboard,
      addSeries: addSeries,
      addSettings: addSettings,
      addStandings: addStandings
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(ResetPassword);
