import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Images from "../resources/index";
import Divider from "@material-ui/core/Divider";
import { NavLink } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage, FormattedDate } from "react-intl";
import Moment from "react-moment";
import "moment/locale/is";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playerId: this.props.match.params.playerId,
      player: {},
      season: this.props.settings.season,
      loaded: false
    };
  }

  componentDidMount() {
    const { playerId } = this.props.match.params;

    //GET FROM API
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .get(API_URL + "/players/" + playerId.toLowerCase(), config)
      .then(response => {
        this.setState({
          player: response.data,
          loaded: true
        });
      })
      .catch(error => {
        this.props.history.push("/home");
      });
  }

  handleChange = event => {
    this.setState({
      season: event.target.value
    });
  };

  render() {
    if (!this.state.loaded) {
      return (
        <CircularProgress
          style={{
            height: "100px",
            width: "100px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#11F211"
          }}
          color="secondary"
        />
      );
    } else {
      return (
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <Select value={this.state.season} onChange={this.handleChange}>
                  <MenuItem value={1}>Season 1</MenuItem>
                  <MenuItem value={2}>Season 2</MenuItem>
                  <MenuItem value={3}>Season 3</MenuItem>
                  <MenuItem value={4}>Season 4</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={11} />

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <img
                  style={{
                    height: "250px",
                    marginBottom: "0.5rem"
                  }}
                  alt={this.state.player.name}
                  src={Images.players[this.state.player._id]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h4">{this.state.player.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="price" />
            </Typography>
            <Typography variant="h6" gutterBottom>
              ${this.state.player.price?.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="points" />{" "}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {this.state.player.stats.find(x => x.season === this.state.season)
                ?.totalpoints || 0}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="kills" />{" "}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {this.state.player.stats.find(x => x.season === this.state.season)
                ?.totalkills || 0}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="deaths" />{" "}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {this.state.player.stats.find(x => x.season === this.state.season)
                ?.totaldeaths || 0}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant="overline" gutterBottom>
              <FormattedMessage id="mvp" />{" "}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {this.state.player.stats.find(x => x.season === this.state.season)
                ?.totalmvp || 0}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Profile);
