import React, { Component } from "react";
import Images from "../../resources/index";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";

class LaHero extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false };
  }

  componentDidMount() {
    this.setState({ checked: true });
  }
  render() {
    return (
      <section>
        <Container maxWidth={false} className="landing-container">
          <Grid container spacing={0}>
            <Hidden mdUp>
              <Grid item xs={12} md={6}>
                <Slide
                  direction="right"
                  mountOnEnter
                  unmountOnExit
                  timeout={500}
                  in={this.state.checked}
                >
                  <Typography
                    variant="h1"
                    style={{
                      fontWeight: "700",
                      fontSize: "35px",
                      fontFamily: "Montserrat",
                      lineHeight: "110%",
                      textAlign: "left",
                      padding: "20px"
                    }}
                  >
                    <FormattedMessage id="landing.text" />
                  </Typography>
                </Slide>

                <div>
                  <Link
                    style={{
                      textDecoration: "none"
                    }}
                    to="/create"
                  >
                    <Slide
                      direction="right"
                      mountOnEnter
                      unmountOnExit
                      timeout={500}
                      in={this.state.checked}
                    >
                      <Button
                        variant="outlined"
                        size="large"
                        className="green-hover"
                        style={{
                          minWidth: "200px",
                          fontSize: "large",
                          fontFamily: "Montserrat",
                          borderRadius: "20px"
                        }}
                      >
                        <FormattedMessage id="landing.button" />
                      </Button>
                    </Slide>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "1rem" }}>
                <Slide
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  timeout={500}
                  in={this.state.checked}
                >
                  <img
                    alt="logo"
                    src={Images["landing"]}
                    onError={e => {
                      e.target.src = Images["landingfallback"]; // some replacement image
                    }}
                    style={{
                      width: "100%",
                      height: "auto"
                    }}
                  />
                </Slide>
              </Grid>
            </Hidden>

            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <Slide
                  direction="right"
                  mountOnEnter
                  unmountOnExit
                  timeout={500}
                  in={this.state.checked}
                >
                  <Typography
                    variant="h1"
                    style={{
                      fontWeight: "700",
                      fontSize: "42px",
                      fontFamily: "Montserrat",
                      lineHeight: "110%",
                      textAlign: "left",
                      padding: "96px 64px"
                    }}
                  >
                    <FormattedMessage id="landing.text" />
                  </Typography>
                </Slide>

                <div>
                  <Link
                    style={{
                      textDecoration: "none"
                    }}
                    to="/create"
                  >
                    <Slide
                      direction="right"
                      mountOnEnter
                      unmountOnExit
                      timeout={500}
                      in={this.state.checked}
                    >
                      <Button
                        variant="outlined"
                        size="large"
                        className="green-hover"
                        style={{
                          minWidth: "200px",
                          fontSize: "large",
                          fontFamily: "Montserrat",
                          borderRadius: "20px"
                        }}
                      >
                        <FormattedMessage id="landing.button" />
                      </Button>
                    </Slide>
                  </Link>
                  <Slide
                    direction="right"
                    mountOnEnter
                    unmountOnExit
                    timeout={500}
                    in={this.state.checked}
                  >
                    <Typography
                      style={{
                        minWidth: "200px",
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        display: "inline-block"
                      }}
                    >
                      <Link
                        className="green-hover"
                        style={{
                          textDecoration: "none",
                          color: "white"
                        }}
                        to="/login"
                      >
                        eða skráðu þig inn
                      </Link>
                    </Typography>
                  </Slide>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <Slide
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  timeout={500}
                  in={this.state.checked}
                >
                  <img
                    alt="logo"
                    src={Images["landing"]}
                    onError={e => {
                      e.target.src = Images["landingfallback"]; // some replacement image
                    }}
                    style={{
                      width: "100%",
                      height: "auto"
                    }}
                  />
                </Slide>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </section>
    );
  }
}

export default LaHero;
