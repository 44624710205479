import React from "react";
import { Route, Redirect } from "react-router-dom";
import { userLogout } from "../actions/miscActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function isAuthenticated(userLogout) {
  var jwt_token = localStorage.getItem("JWT");
  if (jwt_token) {
    jwt_token = jwt_token.substr(7);

    const base64Url = jwt_token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const buff = new Buffer(base64, "base64");
    const payloadinit = buff.toString("ascii");
    const payload = JSON.parse(payloadinit);

    var dateNow = new Date();
    dateNow = dateNow.getTime();
    dateNow = dateNow / 1000;
    if (payload.exp < Math.floor(dateNow)) {
      localStorage.removeItem("JWT");
      userLogout();

      return false;
    }

    return true;
  }
  return false;
}

const PublicRoute = ({ component: Component, userLogout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated(userLogout)) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/home",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ userLogout: userLogout }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(PublicRoute);
