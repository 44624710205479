import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { API_URL } from "../../constants/apiConstants";
import { Redirect } from "react-router-dom";

class AdminAddPlayerToTeam extends Component {
  constructor(props) {
    super(props);
    this.state = { team: this.props.teams[0].name, player: "" };

    this.addPlayerToTeam = this.addPlayerToTeam.bind(this);
  }

  handleChange = event => {
    this.setState({
      team: event.target.value
    });
  };

  //handle input changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  addPlayerToTeam() {
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };

    axios
      .patch(
        API_URL + "/teams/" + this.state.team + "/player/" + this.state.player,
        {},
        config
      )
      .then(response => {
        alert("Success!");
      })
      .catch(error => {
        alert("Error!");
      });
  }

  render() {
    if (this.props.user.type !== "admin") {
      return <Redirect to={`/Home`} />;
    } else {
      return (
        <Grid container spacing={3}>
          {" "}
          <Grid item xs={1} sm={3} />
          <Grid item xs={10} sm={5}>
            <Typography variant="h4" component="h4">
              Add Player to Team
            </Typography>
          </Grid>
          <Grid item xs={1} sm={4} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <FormControl variant="filled">
              <InputLabel>Team</InputLabel>
              <Select value={this.state.team} onChange={this.handleChange}>
                {this.props.teams.map(
                  /*<MenuItem value={10}>Ten</MenuItem>*/ x => (
                    <MenuItem key={x.name} value={x.name}>
                      {x.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="player"
              label="Player (id)"
              type="text"
              id="player"
              onChange={this.handleInputChange("player")}
            />
          </Grid>
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={1} sm={5} />
          <Grid item xs={1} sm={4} />
          <Grid item xs={10} sm={3}>
            <Button onClick={this.addPlayerToTeam}>Confirm</Button>
          </Grid>
          <Grid item xs={1} sm={5} />
        </Grid>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(AdminAddPlayerToTeam);
