import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../constants/apiConstants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import { Doughnut, Bar } from "react-chartjs-2";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captain: { datasets: [], labels: [] },
      lineup: { datasets: [], labels: [] },
      round: "overall",
      buttons: ["1"],
      season: this.props.settings.season,
      errorOpen: false,
      errorText: "Error"
    };
    this.handleError = this.handleError.bind(this);
  }

  handleError() {
    this.setState({
      errorOpen: false
    });
  }

  componentDidMount() {
    var buttons = [];
    for (let i = 1; i < this.props.settings.round; i++) {
      buttons.push(i.toString());
    }

    this.setState({
      buttons
    });
    //GET FROM API
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };
    axios
      .get(
        API_URL +
          "/stats/captainusage/season/" +
          this.state.season +
          "/round/" +
          this.state.round,
        config
      )
      .then(response => {
        var data = {
          labels: [
            response.data[0]?._id,
            response.data[1]?._id,
            response.data[2]?._id,
            response.data[3]?._id,
            response.data[4]?._id,
            response.data[5]?._id
          ],
          datasets: [
            {
              data: [
                response.data[0]?.count,
                response.data[1]?.count,
                response.data[2]?.count,
                response.data[3]?.count,
                response.data[4]?.count,
                response.data[5]?.count
              ],
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(105, 191, 128)",
                "#05dfd7",
                "#fb7b6b"
              ]
            }
          ]
        };
        this.setState({
          captain: data
        });
        axios
          .get(
            API_URL +
              "/stats/playerusage/season/" +
              this.state.season +
              "/round/" +
              this.state.round,
            config
          )
          .then(response => {
            var data = {
              labels: [
                response.data[0]?._id,
                response.data[1]?._id,
                response.data[2]?._id,
                response.data[3]?._id,
                response.data[4]?._id,
                response.data[5]?._id,
                response.data[6]?._id,
                response.data[7]?._id,
                response.data[8]?._id,
                response.data[9]?._id,
                response.data[10]?._id,
                response.data[11]?._id,
                response.data[12]?._id,
                response.data[13]?._id,
                response.data[14]?._id
              ],
              datasets: [
                {
                  label: "Picked by # of users",
                  data: [
                    response.data[0]?.count,
                    response.data[1]?.count,
                    response.data[2]?.count,
                    response.data[3]?.count,
                    response.data[4]?.count,
                    response.data[5]?.count,
                    response.data[6]?.count,
                    response.data[7]?.count,
                    response.data[8]?.count,
                    response.data[9]?.count,
                    response.data[10]?.count,
                    response.data[11]?.count,
                    response.data[12]?.count,
                    response.data[13]?.count,
                    response.data[14]?.count
                  ],
                  backgroundColor: [
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                    "rgb(105, 191, 128)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                    "rgb(105, 191, 128)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                    "rgb(105, 191, 128)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)"
                  ]
                }
              ]
            };
            this.setState({
              lineup: data
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              errorOpen: true,
              errorText: "Data unavailable!"
            });
          });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          errorOpen: true,
          errorText: "Data unavailable!"
        });
      });
  }

  getStats(round) {
    //GET FROM API
    let config = {
      headers: {
        Authorization: localStorage.getItem("JWT")
      }
    };
    axios
      .get(
        API_URL +
          "/stats/captainusage/season/" +
          this.state.season +
          "/round/" +
          round,
        config
      )
      .then(response => {
        var data = {
          labels: [
            response.data[0]._id,
            response.data[1]?._id,
            response.data[2]?._id,
            response.data[3]?._id,
            response.data[4]?._id,
            response.data[5]?._id
          ],
          datasets: [
            {
              data: [
                response.data[0]?.count,
                response.data[1]?.count,
                response.data[2]?.count,
                response.data[3]?.count,
                response.data[4]?.count,
                response.data[5]?.count
              ],
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(105, 191, 128)",
                "#05dfd7",
                "#fb7b6b"
              ]
            }
          ]
        };
        this.setState({
          captain: data
        });
        axios
          .get(
            API_URL +
              "/stats/playerusage/season/" +
              this.state.season +
              "/round/" +
              round,
            config
          )
          .then(response => {
            var data = {
              labels: [
                response.data[0]?._id,
                response.data[1]?._id,
                response.data[2]?._id,
                response.data[3]?._id,
                response.data[4]?._id,
                response.data[5]?._id,
                response.data[6]?._id,
                response.data[7]?._id,
                response.data[8]?._id,
                response.data[9]?._id,
                response.data[10]?._id,
                response.data[11]?._id,
                response.data[12]?._id,
                response.data[13]?._id,
                response.data[14]?._id
              ],
              datasets: [
                {
                  label: "Picked by # of users",
                  data: [
                    response.data[0]?.count,
                    response.data[1]?.count,
                    response.data[2]?.count,
                    response.data[3]?.count,
                    response.data[4]?.count,
                    response.data[5]?.count,
                    response.data[6]?.count,
                    response.data[7]?.count,
                    response.data[8]?.count,
                    response.data[9]?.count,
                    response.data[10]?.count,
                    response.data[11]?.count,
                    response.data[12]?.count,
                    response.data[13]?.count,
                    response.data[14]?.count
                  ],
                  backgroundColor: [
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                    "rgb(105, 191, 128)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                    "rgb(105, 191, 128)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                    "rgb(105, 191, 128)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)"
                  ]
                }
              ]
            };
            this.setState({
              lineup: data
            });
          })
          .catch(error => {
            console.log(error);

            this.setState({
              errorOpen: true,
              errorText: "Data unavailable!"
            });
          });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          errorOpen: true,
          errorText: "Data unavailable!"
        });
      });
  }

  render() {
    const options = {
      maintainAspectRatio: false
    };
    console.log(this.state.buttons);
    return (
      <div>
        <Grid container spacing={5}>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={4000}
            onClose={this.handleError}
          >
            <Alert severity="error">
              {" "}
              <FormattedMessage id="error" />
              {this.state.errorText}
            </Alert>
          </Snackbar>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={{
                fontWeight: "lighter"
              }}
              className="front-title"
              variant="h4"
              component="h4"
            >
              <FormattedMessage id="stats" />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <Button
              fullWidth={true}
              style={{ marginBottom: "1rem" }}
              variant="outlined"
              onClick={() => {
                this.setState({ round: "overall" });
                this.getStats("overall");
              }}
              className={"overall" === this.state.round ? "smoke" : ""}
            >
              Overall
            </Button>
            {this.state.buttons.map(element => {
              return (
                <Button
                  key={element}
                  variant="outlined"
                  fullWidth={true}
                  style={{ marginBottom: "1rem" }}
                  onClick={() => {
                    this.setState({ round: element });
                    this.getStats(element);
                  }}
                  className={element === this.state.round ? "smoke" : ""}
                >
                  Round {element}
                </Button>
              );
            })}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            style={{ padding: "0px", marginTop: "2rem" }}
          >
            <Typography
              variant="h4"
              component="h4"
              style={{
                paddingBottom: "1rem",
                fontWeight: "lighter",
                fontSize: "22px"
              }}
            >
              <FormattedMessage id="stats.captain" />
            </Typography>
            <Doughnut data={this.state.captain} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            style={{ padding: "0px", marginTop: "2rem" }}
          >
            <Typography
              variant="h4"
              component="h4"
              style={{
                paddingBottom: "1rem",
                fontWeight: "lighter",
                fontSize: "22px"
              }}
            >
              <FormattedMessage id="stats.player.usage" />
            </Typography>

            <Bar
              options={{
                responsive: true,
                maintainAspectRatio: true
              }}
              data={this.state.lineup}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(Stats);
