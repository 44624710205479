import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import Images from "../../resources/index";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@material-ui/core/styles";

class Standings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  oneTeam(props) {
    const theme = useTheme();
    return (
      <span key={props.index}>
        <Divider />
        <Typography
          className={
            theme.palette.type === "light"
              ? "result-entry-mainpage smoke-hoverw"
              : "result-entry-mainpage smoke-hoverb"
          }
          component="div"
          variant="body2"
        >
          <Grid container spacing={3}>
            <Grid item xs={3} style={{ fontWeight: "bold" }}>
              {props.index + 1}
            </Grid>
            <img
              style={{
                width: "30px",
                height: "44px",
                float: "left"
              }}
              alt="team"
              src={Images.teams[props.team.name]}
            />
            <Grid
              item
              xs={3}
              style={{
                textAlign: "right",
                fontWeight: "bold",
                color: "green"
              }}
            >
              {props.team.wins}W
            </Grid>
            <Grid item xs={3} style={{ fontWeight: "bold", color: "red" }}>
              {props.team.losses}L
            </Grid>
          </Grid>
        </Typography>
      </span>
    );
  }

  render() {
    return (
      <span>
        {this.props.standings.length !== 0 ? (
          <Paper
            style={{
              width: "100%",
              display: "block",
              float: "left",
              position: "relative"
            }}
          >
            <div>
              <Typography
                variant="overline"
                className="frontpage-paper-header"
                style={{
                  cursor: "default"
                }}
                component="h3"
              >
                <span
                  style={{
                    marginRight: "1.7rem"
                  }}
                >
                  {" "}
                  <EmojiEventsIcon
                    style={{
                      verticalAlign: "middle",
                      marginRight: "5px"
                    }}
                  />{" "}
                  <FormattedMessage id="standings" />
                </span>
              </Typography>
              {this.props.standings.map((team, i) => (
                <this.oneTeam team={team} key={i} index={i} />
              ))}
            </div>
          </Paper>
        ) : null}
      </span>
    );
  }
}
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Standings);
